import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft, SlArrowRight} from 'react-icons/sl';
import {userProfile} from '../../assets/images';
import {PiPencilSimpleBold, PiX} from 'react-icons/pi';
import {Dialog, DialogBackdrop, DialogPanel} from '@headlessui/react';

const UserAccount: React.FC = () => {
  const userInformation = JSON.parse(localStorage.getItem('userInfo') || '{}');

  const UserData = [
    {
      title: 'Name',
      navigateTo: '/settings/user-account/update-name',
      value: `${userInformation?.firstName} ${userInformation?.lastName} `,
      state: {
        firstName: userInformation?.firstName,
        lastName: userInformation?.lastName,
      },
    },
    {
      title: 'Phone Number',
      navigateTo: '/settings/user-account/update-phone-number',
      value: userInformation?.phone,
    },
    {
      title: 'Email',
      navigateTo: '/settings/user-account/update-email',
      value: userInformation?.email,
    },
  ];
  const [open, setOpen] = useState(false);
  // const handleOpenModal = () => {
  //   setOpen(true);
  // };

  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-6">
              <Link
                to="/settings"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <h4 className="text-2xl">User Account</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-3 mb-6">
              <div className="profile-ring w-[76px] h-[76px] flex-none relative">
                <img
                  className="profile-img w-[68px] h-[68px] rounded-full bg-amber-600"
                  src={userProfile}
                  width={70}
                  height={70}
                  alt="user-profile"
                />
                {/* <button
                  onClick={handleOpenModal}
                  className="btn-dark-blue p-2 rounded-full h-8 w-8 flex items-center justify-center absolute right-0 bottom-0">
                  <PiPencilSimpleBold />
                </button> */}
              </div>
            </div>
            <h5 className="text-2xl mb-1">Basic Info</h5>
            <div className="divide-y divide-gray-medium space-y-4">
              {UserData.map((info, index) => {
                const content = (
                  <div className="grid grid-cols-10 gap-2 pt-4" key={index}>
                    <div className="col-span-9">
                      <h5 className="text-lg">{info.title}</h5>
                    </div>
                    {info.title === 'Name' && (
                      <div className="col-span-1 flex items-center justify-end">
                        <SlArrowRight />
                      </div>
                    )}
                    <div className="col-span-10">
                      <p>{info.value}</p>
                    </div>
                  </div>
                );
                
                return info.title === "Name" ? (
                  <Link to={info.navigateTo} state={info?.state} key={index}>
                    {content}
                  </Link>
                ) : (
                  content
                );
              })}
            </div>
          </div>
        </div>
        <Dialog open={open} onClose={setOpen} className="relative z-10">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-black bg-opacity-[58%] transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
          />

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 items-center sm:p-0">
              <DialogPanel
                transition
                className="relative transform overflow-hidden rounded-lg bg-white px-7 py-[30px] text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
                <div className="flex items-center justify-between pb-[18px] mb-[18px] border-b border-gray-medium">
                  <h5 className="text-xl">Profile Photo</h5>
                  <PiX
                    className="cursor-pointer text-2xl hover:text-black"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <p>This is the photo you would like others to see.</p>
                <div className="mt-5">
                  <button type="button" className="btn-primary px-6">
                    Update Photo
                  </button>
                </div>
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default UserAccount;
