/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {
  Home,
  Login,
  Profile,
  Otp,
  StationDetail,
  Checkout,
  Scan,
  PaymentSuccess,
  PaymentFailure,
  OrderList,
  OrderDetails,
  MyWallet,
  UserAgreement,
  PrivacyPolicy,
  TermsCondition,
  Help,
  Settings,
  UserAccount,
  UpdateName,
  UpdatePhoneNo,
  UpdateEmail,
  RentalConfirmed,
  AcceptTermCondition,
  PopoutTimer,
} from './pages';
import {AuthProvider} from './context/AuthContext';
import {PrivateRoute} from './components';
import PrivateRouteLogin from './components/common/PrivateRouteLogin';
import PublicRoute from './components/common/PublicRoute';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/login" element={<PublicRoute element={<Login />} />} />
          <Route path="/user-agreement" element={<UserAgreement />} />
          {/* <PrivateRoute key="/profile" element={<Profile />} /> */}
          <Route
            path="/profile"
            element={<PrivateRoute element={<Profile />} />}
          />
          <Route path="/station-detail" element={<StationDetail />} />
          <Route
            path="/checkout"
            element={<PrivateRouteLogin element={<Checkout />} />}
          />

          {/* // element={<Checkout />} /> PublicRoute*/}
          <Route path="/scan" element={<Scan />} />
          <Route path="/payment/success" element={<PaymentSuccess />} />
          <Route path="/payment/failure" element={<PaymentFailure />} />
          <Route path="/order-list" element={<OrderList />} />
          <Route path="/order-list/order-details" element={<OrderDetails />} />
          <Route path="/my-wallet" element={<MyWallet />} />

          <Route
            path="/accept-terms-and-condition"
            element={<AcceptTermCondition />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-condition" element={<TermsCondition />} />

          <Route path="/help" element={<Help />} />

          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/user-account" element={<UserAccount />} />
          <Route
            path="/settings/user-account/update-name"
            element={<UpdateName />}
          />
          <Route
            path="/settings/user-account/update-phone-number"
            element={<UpdatePhoneNo />}
          />
          <Route
            path="/settings/user-account/update-email"
            element={<UpdateEmail />}
          />

          <Route path="/rental-confirmed" element={<RentalConfirmed />} />
          <Route path="/popout-timer" element={<PopoutTimer />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
