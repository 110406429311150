/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {walletBg, masterCardLogo} from '../../assets/images';
import {PiPlus, PiTrash} from 'react-icons/pi';

const savedCards = [
  {
    id: 'card-1',
    checked: true,
    logoSrc: masterCardLogo,
    cardNumber: '**** **** **** 1106',
  },
  // {
  //   id: 'card-2',
  //   checked: false,
  //   logoSrc: masterCardLogo,
  //   cardNumber: '**** **** **** 1107',
  // },
];

const OrderDetails: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-6">
              <Link
                to="/"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <h4 className="text-2xl">My Wallet</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="bg-gray-50 border border-gray-medium rounded-lg shadow mb-[30px]">
              <div
                className="px-5 py-8 bg-cover sm:bg-center bg-[right_-15.5rem_center] bg-no-repeat"
                style={{backgroundImage: `url(${walletBg})`}}>
                {/* bg-[length:200px_100px] */}
                <div className="flex flex-wrap items-center justify-between gap-4">
                  <div className="flex-none">
                    <p className="mb-2">My Balance</p>
                    <h4 className="text-2xl">€957.5</h4>
                  </div>
                  <div className="flex-none">
                    <button className="btn-dark-blue px-[26px] py-3">
                      <span>Withdraw</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="text-xl mb-5">Payment Method</h5>
            <div className="grid grid-cols-1 gap-4 pb-[30px]">
              {savedCards.map(card => (
                <div
                  key={card.id}
                  className="flex items-center bg-gray-50 px-4 py-5 rounded-xl">
                  <label
                    htmlFor={card.id}
                    className="h-5 w-5 flex items-center justify-center rounded-full border border-blue-950 flex-none me-5 cursor-pointer">
                    <input
                      className="hidden peer"
                      id={card.id}
                      type="radio"
                      name="card-radio"
                      defaultChecked={card.checked}
                    />
                    <span className="h-3 w-3 rounded-full bg-blue-950 opacity-0 peer-checked:opacity-100 transition-opacity"></span>
                  </label>
                  <img
                    className="h-7 w-7 object-contain object-center flex-none me-4"
                    loading="lazy"
                    src={card.logoSrc}
                    alt="Bank Logo"
                  />
                  <h6 className="text-blue-950">{card.cardNumber}</h6>
                  <PiTrash className="text-red-600 text-xl cursor-pointer ms-auto" />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="py-7 lg:py-0 lg:relative fixed left-0 bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
          <div className="container">
            <div className="max-w-[900px] mx-auto w-full">
              <div className="lg:max-w-[362px]">
                <button className="btn-primary flex items-center justify-center gap-3 px-4">
                  <PiPlus className="text-xl" /> Add Payment Method
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
