import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';
import Cookies from 'js-cookie';
import {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useLocation, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {color} from '../../assets/colors';
import {cardImg, idealCard, loginBanner} from '../../assets/images/index';
import {
  PAYMENTCONFIRM,
  PAYMENTMODE,
  RECHARGEWALLET,
  STRIPEMULTICURANCY,
  USEBALANCETORENT,
} from '../../services/apiStaticData';
Modal.setAppElement('#root');
// Load Stripe.js with your publishable key
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
);

// CheckoutForm Component
const CheckoutForm = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [payMode, setPayMode] = useState('');
  const [currencySymbol, setCurrencySymbol] = useState(state?.currencyName);
  const [paymentModes, setPaymentModes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idealModal, setIdealModal] = useState(false);
  const [stripePaymentElementOption, setStripePaymentElementOption] =
    useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    fetchPaymentModes();
  }, []);

  // Check for query params on page load
  useEffect(() => {
    const fromCheckout = Cookies.get('fromCheckout');
    if (fromCheckout === 'true') {
      const url = new URL(window.location.href);
      url.search = '';
      window.history.replaceState({}, document.title, url.pathname);
      Cookies.remove('fromCheckout');
    }
    const query = new URLSearchParams(window.location.search);
    const paymentIntentId =
      query.get('payment_intent') || query.get('outTradeNo');
    const status = query.get('redirect_status');
    const payType = query.get('payType');

    // If both paymentIntentId and status are available and status is 'succeeded'
    if (status === 'succeeded' && paymentIntentId) {
      const confirmPayment = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_HOST}${PAYMENTCONFIRM}?tradeNo=${paymentIntentId}`,
            {headers: {Token: `${localStorage.getItem('reviveToken')}`}},
          );

          if (response.status === 200) {
            if (
              response?.data?.data.OrderType === 'PreAuth' &&
              response?.data?.data.orderId
            ) {
              navigate(
                `/popout-timer?tradeNo=${response?.data?.data.orderId}&payMode=stripe`,
              );
            } else if (
              (response?.data?.data.OrderType === 'CZ' ||
                response?.data?.data.orderType === 'YJ') &&
              payType === 'YJ'
            ) {
              const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}${USEBALANCETORENT}?qrcode=${localStorage.getItem('cabinetID')}`,
                {headers: {Token: `${localStorage.getItem('reviveToken')}`}},
              );
              if (response.status === 200) {
                navigate(
                  `/popout-timer?tradeNo=${response?.data?.data.orderId}&payMode=stripe`,
                );
              } else {
                Swal.fire({
                  icon: 'error',
                  text: response?.data?.msg,
                }).then(() => navigate('/'));
              }
            } else {
              Swal.fire({
                icon: 'error',
                text: response?.data?.msg,
              }).then(() => navigate('/'));
            }
          } else {
            Swal.fire({
              icon: 'error',
              text: 'Payment failed.',
            }).then(() => navigate('/'));
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'Error confirming the payment.',
          }).then(() => navigate('/'));
        }
      };

      confirmPayment();
    }
  }, [window.location.search]);

  const fetchPaymentModes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}${PAYMENTMODE}?id=${localStorage.getItem('cabinetID')}`,
      );
      const modes = response.data.data;
      const filteredModes = modes.filter(
        mode => mode.pId === 4 || mode.pId === 7,
      );

      if (filteredModes.length > 0) {
        setPayMode(filteredModes[0].pPaymentType);
      }

      setPaymentModes(filteredModes);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Error fetching payment modes.',
      }).then(() => navigate('/'));
    }
  };

  const handlePaymentModeChange = mode => {
    setPayMode(mode.pPaymentType);
    setCurrencySymbol(mode.currencySymbol);
  };

  const handlePayment = async () => {
    if (!payMode) {
      Swal.fire({
        icon: 'error',
        text: 'Please select a payment method.',
      }).then(() => navigate('/'));
      return;
    }
    const query = new URLSearchParams(window.location.search);
    const paymentId = paymentModes.find(
      mode => mode.pPaymentType === payMode,
    )?.pId;
    const formData = new FormData();
    formData.append(
      'amount',
      state?.priceStrategy.pDepositAmount || query.get('price'),
    );
    formData.append('paymentId', paymentId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}${STRIPEMULTICURANCY}/${localStorage.getItem('cabinetID') || query.get('cabinetID')}`,
        formData,
        {
          headers: {
            Token: `${localStorage.getItem('reviveToken')}`,
          },
        },
      );

      if (response.status === 200 && response?.data?.code === 0) {
        setIsModalOpen(true);
        setPaymentIntentId(response.data?.data?.paymentId);
        setClientSecret(response.data?.data?.clientSecret);
        const options = {
          clientSecret: response.data?.data?.clientSecret,
          appearance: {
            theme: 'stripe',
          },
        };
        setStripePaymentElementOption(options);
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.data?.msg,
        }).then(() => navigate('/'));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while processing the payment.',
      }).then(() => navigate('/'));
    }
  };

  const handleIdealPay = async () => {
    const query = new URLSearchParams(window.location.search);
    const formData = new FormData();
    const paymentId = paymentModes.find(
      mode => mode.pPaymentType === payMode,
    )?.pId;
    formData.append(
      'amount',
      state?.priceStrategy.pDepositAmount || query.get('price'),
    );
    formData.append('paymentId', paymentId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}${RECHARGEWALLET}`,
        formData,
        {
          headers: {
            Token: `${localStorage.getItem('reviveToken')}`,
          },
        },
      );
      if (response.status === 200 && response?.data?.code === 0) {
        setIdealModal(true);
        setPaymentIntentId(response.data?.data?.outTradeNo);
        setClientSecret(response.data?.data?.clientSecret);
        const options = {
          clientSecret: response.data?.data?.clientSecret,
          appearance: {
            theme: 'stripe',
          },
        };
        setStripePaymentElementOption(options);
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.data?.msg,
        }).then(() => navigate('/'));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while processing the payment.',
      }).then(() => navigate('/'));
    }
  };

  return (
    <div className="py-6 min-h-full flex flex-col items-center">
      <div className="container text-start">
        <div className="max-w-[484px] mx-auto">
          <div>
            <img className="mb-6 w-full" alt="login-banner" src={loginBanner} />
            <div className="payment-methods mb-16">
              <h3 className="mb-6 text-2xl">Payment Method</h3>
              {paymentModes.length > 0 ? (
                <div className="radio-group">
                  {paymentModes.map(mode => (
                    <label
                      key={mode.pId}
                      className="radio-label mb-4 flex justify-between border-4 py-5 px-4 rounded-xl border-[#ddd] peer-checked/draft:text-sky-500 checked:border-primary">
                      <span className="flex items-center">
                        <img
                          src={mode.pId === 4 ? cardImg : idealCard}
                          width={30}
                          height={29}
                          alt="credit img"
                          className="pe-2"
                        />{' '}
                        {mode.pId === 4
                          ? 'Credit Card'
                          : mode.pId === 7
                            ? 'Ideal Pay'
                            : mode.pPaymentType}
                      </span>
                      <input
                        type="radio"
                        name="paymentMode"
                        value={mode.pPaymentType}
                        checked={payMode === mode.pPaymentType}
                        onChange={() => handlePaymentModeChange(mode)}
                        className="input-active hidden"
                      />
                      <span className="border-[3px] input-img border-white outline outline-2 outline-amber-600 bg-amber-600  w-5 h-5 rounded-full"></span>
                    </label>
                  ))}
                </div>
              ) : (
                <p>Loading payment methods...</p>
              )}
            </div>
          </div>
          <button
            className="btn-primary w-full py-5"
            onClick={payMode === 'STRIPE_CARD' ? handlePayment : handleIdealPay}
            disabled={!payMode}>
            Payment
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div style={{width: '500px'}}>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            contentLabel="Stripe Payment"
            className="payment-modal"
            appElement={document.getElementById('root')}
            overlayClassName="ReactModal__Overlay">
            <Elements
              stripe={stripePromise}
              options={stripePaymentElementOption}>
              <StripeCheckoutForm
                paymentIntentId={paymentIntentId}
                clientSecret={clientSecret}
                paymentType="STRIPE_CARD"
              />
            </Elements>
          </Modal>
        </div>
      )}

      {idealModal && (
        <Modal
          isOpen={idealModal}
          onRequestClose={() => setIdealModal(false)}
          contentLabel="Stripe Payment"
          className="payment-modal"
          overlayClassName="ReactModal__Overlay">
          <Elements stripe={stripePromise} options={stripePaymentElementOption}>
            <StripeCheckoutForm
              paymentIntentId={paymentIntentId}
              clientSecret={clientSecret}
              paymentType="IDEAL_PAY"
            />
          </Elements>
        </Modal>
      )}
    </div>
  );
};

const StripeCheckoutForm = ({paymentIntentId, clientSecret, paymentType}) => {
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const disabled = !stripe || isProcessing || isSuccess;
  const bgColor = isSuccess ? color.green : color.dodger_blue;

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setIsProcessing(true);
    const handleError = error => {
      setIsProcessing(false);
      setMessage(error.message);
    };
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    let return_url = `${window.location.origin}/checkout`;
    if (paymentType === 'IDEAL_PAY') {
      let isparam = window.location.href.indexOf('?') !== -1;
      return_url = `${window.location.origin}/checkout/${isparam ? '&' : '?'}/orderNo=${paymentIntentId}&payType=YJ`;
    }
    const {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      clientSecret: clientSecret,
      confirmParams: {return_url: return_url},
    });
    if (error) {
      setIsProcessing(false);
      setMessage(error.message);
      navigate('/payment/failure');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        disabled={disabled}
        style={{
          ...payButton,
          backgroundColor: bgColor,
        }}>
        {isSuccess ? (
          <span className="check-icon">&#10003;</span>
        ) : isProcessing ? (
          'Processing...'
        ) : (
          'Pay'
        )}
      </button>
      <span style={messageStyle}>{message}</span>
    </form>
  );
};

// Styling the Pay Now button
const payButton = {
  backgroundColor: color.dodger_blue,
  borderRadius: 10,
  padding: '10px 20px 10px 20px',
  color: color.white,
  fontWeight: 'normal',
  marginTop: 15,
  width: '100%',
  cursor: 'pointer',
  opacity: 0.9,
  transition: 'background-color 0.3s ease',
};

const messageStyle = {
  color: color.black,
  fontWeight: 'normal',
  fontFamily: 'serif',
  fontSize: '16px',
};

export default CheckoutForm;
