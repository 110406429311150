import React from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react';
import {PiMinusCircle, PiPlusCircleFill} from 'react-icons/pi';
import {questionAnswerVector} from '../../assets/images';

const faqs = [
  {
    question: 'What if I fail to make the payment?',
    answer:
      'Please check your balance of selected payment method or try other payment methods. If it fails again, please contact us to report the problem.',
  },
  {
    question: 'How do I rent a power bank?',
    answer:
      'There is a QR code on every machine. Scan the code with the camera app on your phone and you will be guided through the link to the Genie Charger app, Set up your account, choose your payment method according to the instructions. A power bank will pop out after a successful payment and you can take it out and start charging.',
  },
  {
    question: 'Where are the cables of the power bank?',
    answer:
      'The grey cables are set on the obverse side of the power bank. There are 3 types of cables: Apple, Type-C, micro USB. Please select properly.',
  },
  {
    question: 'Which phone models can the power bank be used with?',
    answer:
      'Our power banks are equipped with 3 types of cables and wireless charging, covering a great amount of mobile phone models in the market.',
  },
  {
    question: 'Why is the power bank not charging?',
    answer:
      'A) Sometimes our power banks may be uncharged due to poor contact. If you happen to rent an uncharged power bank, please insert the back within the free time and rent another one. No extra cost will be conducted. B) Please make sure the power button on the side is showing a blue light. If it is not press the orange button to turn the device on and plug in your phone to activate the charge.',
  },
  {
    question: 'How long will it take to refund deposit or withdraw balance?',
    answer:
      'All our payments are processed by third party online payment company. When the rental time begins the payment is AUTHORIZED not charged. On returning the device the amount of time the charger was used is calculated and that amount is CAPTURED as and charged. The initial authorization only hold the total purchase amount until the transaction is complete at which time the total of the transaction is captured and deducted from your account.',
  },
  {
    question: 'How do I return the power bank?',
    answer:
      'Tuck the cables into the channel securely and insert the side of the power bank with metal chips into an empty socket till it reaches the bottom and stabilized.',
  },
];
const Help: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="flex items-center gap-6">
            <Link
              to="/"
              className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
              <SlArrowLeft />
            </Link>
            <h4 className="text-2xl">Help</h4>
          </div>
        </div>
      </div>
      <div className="pb-6">
        <div className="container">
          <div className="hero-image mb-[30px]">
            <img
              className="mb-6 max-w-[80%] sm:max-w-[484px] w-full mx-auto"
              alt="Help"
              src={questionAnswerVector}
            />
          </div>
          <div className="mt-10 flex flex-col gap-1">
            {faqs.map((faq, index) => (
              <Disclosure key={faq.question} as="div" className="group">
                <div className="pt-5 pb-6 pe-4 border-b border-gray-300 group-data-[open]:border-white group-data-[open]:rounded-[6px] [.group:not([data-open])_&]:bg-white group-data-[open]:bg-gray-50">
                  <DisclosureButton className="flex w-full items-start text-left">
                    <span className="ml-5 mr-2 flex h-6 items-center">
                      <PiPlusCircleFill
                        size={24}
                        className="text-blue-950 h-7 w-7 group-data-[open]:hidden"
                      />
                      <PiMinusCircle
                        size={24}
                        className="text-blue-950 h-7 w-7 [.group:not([data-open])_&]:hidden"
                      />
                    </span>
                    <h5 className="text-xl">{faq.question}</h5>
                  </DisclosureButton>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-0">
                    <DisclosurePanel as="div" className="mt-3 pl-[52px]">
                      <p>{faq.answer}</p>
                    </DisclosurePanel>
                  </Transition>
                </div>
              </Disclosure>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Help;
