/* eslint-disable react-hooks/exhaustive-deps */
import {
  Elements,
  ExpressCheckoutElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import axios from 'axios';
import {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {color} from '../../assets/colors';
import {loginBanner} from '../../assets/images/index';
import {PAYMENT_CREATE} from '../../services/apiStaticData';
import {getCurrencySymbol} from '../../utils/functionCommon';

Modal.setAppElement('#root');
// Load Stripe.js with your publishable key
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
);

// CheckoutForm Component
const CheckoutForm = () => {
  const navigate = useNavigate();
  const [paymentCreateData, setPaymentCreateData] = useState(null);
  const [stripePaymentElementOption, setStripePaymentElementOption] =
    useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const checkURLCheckout = new URLSearchParams(window.location.search);

  // Check for query params on page load
  const payment_intent = checkURLCheckout.get('payment_intent');

  useEffect(() => {
    if (payment_intent) {
      navigate(`/popout-timer?tradeNo=${payment_intent}&payMode=stripe`);
    }
  }, [payment_intent, navigate]);

  const handlePayment = async () => {
    const query = new URLSearchParams(window.location.search);
    const stationId =
      localStorage.getItem('cabinetID') || query.get('cabinetID');
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${PAYMENT_CREATE}`,
        {stationId: stationId},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('reviveToken')}`,
          },
        },
      );
      if (response) {
        setPaymentIntentId(response?.data?.data?.intentId);
        setClientSecret(response?.data?.data?.clientSecret);
        setPaymentCreateData(response?.data?.data);
        const options = {
          clientSecret: response?.data?.data?.clientSecret,
          appearance: {
            theme: 'stripe',
          },
        };
        setStripePaymentElementOption(options);
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.error?.message,
        }).then(() => navigate('/'));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text:
          error?.response?.data?.error?.message ||
          'Error creating the payment.',
      }).then(() => navigate('/'));
    }
  };

  useEffect(() => {
    if (!checkURLCheckout.get('payment_intent')) {
      handlePayment();
    }
  }, [!checkURLCheckout.get('payment_intent')]);

  return (
    <div className="py-6 min-h-full flex flex-col items-center">
      <div className="container text-start">
        <div className="max-w-[484px] mx-auto">
          <div>
            <img className="mb-6 w-full" alt="login-banner" src={loginBanner} />
            {clientSecret && (
              <Elements
                stripe={stripePromise}
                options={stripePaymentElementOption}>
                <StripeCheckoutForm
                  paymentIntentId={paymentIntentId}
                  clientSecret={clientSecret}
                  paymentType="STRIPE_CARD"
                  paymentCreateData={paymentCreateData}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const StripeCheckoutForm = ({
  paymentIntentId,
  clientSecret,
  paymentType,
  paymentCreateData,
}) => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isSuccess, setIsSuccess] = useState(false);
  const disabled = !stripe || isProcessing || isSuccess;
  const bgColor = isSuccess ? color.green : color.dodger_blue;

  useEffect(() => {
    if (!elements) return;

    const expressCheckoutElement = elements.getElement(ExpressCheckoutElement);

    if (expressCheckoutElement) {
      expressCheckoutElement.on('confirm', async event => {
        setIsProcessing(true);
        try {
          const {error} = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
              return_url: `${window.location.origin}/checkout?payment_intent=${paymentIntentId}`,
            },
          });

          if (error) {
            setMessage(error.message);
            setIsProcessing(false);
            navigate('/payment/failure');
          }
        } catch (err) {
          setMessage('An unexpected error occurred. Please try again.');
          setIsProcessing(false);
          navigate('/payment/failure');
        }
      });
    }
  }, [elements, stripe, clientSecret, paymentIntentId, navigate]);

  const handleSubmit = async event => {
    event.preventDefault();
    if (!stripe || !elements || !clientSecret) {
      return;
    }

    setIsProcessing(true);

    try {
      const {error: submitError} = await elements.submit();
      if (submitError) {
        setMessage(submitError.message);
        setIsProcessing(false);
        return;
      }
      const return_url = `${window.location.origin}/checkout?payment_intent=${paymentIntentId}`;

      const {error} = await stripe.confirmPayment({
        elements,
        clientSecret: clientSecret,
        confirmParams: {return_url: return_url},
      });
      // This code might not execute due to redirect
      if (error) {
        setMessage(error.message);
        setIsProcessing(false);
        navigate('/payment/failure');
      }
    } catch (err) {
      setMessage('An unexpected error occurred. Please try again.');
      setIsProcessing(false);
      navigate('/payment/failure');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-5">
        <ExpressCheckoutElement />
        <PaymentElement />
      </div>
      <button
        type="submit"
        disabled={disabled}
        style={{
          ...payButton,
          backgroundColor: bgColor,
        }}>
        {isSuccess ? (
          <span className="check-icon">&#10003;</span>
        ) : isProcessing ? (
          'Processing...'
        ) : (
          // `Pay ${paymentCreateData?.amount} ${paymentCreateData?.currency}`
          `Pay ${getCurrencySymbol(paymentCreateData?.currency)}${
            paymentCreateData?.amount
          }`
        )}
      </button>
      <span style={messageStyle}>{message}</span>
    </form>
  );
};

// Styling the Pay Now button
const payButton = {
  backgroundColor: color.dodger_blue,
  borderRadius: 10,
  padding: '10px 20px 10px 20px',
  color: color.white,
  fontWeight: 'normal',
  marginTop: 15,
  width: '100%',
  cursor: 'pointer',
  opacity: 0.9,
  transition: 'background-color 0.3s ease',
};

const messageStyle = {
  color: color.black,
  fontWeight: 'normal',
  fontFamily: 'serif',
  fontSize: '16px',
};

export default CheckoutForm;
