export const GETCAPTCHAS = '/captcha.webp';
export const VRIFYOTP = '/saas/sendVerifyCodeSms';
export const MOBILELOGIN = '/saas/mobileLogin';
export const EMAILOTPSEND = '/saas/sendVerifyCodeMail';
export const EMAILLOGIN = '/saas/emailLogin';
export const GOOGLELOGIN = '/saas/loginGoogleByCode';
export const STRIPEMULTICURANCY =
  '/cdb/payment/stripe/multicurrency/preauthOfIntent/payment';
export const PAYMENTCONFIRM = '/cdb/payment/stripe/multicurrency/confirm';
export const USEBALANCETORENT = '/cdb/cabinet/useBalanceToRent';
export const GOOGLEMAPLISTNEARAPI = '/cdb/shop/listnear';
export const PAYMENTMODE = '/cdb/cabinet/check/payment';
export const RECHARGEWALLET =
  '/cdb/payment/stripe/multicurrency/rechargeWallet';
export const ORDERCHECK = '/cdb/cabinet/ordercheck';
export const LOGIN_FACEBOOK = '/saas/loginFacebookByToken';

///// New API Calling  /////
export const SOCIAL_LOGIN = '/user/signin/social/google';
export const SOCIAL_LOGIN_FACEBOOK = '/user/signin/social/facebook';
export const SOCIAL_LOGIN_APPLE = '/user/signin/social/apple';
export const WITHOUT_SOCIAL_LOGIN = '/user/signin/phone';
export const USERINFO = '/user/userInfo';
export const WITHOUT_SOCIAL_LOGIN_EMAIL = '/user/signin/email';
export const AUTH_VERIFICATION = '/user/auth/verify';
export const PAYMENT_CREATE = '/payment/create';
export const PAYMENT_CONFIRM = '/payment/conform';
export const STATION = '/station';
export const QRCODESCAN = '/station/check';
export const ORDERLIST = '/order/user';

export const HELP_CONTACT = '#support';
