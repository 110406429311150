/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import L, {Map} from 'leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {useNavigate} from 'react-router-dom';
import {mapOptions} from '../../services/MapConfig';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Popover,
  PopoverButton,
  PopoverPanel,
  TransitionChild,
} from '@headlessui/react';
import {
  PiCaretRight,
  PiClipboardText,
  PiCrosshair,
  PiGear,
  PiHouse,
  PiList,
  PiMagnifyingGlass,
  PiPower,
  PiQuestion,
  PiQuestionMark,
  PiSignOut,
  PiWallet,
  PiX,
} from 'react-icons/pi';
import {
  locationIcon,
  locationPinListnear,
  reviveMarkerIcon,
  scanner,
  userProfile,
} from '../../assets/images';
import {color} from '../../assets/colors';
import {Link} from 'react-router-dom';
import {GOOGLEMAPLISTNEARAPI} from '../../services/apiStaticData';
import Swal from 'sweetalert2';
import {sidebarLinks} from '../../utils/data';
import StationDetails from '../StationDetails';

type MarkerDataProps = {
  type: string;
  type_icon: string;
  lat: number;
  lng: number;
  place: string;
  address: string;
  online_image: any;
  offline_image: any;
  image: any;
  loanable: number;
  returnable: number;
  distance: number;
  distance_unit: string;
  open: boolean;
  closed_until: string;
  timing: string;
  station_status: boolean;
  price: number;
  currency: string;
  time_duration_in_mins: number;
};

type PositionProps = {
  lat: number;
  lng: number;
  latitudeDelta: number;
  longitudeDelta: number;
};

const Home: React.FC = () => {
  // const centerPosition: [number, number] = [52.3725, 4.8872];

  const navigate = useNavigate();
  const markerPlace = {
    type: '',
    type_icon: '',
    lat: 44.6455755192,
    lng: -1.25011620165,
    place: '',
    address: '',
    online_image: '',
    offline_image: '',
    image: '',
    loanable: 0,
    returnable: 0,
    distance: 0,
    distance_unit: '',
    open: false,
    closed_until: '',
    timing: '',
    station_status: false,
    price: 0,
    currency: '',
    time_duration_in_mins: 0,
  };

  const initialPosition = {
    lat: 52.367845617427975,
    lng: 4.902928148300927,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  };

  const initialDragPosition = {
    lat: 23.0119097,
    lng: 72.5118954,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  };

  const defaultIcon = L.icon({
    iconUrl: reviveMarkerIcon,
    iconSize: [36, 40],
    iconAnchor: [15, 44],
    popupAnchor: [1, -44],
    shadowSize: [40, 40],
  });

  const currentLocationIcon = L.icon({
    iconUrl: locationIcon,
    iconSize: [25, 25],
    iconAnchor: [12, 41],
    popupAnchor: [1, -44],
    shadowSize: [40, 40],
  });

  const dragLocationMarker = L.icon({
    iconUrl: locationPinListnear,
    iconSize: [25.9062, 41.75],
    iconAnchor: [12, 41],
    popupAnchor: [1, -44],
    shadowSize: [40, 40],
  });

  const DEFAULT_ZOOM_LEVEL = 13;
  const MAX_ZOOM_LEVEL = 23; //20
  const MIN_ZOOM_LEVEL = 3;

  const mapRef = useRef<Map | null>(null);

  const [bottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false);
  const [dragMarker, setDragMarker] = useState(initialDragPosition);
  const [dragTimeout, setDragTimeout] = useState<NodeJS.Timeout | null>(null);
  const [selectedMarkerPlace, setSelectedMarkerPlace] =
    useState<MarkerDataProps>(markerPlace);
  const [currentPosition, setCurrentPosition] =
    useState<PositionProps>(initialPosition);
  const [locations, setLocations] = useState([]);
  const [clusterRadius, setClusterRadius] = useState<number>(40); // default radius
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isToolTipText, setIsToolTipText] = useState<boolean>(false);
  const [isMarkerDraggable, setIsMarkerDraggable] = useState<boolean>(true);
  const [isClickDisabled, setClickDisabled] = useState<boolean>(false);
  const [isMount, setIsMount] = useState<boolean>(false);
  const [showStationDetails, setShowStationDetails] = useState<boolean>(false);
  const [stationDetails, setStationDetails] = useState<any>({});

  const [centerPosition, setCenterPosition] = useState<[number, number]>([
    52.3725, 4.8872,
  ]); // Default center
  const [zoomLevel, setZoomLevel] = useState(
    MAX_ZOOM_LEVEL - DEFAULT_ZOOM_LEVEL,
  ); // Default zoom

  const ZoomHandler = () => {
    const map = useMapEvents({
      zoomend: () => {
        setZoomLevel(map.getZoom()); // Get latest zoom level
      },
    });
    return null;
  };

  /**
   * An error was occurring when the map layer didn't load. To fix this,
   * I have added a timeout so that the pointer appears only after the layer has fully loaded."
   */

  const handleShowStationDetails = () => {
    const tempLocation = [...locations];
    setLocations([]);
    setClickDisabled(false);

    setShowStationDetails(false);

    const timeout = setTimeout(() => {
      setLocations(tempLocation);
      setClickDisabled(true);
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  };

  // Custom clustering options
  const clusterOptions = {
    chunkedLoading: true,
    removeOutsideVisibleBounds: true,
    maxClusterRadius: clusterRadius, // Dynamically set the cluster radius
  };

  const Login_Token = localStorage.getItem('reviveToken') || null;
  const userInformation = JSON.parse(localStorage.getItem('userInfo') || '{}');

  const toggleBottomSheetOpen = () => setBottomSheetOpen(true);

  const toggleBottomSheetClose = () => setBottomSheetOpen(false);

  const handleMarkerPlaceClick = (markerItem: typeof markerPlace) => {
    setSelectedMarkerPlace(markerItem);
    toggleBottomSheetOpen();
  };

  const createClusterCustomIcon = function (cluster: any) {
    return L.divIcon({
      className: 'custom-cluster-icon',
      html: `
        <div class="custom-cluster-icon-inner">
          ${cluster.getChildCount()}
        </div>`,
      iconSize: L.point(40, 40, true), // Set the size of the icon
      iconAnchor: L.point(20, 20, true), // Anchor the icon at the center
    });
  };

  const getCurrentLocation = () => {
    if (navigator?.geolocation) {
      navigator?.geolocation?.getCurrentPosition?.(
        position => {
          const newPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          };
          setCurrentPosition(newPosition);
          setDragMarker(newPosition);
          setIsMarkerDraggable(false);
          mapRef.current?.flyTo(newPosition, DEFAULT_ZOOM_LEVEL + 2, {
            animate: true,
            duration: 0.5,
            easeLinearity: 0.3, // Controls animation easing
          });
        },
        error => {
          console.error('Error getting the current position:', error);
          setShowAlert(true);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      setShowAlert(true); // Handle case where geolocation is not supported
    }
  };

  const Alert = ({onClose}: {onClose: () => void}) => {
    return (
      <div
        onClick={() => setShowAlert(false)}
        className="fixed top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center z-[1000]">
        <div className="bg-white shadow-[0px_0px_10px_#000000/10] text-center flex flex-col justify-center items-center rounded-xl">
          <div className="px-4 py-5 flex items-center justify-center text-center flex-col">
            <span className="text-black text-2xl mb-3 capitalize font-[serif]">
              {'error'}
            </span>
            <span className="text-black mt- font-[serif] text-xl">
              {'Error: The Geolocation service failed.'}
            </span>
          </div>
          <div
            className="border border-[#DCDFE6] h-full w-full overflow-hidden rounded-b-xl"
            onClick={onClose}>
            <button className="py-2 px-2.5 rounded-[3px] text-emerald-500 cursor-pointer">
              {'Sure'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const Spinner = () => (
    <div className="spinner">
      <div className="custom-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  // Detect screen width and adjust cluster radius accordingly
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width <= 480) {
        setClusterRadius(20); // Mobile devices //50
      } else if (width <= 768) {
        setClusterRadius(30); // Tablets  //60
      } else if (width <= 1024) {
        setClusterRadius(40); // Laptops or small screens //70
      } else {
        setClusterRadius(50); // Desktops or large screens  //80
      }
    };

    // Call the function initially and also set a listener for window resize events
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Detect current location with permission alert
  useEffect(() => {
    getCurrentLocation();
  }, []);

  const sidebarItems = sidebarLinks(Login_Token);
  ////////   Listener API calling start ////
  const handleMapDrag = () => {
    if (!isMarkerDraggable) {
      const center = mapRef.current?.getCenter();

      if (center) {
        setDragMarker({
          lat: center.lat,
          lng: center.lng,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        });
      }
    }
  };

  // Add the event listener in useEffect
  useEffect(() => {
    const mapInstance = mapRef.current;
    if (mapInstance) {
      mapInstance.on('drag', handleMapDrag);
    }

    return () => {
      if (mapInstance) {
        mapInstance.off('drag', handleMapDrag);
      }
    };
  }, [isMarkerDraggable]);

  const handleListenerLatLang = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('zoomLevel', '1');
    formData.append('lng', dragMarker.lng.toString());
    formData.append('lat', dragMarker.lat.toString());
    formData.append('mapType', 'WGS－84');
    formData.append('coordType', 'WGS－84');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}${GOOGLEMAPLISTNEARAPI}`,
        {
          method: 'POST',
          body: formData,
        },
      );
      if (response.status === 200) {
        const data = await response.json();
        setLocations(data?.list);
        setTimeout(() => {
          setClickDisabled(true);
        }, 1000);
        if (!data?.list || data.list.length === 0) {
          setIsToolTipText(true);
          setTimeout(() => {
            setIsToolTipText(false);
          }, 1000);
        }
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Failed to fetch data from the API',
        });
      }
    } catch (error) {
      console.error('Error calling the API', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleListenerLatLang();
    setIsMount(true);
  }, []);

  ///// logout functionality ////
  const handleLogout = () => {
    localStorage.removeItem('QRID');
    localStorage.removeItem('reviveToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('cabinetID');
    setIsOpen(false);
    navigate('/');
  };

  return (
    <>
      {!showStationDetails && (
        <div className="h-screen w-full relative">
          <div className="fixed top-6 left-0 right-0 px-4 w-full z-[1000]">
            <div className="flex items-center justify-between gap-4">
              <button
                onClick={open}
                className="btn-emerald rounded-full w-[50px] h-[50px] flex items-center justify-center border-black/20 border shadow-sm">
                <PiList size={32} />
              </button>
              <Link
                to="/help"
                className="btn-emerald rounded-full w-[50px] h-[50px] flex items-center justify-center border-black/20 border shadow-sm">
                <PiQuestionMark size={32} />
              </Link>
            </div>
          </div>
          {isLoading && <Spinner />}
          {isToolTipText && (
            <p className="no-equipment fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-[#00000080] text-white z-[999] py-[10px] px-[20px] rounded-lg text-xs">
              No equipment was found nearby
            </p>
          )}
          <Dialog
            open={isOpen}
            as="div"
            className="relative z-[99999]"
            onClose={close}>
            <DialogBackdrop
              transition
              className="fixed inset-0 bg-black bg-opacity-[58%] transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
            />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full">
                  <DialogPanel
                    transition
                    className="pointer-events-auto relative w-screen sm:max-w-[313px] max-w-[300px] transform transition duration-500 ease-in-out data-[closed]:-translate-x-full sm:duration-700">
                    <TransitionChild>
                      <div className="absolute right-0 top-0 sm:-mr-12 -mr-11 flex pl-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:pl-3.5">
                        <button
                          type="button"
                          onClick={close}
                          className="relative rounded-md text-white/70 focus:outline-none hover:text-white">
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <PiX
                            aria-hidden="true"
                            size={30}
                            className="font-bold"
                          />
                        </button>
                      </div>
                    </TransitionChild>
                    <div className="flex h-full flex-col bg-white">
                      <div className="px-6 py-5 lg:py-10">
                        <DialogTitle
                          as="div"
                          className="flex items-center gap-3">
                          <div className="profile-ring w-[60px] h-[60px] flex-none">
                            <img
                              className="rounded-full profile-img bg-amber-600 h-[54px] w-[54px]"
                              src={userProfile}
                              width={55}
                              height={55}
                              alt="user-profile"
                            />
                          </div>
                          {Login_Token ? (
                            <>
                              <div className="flex-1">
                                {userInformation?.firstName ||
                                userInformation?.lastName ? (
                                  <Popover>
                                    <PopoverButton className="w-[190px] truncate text-xl text-left text-blue-950 font-semibold mb-1">
                                      {userInformation?.firstName}{' '}
                                      {userInformation?.lastName}
                                    </PopoverButton>
                                    <PopoverPanel
                                      transition
                                      anchor="top"
                                      className="z-10 rounded-md px-2 py-1 text-xs text-white bg-blue-950 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0">
                                      {userInformation?.firstName}{' '}
                                      {userInformation?.lastName}
                                    </PopoverPanel>
                                  </Popover>
                                ) : null}
                                <Popover>
                                  <PopoverButton className="w-[190px] truncate text-sm text-left text-blue-950">
                                    {userInformation?.email ? (
                                      <span>{userInformation.email}</span>
                                    ) : userInformation?.phone ? (
                                      <span>{userInformation.phone}</span>
                                    ) : null}
                                  </PopoverButton>
                                  <PopoverPanel
                                    transition
                                    anchor="bottom"
                                    className="z-10 rounded-md px-2 py-1 text-xs text-white bg-blue-950 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0">
                                    {userInformation?.email ? (
                                      <span>{userInformation.email}</span>
                                    ) : userInformation?.p_mobile ? (
                                      <span>{userInformation.p_mobile}</span>
                                    ) : null}
                                  </PopoverPanel>
                                </Popover>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex-1">
                                <h2
                                  onClick={() => {
                                    navigate('/login');
                                  }}>
                                  Please Login
                                </h2>
                              </div>
                            </>
                          )}
                        </DialogTitle>
                      </div>
                      <div className="relative flex-1 overflow-y-auto">
                        <ul>
                          {sidebarItems.map(
                            ({id, navigateTo, icon: Icon, label}) => (
                              <li key={id}>
                                <Link
                                  to={navigateTo}
                                  className="flex items-center px-6 text-gray-950 py-0 group hover:bg-blue-950 transition ease-in-out duration-400">
                                  <span className="flex items-center gap-3 py-5 w-full border-b border-gray-medium group-hover:border-blue-950/0">
                                    <Icon
                                      size={22}
                                      className="text-gray-950 group-hover:text-white flex-none"
                                    />
                                    <span className="group-hover:text-white">
                                      {label}
                                    </span>
                                  </span>
                                </Link>
                              </li>
                            ),
                          )}
                          {Login_Token && (
                            <li>
                              <Link
                                to="#"
                                onClick={handleLogout}
                                className="flex items-center px-6 text-red-600 py-0 group hover:bg-blue-950 transition ease-in-out duration-400">
                                <span className="flex items-center gap-3 py-5 ">
                                  <PiSignOut
                                    size={22}
                                    className="text-red-600 group-hover:text-white rotate-180 flex-none"
                                  />
                                  <span className="group-hover:text-white">
                                    Log Out
                                  </span>
                                </span>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                      {/* <div className="px-6 py-[30px]">
                    <button className="btn-primary text-center px-4">
                      Join Us
                    </button>
                  </div> */}
                    </div>
                  </DialogPanel>
                </div>
              </div>
            </div>
          </Dialog>

          <MapContainer
            ref={mapRef}
            zoom={zoomLevel}
            maxZoom={MAX_ZOOM_LEVEL}
            minZoom={MIN_ZOOM_LEVEL}
            zoomControl={false}
            center={centerPosition}
            style={{height: '100vh', width: '100%'}}
            markerZoomAnimation
            zoomAnimation
            zoomAnimationThreshold={4}
            preferCanvas>
            <ZoomHandler />
            {isMount && (
              <ReactLeafletGoogleLayer
                apiKey={mapOptions.googleMapApiKey}
                styles={mapOptions.reactLeafletGoogleLayerTheme}
              />
            )}
            {currentPosition.lat !== initialPosition.lat &&
            currentPosition.lng !== initialPosition.lng ? (
              <Marker
                icon={currentLocationIcon}
                position={currentPosition}></Marker>
            ) : null}
            <MarkerClusterGroup
              {...clusterOptions}
              iconCreateFunction={createClusterCustomIcon}
              spiderfyOnMaxZoom={true}
              polygonOptions={{
                fillColor: color.white,
                color: color.blue_dianne,
                weight: 1,
                opacity: 0.4,
                fillOpacity: 0.8,
              }}
              showCoverageOnHover={true}>
              {locations?.length > 0 &&
                locations.map((marker: any, index) => {
                  return (
                    <Marker
                      key={`${index}`}
                      eventHandlers={{
                        click: e => {
                          setShowStationDetails(true);
                          setStationDetails(marker);
                          setCenterPosition([
                            marker.latitude,
                            marker.longitude,
                          ]);
                        },
                      }}
                      position={[marker?.latitude, marker?.longitude]}
                      icon={defaultIcon}></Marker>
                  );
                })}
            </MarkerClusterGroup>
          </MapContainer>

          <div className="px-4 sm:max-w-[623px] max-w-[382px] mx-auto fixed w-full left-0 right-0 sm:bottom-[60px] bottom-10 z-[1000]">
            <div className="scan-qr flex justify-between items-center w-full p-2">
              <button className="md:w-[67px] w-[57px] md:h-[67px] h-[57px]">
                {/* <PiMagnifyingGlass size={30} className="text-emerald-500" /> */}
              </button>
              <p className="font-semibold text-base md:text-xl lg:text-2xl sm:mt-10 mt-[35px]">
                Scan QR to start rent
              </p>
              <button
                onClick={getCurrentLocation}
                className="btn-dark-blue rounded-full flex flex-none justify-center items-center md:w-[67px] w-[57px] md:h-[67px] h-[57px]">
                <PiCrosshair size={32} className="text-emerald-500" />
              </button>
            </div>
            <div className="absolute -top-4 sm:-top-5 left-1/2 -translate-x-2/4 -translate-y-2/4 h-fit">
              <button
                onClick={() => {
                  if (isClickDisabled) {
                    navigate('/scan');
                  }
                }}
                className="btn-dark-blue rounded-full w-[86px] h-[86px] sm:w-[102px] sm:h-[102px] rounded-full flex items-center justify-center mx-auto">
                <img src={scanner} alt="scanner" />
              </button>
            </div>
          </div>
          {showAlert && <Alert onClose={() => setShowAlert(false)} />}
        </div>
      )}
      {showStationDetails && (
        <StationDetails
          state={stationDetails}
          setShowStationDetails={handleShowStationDetails}
        />
      )}
    </>
  );
};

export default Home;
