const locationPinListnear = `${process.env.REACT_APP_S3_URI}/webapp/listnearPin.png`;
const idealCard = `${process.env.REACT_APP_S3_URI}/webapp/idealCard.png`;
const cardImg = `${process.env.REACT_APP_S3_URI}/webapp/card.svg`;
const facebook = `${process.env.REACT_APP_S3_URI}/webapp/facebook.svg`;
const mobile = `${process.env.REACT_APP_S3_URI}/webapp/mobile.svg`;
const loginBanner = `${process.env.REACT_APP_S3_URI}/webapp/login-banner.png`;
const otpBanner = `${process.env.REACT_APP_S3_URI}/webapp/otp-banner.svg`;
const reviveStationImg = `${process.env.REACT_APP_S3_URI}/webapp/revive-station-img.png`;
const reviveMarkerIcon = `${process.env.REACT_APP_S3_URI}/webapp/revive_marker_icon.png`;
const locationIcon = `${process.env.REACT_APP_S3_URI}/webapp/locationIcon.png`;
const leSailFish = `${process.env.REACT_APP_S3_URI}/webapp/lesailfish.jpg`;
const theTeamBuilding = `${process.env.REACT_APP_S3_URI}/webapp/theteambuilding.jpg`;
const jeuDeBar = `${process.env.REACT_APP_S3_URI}/webapp/jeudebar.jpg`;
const kapSalonBroers = `${process.env.REACT_APP_S3_URI}/webapp/kapsalonbroers.jpg`;
const uvsvClub = `${process.env.REACT_APP_S3_URI}/webapp/uvsvclub.jpg`;
const cafeNar = `${process.env.REACT_APP_S3_URI}/webapp/cafeNar.jpg`;
const gerrie = `${process.env.REACT_APP_S3_URI}/webapp/gerrie.png`;
const greenClock = `${process.env.REACT_APP_S3_URI}/webapp/green-clock.png`;
const greenMarker = `${process.env.REACT_APP_S3_URI}/webapp/green-marker.png`;
const greyExplore = `${process.env.REACT_APP_S3_URI}/webapp/grey-explore.png`;
const navigateLocation = `${process.env.REACT_APP_S3_URI}/webapp/navigate-location.png`;
const scanner = `${process.env.REACT_APP_S3_URI}/webapp/icon/scanner.svg`;
const apple = `${process.env.REACT_APP_S3_URI}/webapp/icon/apple-icon.svg`;
const email = `${process.env.REACT_APP_S3_URI}/webapp/icon/email-icon.svg`;
const flagDownArrow = `${process.env.REACT_APP_S3_URI}/webapp/icon/flag-down-arrow.svg`;
const google = `${process.env.REACT_APP_S3_URI}/webapp/icon/google-icon.svg`;
const noneRotateLeft = `${process.env.REACT_APP_S3_URI}/webapp/icon/none-rotate-left.svg`;
const reviveRound = `${process.env.REACT_APP_S3_URI}/webapp/icon/revive-round.png`;
const scannerBlack = `${process.env.REACT_APP_S3_URI}/webapp/icon/scanner-black.svg`;
const letterT = `${process.env.REACT_APP_S3_URI}/webapp/icon/letter-t.svg`;
const scanCode = `${process.env.REACT_APP_S3_URI}/webapp/icon/scan-code.svg`;
const scanCodeWhite = `${process.env.REACT_APP_S3_URI}/webapp/icon/scan-code-white.svg`;
const check = `${process.env.REACT_APP_S3_URI}/webapp/icon/check.svg`;
const userProfile = `${process.env.REACT_APP_S3_URI}/webapp/user-profile.png`;
const failed = `${process.env.REACT_APP_S3_URI}/webapp/icon/failed.png`;
const success = `${process.env.REACT_APP_S3_URI}/webapp/icon/success.png`;
const profileBg = `${process.env.REACT_APP_S3_URI}/webapp/profile-bg.svg`;
const walletBg = `${process.env.REACT_APP_S3_URI}/webapp/wallet-bg.svg`;
const masterCardLogo = `${process.env.REACT_APP_S3_URI}/webapp/master-card-logo.svg`;
const agreementVector = `${process.env.REACT_APP_S3_URI}/webapp/agreement-vector.svg`;
const termsConditionsVector = `${process.env.REACT_APP_S3_URI}/webapp/terms-conditions-vector.svg`;
const privacyPolicyVector = `${process.env.REACT_APP_S3_URI}/webapp/privacy-policy-vector.svg`;
const mobileWithPowerbank = `${process.env.REACT_APP_S3_URI}/webapp/mobile-with-powerbank.png`;
const questionAnswerVector = `${process.env.REACT_APP_S3_URI}/webapp/question-answer-vector.svg`;
const acceptTermsCondition = `${process.env.REACT_APP_S3_URI}/webapp/accept-terms-condition.svg`;
const playStore = `${process.env.REACT_APP_S3_URI}/webapp/play-store.svg`;
const appStore = `${process.env.REACT_APP_S3_URI}/webapp/app-store.svg`;
const eightSlotMachineLeft = `${process.env.REACT_APP_S3_URI}/webapp/8-slot-machine-left.png`;
const refreshIcon = `${process.env.REACT_APP_S3_URI}/webapp/refresh.svg`;



export {
  loginBanner,
  otpBanner,
  reviveStationImg,
  scanner,
  apple,
  email,
  flagDownArrow,
  google,
  noneRotateLeft,
  reviveMarkerIcon,
  locationIcon,
  leSailFish,
  theTeamBuilding,
  jeuDeBar,
  kapSalonBroers,
  uvsvClub,
  greenClock,
  greenMarker,
  greyExplore,
  navigateLocation,
  cafeNar,
  gerrie,
  reviveRound,
  scannerBlack,
  letterT,
  scanCode,
  scanCodeWhite,
  userProfile,
  failed,
  success,
  profileBg,
  walletBg,
  masterCardLogo,
  agreementVector,
  termsConditionsVector,
  privacyPolicyVector,
  mobileWithPowerbank,
  questionAnswerVector,
  acceptTermsCondition,
  playStore,
  appStore,
  eightSlotMachineLeft,
  locationPinListnear,
  idealCard,
  cardImg,
  check,
  facebook,
  mobile,
  refreshIcon
};
