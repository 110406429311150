import React from 'react';
import {Navigate} from 'react-router-dom';

interface PrivateRouteLoginProps {
  element: React.ReactElement;
}

const PrivateRouteLogin: React.FC<PrivateRouteLoginProps> = ({element}) => {
  const Login_Token = localStorage.getItem('reviveToken');

  return Login_Token ? element : <Navigate to="/" replace />;
};

export default PrivateRouteLogin;
