/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {
  PiArrowCounterClockwise,
  PiArrowUUpLeft,
  PiCalendarDots,
  PiClock,
  PiCopySimple,
  PiLightning,
  PiTreeStructure,
} from 'react-icons/pi';
import classNames from 'classnames'; // Import classNames
import Swal from 'sweetalert2';
import axios from 'axios';
import {ORDERLIST} from '../../services/apiStaticData';
import CommonPagination from '../../components/common/pagination/CommonPagination';

const OrderList: React.FC = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const orderList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}${ORDERLIST}?page=${page}&pageSize=10`,
        {
          headers: {
            Token: `${localStorage.getItem('reviveToken')}`,
          },
        },
      );

      if (response.status === 200 && response?.data?.code === 0) {
        const items = response.data.page.records;

        // Filter borrowItems and finishItems based on conditions
        const filteredBorrowItems = items.filter(
          (item: any) =>
            item.pJiesuanid === 0 && (item.pZujie === 1 || item.pZujie === 4),
        );
        const filteredFinishItems = items.filter(
          (item: any) =>
            (item.pZujie !== 1 && item.pZujie !== 4) || item.pJiesuanid === 1,
        );
        setOrderData(response.data.page.records);
        setTotalDataCount(response.data.page.total);
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.data?.msg,
        }).then(() => navigate('/'));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while getting user info.',
      }).then(() => navigate('/'));
    }
  };

  useEffect(() => {
    orderList();

    const intervalId = setInterval(() => {
      orderList();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [page]);

  const getZujieStatus = (status: number | undefined) => {
    switch (status) {
      case 0:
        return 'Not Completed';
      case 1:
        return 'Renting';
      case 2:
        return 'Canceled';
      case 3:
        return 'Returned';
      case 4:
        return 'Fault occurred';
      case 5:
        return 'Overtime';
      default:
        return null;
    }
  };
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return formattedTime;
  };

  const handlePayment = (order: any) => {
    navigate(
      `/checkout?price=${order.pJiesuan}&payType=FK&outTradeNo=${order.pOrderid}&cabinetID=${order.pCabinetid}`,
    );
  };

  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="flex items-center gap-6">
            <Link
              to="/"
              className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
              <SlArrowLeft />
            </Link>
            <h4 className="text-2xl">My Order</h4>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[30px]">
            {orderData?.map((order: any, index) => (
              <div
                className="bg-white border border-gray-medium rounded-lg p-6 shadow"
                key={index}>
                <Link to="/order-list/order-details" state={order}>
                  <div className="grid grid-cols-2">
                    <div className="mb-[14px]">
                      <h6 className="mb-3">Order No.</h6>
                      <div className="flex items-center gap-1">
                        <PiCopySimple className="text-lg flex-none text-amber-600 cursor-pointer" />
                        <span className="text-sm text-gray-950">
                          {order?.pOrderid}
                        </span>
                      </div>
                    </div>
                    <div>
                      <h5 className="text-xl text-gray-950 text-end">
                        {order?.pCurrencyName} {order?.pjiesuan}
                      </h5>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 grid-rows-2 gap-2 place-items-stretc py-[14px] mb-[14px] border-y border-gray-medium">
                    <div className="col-span-4">
                      <h6 className="flex items-center gap-2">
                        <PiLightning className="text-lg flex-none" />
                        <span>Venue Rented:</span>
                      </h6>
                    </div>
                    <div className="col-span-2">
                      <p className="text-sm text-gray-950 text-end">
                        {order?.pShopName}
                      </p>
                    </div>
                    <div className="col-span-4">
                      <h6 className="flex items-center gap-2">
                        <PiArrowUUpLeft className="text-lg flex-none" />
                        <span>Venue Returned:</span>
                      </h6>
                    </div>
                    <div className="col-span-2">
                      <p className="text-sm text-gray-950 text-end">
                        {order?.pShopName}
                      </p>
                    </div>
                  </div>
                </Link>
                <div className="grid grid-cols-2">
                  <div>
                    <p className="text-gray-600 text-sm flex items-center gap-2 mb-[9px]">
                      <PiCalendarDots className="text-lg flex-none" />
                      {formatDate(order?.pBorrowtime)}
                    </p>
                    <p className="text-gray-600 text-sm flex items-center gap-2">
                      <PiClock className="text-lg flex-none" />
                      {formatTime(order?.pBorrowtime)}
                    </p>
                  </div>
                  <div className="text-end">
                    {getZujieStatus(order?.pZujie) && ( // Use order here
                      <span
                        className={classNames(
                          'inline-block text-center px-[14px] py-[6px] leading-tight text-sm rounded-[5px] min-w-[100px]',
                          {
                            'bg-amber-600 text-gray-950': order?.pZujie === 1,
                            'bg-gray-950 text-white': order?.pZujie !== 1,
                          },
                        )}>
                        {getZujieStatus(order?.pZujie)}{' '}
                        {/* Use the function here */}
                      </span>
                    )}
                    {/* {order?.palipayjsSm === '代扣支付失败' &&
                      order?.pZujie != 2 && (
                        <button
                          className="btn-primary ms-1 font-normal px-4 py-[6px] w-auto leading-tight text-sm rounded-[5px] min-w-[100px]"
                          onClick={() => handlePayment(order)}>
                          Payment
                        </button>
                      )} */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            {orderData && orderData.length >= 1 && (
              <div className="">
                <CommonPagination
                  totalCount={totalDataCount}
                  activePage={page}
                  pageCount={Math.ceil(totalDataCount / 10)}
                  onPageChange={e => {
                    setPage(e);
                  }}
                  className="my-5 flex gap-3 items-center"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
