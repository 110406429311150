import React from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {privacyPolicyVector} from '../../assets/images';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="flex items-center gap-6">
            <Link
              to="/station-detail"
              className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
              <SlArrowLeft />
            </Link>
            <h4 className="text-2xl">Privacy Policy</h4>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-4">
        <div className="container">
          <div className="hero-image mb-[30px]">
            <img
              className="mb-6 max-w-[80%] sm:max-w-[484px] w-full mx-auto"
              alt="Privacy Policy"
              src={privacyPolicyVector}
            />
          </div>
          <div className="privacy-policy-content">
            <p>
              This Application collects some Personal Data from its Users. This
              document can be printed for reference by using the print command
              in the settings of any browser.
            </p>
            <h4 style={{marginTop: '20px'}}>Owner and Data Controller</h4>
            <p>
              Revive Sharing B.V. – Jan Pieterszoon Coenstraat 41bis, 3531EM,
              Utrecht, the Netherlands
            </p>
            <h4>Types of Data collected</h4>
            <p>
              Our Application collects various types of Personal Data, either
              directly or through third parties, including but not limited to:
              tracking data; unique device identifiers for advertising purposes
              (such as Google Advertiser ID or IDFA); device details; geographic
              region; user count; session count; session length; Application
              activations; operating systems; email addresses; usage statistics;
              universally unique identifier (UUID); data communicated during
              service use; payment information; first name; last name; billing
              address; phone number; passwords; social media profiles;
              geographic location; precise continuous location permission;
              precise non-continuous location permission; approximate continuous
              location permission; approximate non-continuous location
              permission; camera access without storing or recording; and
              shipping address. Detailed information on each type of Personal
              Data collected can be found in the dedicated sections of this
              privacy policy or in specific explanation texts displayed prior to
              data collection. Personal Data may be voluntarily provided by the
              User, or, in the case of Usage Data, automatically collected while
              using this Application. Unless otherwise specified, all Data
              requested by this Application is mandatory and the lack of such
              Data may result in the inability to provide our services. Where
              this Application specifically indicates that certain Data is not
              mandatory, Users may choose not to provide it without any impact
              on the availability or functionality of the Service. Users who are
              uncertain about which Personal Data is mandatory are encouraged to
              contact us. The use of Cookies and other tracking technologies by
              this Application or third-party service providers utilized by this
              Application is intended to deliver the Service requested by the
              User, as well as for additional purposes outlined in this document
              and our Cookie Policy, if applicable. Users are responsible for
              any third-party Personal Data they share through this Application
              and confirm that they have obtained the third party's consent to
              provide such Data to us.
            </p>
            <h4>Mode and place of processing the Data</h4>
            <h5>Methods of processing</h5>
            <p>
              We employ appropriate security measures to prevent unauthorized
              access, disclosure, alteration, or destruction of the Data. Data
              processing is conducted using computers and/or IT-enabled tools,
              adhering to organizational procedures and methods directly related
              to the specified purposes.
            </p>
            <p>
              In addition to our team, the Data may, in certain instances, be
              accessible to specific individuals involved in the operation of
              this Application (such as those in administration, sales,
              marketing, legal, and system administration) or external entities
              (including third-party technical service providers, mail carriers,
              hosting providers, IT companies, and communications agencies)
              appointed, if necessary, as Data Processors by us. An updated list
              of these parties can be requested from us at any time.
            </p>
            <h5>Legal Basis for Processing</h5>
            <p>
              We may process Personal Data related to Users if one of the
              following conditions is met:
            </p>
            <ul>
              <li>
                Users have provided their consent for one or more specified
                purposes. Note: In certain jurisdictions, we may be permitted to
                process Personal Data until the User objects to such processing
                (“opt-out”), without relying on consent or any of the other
                legal bases. However, this does not apply where the processing
                of Personal Data is subject to European data protection laws.
              </li>
              <li>
                The provision of Data is necessary for the performance of an
                agreement with the User and/or for any pre-contractual
                obligations.
              </li>
              <li>
                Processing is required to comply with a legal obligation to
                which we are subject.
              </li>
              <li>
                {' '}
                Processing is related to a task carried out in the public
                interest or in the exercise of official authority vested in us.
              </li>
              <li>
                Processing is necessary for the purposes of the legitimate
                interests pursued by us or by a third party.
              </li>
            </ul>
            <p>
              In any situation, we are available to help clarify the specific
              legal basis applicable to the processing of Personal Data,
              particularly whether the provision of Personal Data is a statutory
              or contractual requirement, or a requirement necessary to enter
              into a contract.
            </p>
            <h4>Location of Data Processing</h4>
            <p>
              Personal Data is processed at our operating offices and at any
              other locations where the parties involved in the processing are
              situated. Depending on the User's location, transferring the
              User's Data may involve moving it to a country different from
              their own. For more information on where such transferred Data is
              processed, Users can refer to the section detailing the processing
              of Personal Data. Users have the right to understand the legal
              basis for Data transfers to countries outside the European Union
              or to any international organizations governed by public
              international law or formed by two or more countries, such as the
              United Nations. Additionally, Users can learn about the security
              measures we have implemented to protect their Data. For further
              details on any such transfers, Users can consult the relevant
              sections of this document or contact us using the information
              provided in the contact section.
            </p>
            <h5> Data Retention Period</h5>
            <p>
              Personal Data will be processed and stored for as long as
              necessary to fulfill the purposes for which it was collected.
            </p>
            <p>Therefore:</p>
            <ul>
              <li>
                Personal Data collected for purposes related to the performance
                of a contract between us and the User will be retained until the
                contract has been completely fulfilled.
              </li>
              <li>
                Personal Data collected for our legitimate interests will be
                retained as long as needed to achieve those purposes. Users can
                find specific information about the legitimate interests we
                pursue in the relevant sections of this document or by
                contacting us.
              </li>
            </ul>
            <p>
              We may retain Personal Data for a longer period if the User has
              given consent for such processing, provided that the consent is
              not withdrawn. Additionally, we may be required to retain Personal
              Data for a longer duration to comply with legal obligations or
              upon the request of an authority. Once the retention period has
              expired, Personal Data will be deleted. Consequently, after the
              retention period has ended, the rights to access, erasure,
              rectification, and data portability cannot be exercised.
            </p>
            <h5>Purposes of data processing.</h5>
            <p>
              We collect User Data to provide our Service, comply with legal
              obligations, respond to enforcement requests, protect our rights
              and interests (and those of our Users or third parties), and
              detect malicious or fraudulent activity. Additionally, Data is
              processed for purposes such as analytics, user database
              management, handling payments, registration and authentication,
              content performance and feature testing (A/B testing), social
              features, managing contacts and sending messages, infrastructure
              monitoring, location-based interactions, device permissions for
              Personal Data access, hosting and backend infrastructure, platform
              services and hosting, managing support and contact requests, and
              location processing. For detailed information about the Personal
              Data used for each purpose, Users may refer to the section
              “Detailed Information on the Processing of Personal Data.”
            </p>
            <h4>Device permissions for Personal Data access</h4>
            <p>
              Depending on the User's specific device, our Application may
              request certain permissions to access the User's device Data as
              outlined below.
            </p>
            <p>
              By default, these permissions must be granted by the User before
              the respective information can be accessed. Once permission has
              been granted, it can be revoked by the User at any time. To revoke
              these permissions, Users may refer to their device settings or
              contact us for support using the contact details provided in this
              document. The exact procedure for controlling app permissions may
              vary depending on the User's device and software. Please note that
              revoking these permissions may affect the proper functioning of
              our Application. If the User grants any of the permissions listed
              below, the respective Personal Data may be processed (i.e.,
              accessed, modified, or removed) by our Application.
            </p>
            <h5>Approximate Location Permission (Continuous)</h5>
            <p>
              This Application may request permission to access the approximate
              location of the User's device. We collect, utilize, and may share
              User location data to facilitate location-based services.
            </p>
            <h5>Approximate Location Permission (Non-continuous)</h5>
            <p>
              This Application may request permission to access the approximate
              location of the User's device. We collect, utilize, and may share
              User location data to facilitate location-based services. The
              geographic location of the User is determined in a non-continuous
              manner. This means that this Application does not continuously
              track or derive the approximate position of the User.
            </p>
            <h5>Camera Permission, No Saving or Recording</h5>
            <p>
              This Application may request permission to access the camera or
              capture images and video from the device. We do not store or
              record any of the camera output.
            </p>
            <h5>Precise Location Permission (Continuous)</h5>
            <p>
              This Application may request permission to access the precise
              location of the User's device. We collect, use, and may share User
              location data to enhance and provide location-based services.
            </p>
            <h5>Precise Location Permission (Non-continuous)</h5>
            <p>
              This Application may request permission to access the precise
              location of the User's device. We collect, utilize, and may share
              User location data to enhance and provide location-based services.
              The geographic location of the User is determined intermittently.
              This means that this Application does not continuously track or
              pinpoint the exact position of the User.
            </p>
            <h4>Detailed Information on the Processing of Personal Data</h4>
            <p>
              We collect Personal Data for the following purposes and through
              the following services.
            </p>
            <h5> Analytics</h5>
            <p>
              This section includes services that allow us to monitor and
              analyze web traffic, enabling us to track User behavior.
            </p>
            <h5>
              Analytics Service Provider (e.g., Google Analytics for Firebase)
            </h5>
            <p>
              This Application utilizes analytics services to enhance user
              experience and improve its offerings:
            </p>
            <p>
              This Application may use analytics services provided by third
              parties to collect and analyze Usage Data for purposes such as
              monitoring and optimizing the performance of this Application.
              Analytics services may share Data with other tools provided by the
              same service provider for purposes such as Crash Reporting,
              Authentication, Remote Config, or Notifications. Users can refer
              to this privacy policy for detailed explanations on the tools
              utilized. This Application may utilize identifiers for mobile
              devices and technologies similar to cookies to support its
              analytics services. Users may have the option to opt-out of
              certain analytics features through device settings, such as mobile
              device advertising settings, or by following instructions provided
              in related sections of this privacy policy. information processed
              may include details such as application opens, device information,
              geographical location, session durations, operating systems, and
              unique device identifiers for advertising purposes (e.g., Google
              Advertiser ID or IDFA). Data may be processed in the United
              States. For further information, please refer to the respective
              privacy policy.
            </p>
            <h5>Content performance and features testing (A/B testing)</h5>
            <p>
              This Application may utilize services to assess and analyze User
              responses to changes in its structure, text, or other components,
              in order to enhance user experience and optimize performance.
            </p>
            <p>
              Firebase Remote Config is a service used for configuration and
              testing purposes provided by a third-party service provider.
            </p>
            <p>
              Personal Data processed: Various types of data as described in the
              service provider's privacy policy.
            </p>
            <p>
              Place of processing: Data may be processed in the Netherlands or
              other locations as determined by the service provider.
            </p>
            <h4>Device permissions for Personal Data access</h4>
            <p>
              Revive may request specific permissions from users that enable it
              to access data on their devices, as outlined below. For detailed
              information regarding each permission and its processing, please
              refer to the relevant sections within this document.
            </p>
            <p>
              Revive may collect and process Personal Data including Approximate
              location permission (continuous), Approximate location permission
              (non-continuous), Camera permission, without saving or recording,
              Precise location permission (continuous), Precise location
              permission (non-continuous).
            </p>
            <h4>Handling payments</h4>
            <p>
              Unless otherwise stated, this application processes payments
              through external payment service providers. Users are required to
              provide payment details and personal information directly to these
              providers. This application is not involved in the collection or
              processing of such information; it only receives notification from
              the payment service provider regarding the completion of payments.
            </p>
            <h5>Stripe ( Stripe Technology Europe Ltd)</h5>
            <p>
              Stripe is a payment service provided by Stripe Technology Europe
              Ltd.
            </p>
            <p>
              Used to facilitate payment transactions on our platform. Stripe
              collects and processes your billing address, email address, first
              name, last name, payment information, and other necessary data for
              processing payments. Place of Processing: Ireland – Privacy
              Policy.
            </p>
            <h5>PayPal (PayPal Inc.)</h5>
            <p>
              PayPal is an online payment service offered by PayPal Inc.,
              enabling Users to conduct payments over the internet. PayPal
              collects and processes your billing address, email address, first
              name, last name, phone number. Place of processing: See the PayPal
              – Privacy Policy.
            </p>
            <h5>Apple Pay (Apple Inc.)</h5>
            <p>
              Apple Pay is a payment service provided by Apple Inc., which
              allows Users to make transactions using their mobile device.
            </p>
            <p>
              Apple Pay collects and processes your billing address, email
              address, first name, last name, payment info, shipping address.
            </p>
            <p>Place of processing: United States – Privacy Policy.</p>
            <h5>Google Pay (Google Ireland Limited)</h5>
            <p>
              Google Pay is a payment service offered by Google Ireland Limited,
              enabling users to conduct online transactions using their Google
              account credentials.
            </p>
            <p>
              Google Pay (Google Ireland Limited) collects and processes your
              billing address, email address, first name, last name, payment
              info, shipping address. Place of processing: Ireland – Privacy
              Policy.
            </p>
            <h4>Hosting and backend infrastructure</h4>
            <p>
              This service aims to host data and files necessary for the
              operation and distribution of this Application, while also
              providing an infrastructure to support specific features or
              components of this Application. Certain services listed below, if
              applicable, may operate through servers distributed across
              different geographical locations, making it challenging to
              pinpoint the exact storage location of personal data.
            </p>
            <h5>Google BigQuery (Google Ireland Limited)</h5>
            <p>
              Google BigQuery is a hosting and backend service provided by
              Google Ireland Limited. Google BigQuery (Google Ireland Limited)
              collects and processes various types of Personal Data as specified
              in the privacy policy of the service. Place of processing: Ireland
              – Privacy Policy.
            </p>
            <h5>Google Cloud Storage (Google Ireland Limited)</h5>
            <p>
              Google Cloud Storage is a hosting service provided by Google
              Ireland Limited.
            </p>
            <p>
              Google Cloud Storage (Google Ireland Limited) collects and
              processes various types of Personal Data as specified in the
              privacy policy of the service. Place of processing: Ireland –
              Privacy Policy.
            </p>
            <h5>Infrastructure monitoring</h5>
            <p>
              This service enables our Application to monitor the usage and
              behavior of its components to enhance performance, operation,
              maintenance, and troubleshooting. The processing of personal data
              varies based on the characteristics and implementation methods of
              these services, which function to analyze and filter the
              activities of our Application.
            </p>
            <h5>Firebase Performance Monitoring (Google LLC)</h5>
            <p>
              Firebase Performance Monitoring is a monitoring service provided
              by Google LLC. Firebase Performance Monitoring collects and
              processes various types of Personal Data as specified in the
              privacy policy of the service.
            </p>
            <h5>Sentry (Functional Software, Inc. )</h5>
            <p>
              Sentry is a monitoring service provided by Functional Software,
              Inc.
            </p>
            <p>
              Sentry collects and processes various types of Personal Data as
              specified in the privacy policy of the service.
            </p>
            <p>Place of processing: United States – Privacy Policy.</p>
            <h4>Location processing</h4>
            <h5> Radar (Radar Labs, inc)</h5>
            <p>
              {' '}
              Radar is a geolocation service provided by Radar Labs, Inc. Radar
              collects and processes location data (latitude, longitude), device
              IDs, IP addresses, and device info Place of processing; United
              States - https://radar.com/privacy`
            </p>
            <h5>Location-based interactions</h5>
            <h6>Geolocation (this Application)</h6>
            <p>
              This Application may collect, utilize, and share User location
              data to offer location-based services. Many browsers and devices
              offer default tools to opt out of this feature. If explicit
              authorization is granted, this Application may track the User's
              location data.
            </p>
            <p>Personal data processed: geographic position.</p>
            <h6>Managing contacts and sending messages</h6>
            <p>
              This type of service facilitates the management of a database
              containing email contacts, phone contacts, or any other contact
              information for communicating with the User. These services may
              also gather data regarding when the User viewed a message and
              their interactions with it, such as clicking on links within the
              message.
            </p>
            <h6>Firebase Notifications (Google LLC)</h6>
            <p>
              Firebase Notifications is a message sending service provided by
              Google LLC. Firebase Notifications can be integrated with Firebase
              Analytics to target analytics-based audiences and track opening
              and conversion events.
            </p>
            <p>
              Firebase Notifications collects and processes various types of
              Data as specified in the privacy policy of the service.
            </p>
            <p>Place of processing: United States – Privacy Policy.</p>
            <h6>Mailjet (SAS Mailjet)</h6>
            <p>
              Mailjet is an email address management and message sending service
              provided by SAS Mailjet.
            </p>
            <p>
              Mailjet collects and processes email address, first name, last
              name.
            </p>
            <p>Place of processing: France – Privacy Policy.</p>
            <h6>Firebase Cloud Messaging (Google Ireland Limited)</h6>
            <p>
              Firebase Cloud Messaging is a messaging service offered by Google
              Ireland Limited. It enables the Owner to send notifications and
              messages to Users across various platforms, including Android,
              iOS, and the web. Messages can be targeted to individual devices,
              groups of devices, or specific topics or segments of Users.
              Firebase Cloud Messaging collects and processes various types of
              Personal Data as specified in the privacy policy of the service.
            </p>
            <p>Place of processing: Ireland – Privacy Policy.</p>
            <h6>Managing support and contact requests</h6>
            <p>
              This service enables the Application to handle support and contact
              requests received via email or other communication channels, such
              as contact forms. The Personal Data processed is dependent on
              information provided by Users in their messages and the
              communication methods used (e.g., email address).
            </p>
            <h6>Zendesk (Zendesk, Inc.)</h6>
            <p>
              Zendesk is a support and contact request management service
              provided by Zendesk Inc. Zendesk collects and processes various
              types of Personal Data as specified in the privacy policy of the
              service.
            </p>
            <p>Place of processing: United States – Privacy Policy.</p>
            <h4>Platform services and hosting</h4>
            <p>
              These services support the hosting and operation of essential
              components of this Application, enabling its delivery from a
              unified platform. These platforms offer various tools to the
              Operator, such as analytics, user registration, commenting,
              database management, e-commerce, and payment processing, involving
              the collection and processing of Personal Data. Some of these
              services operate through servers distributed across different
              geographical locations, making it challenging to ascertain the
              precise storage location of Personal Data.
            </p>
            <h5>Apple App Store (Apple Inc.)</h5>
            <p>
              This Application is available on the App Store, a platform for
              distributing mobile applications provided by Apple Inc.
            </p>
            <p>
              As part of its distribution via this app store, Apple collects
              basic analytics and offers reporting features that allow the
              Operator to access usage analytics data and assess the performance
              of this Application. Much of this information is processed based
              on user consent.
            </p>
            <p>
              Users can opt-out of these analytics features directly through
              their device settings. Further details on managing analytics
              settings can be found on this page.
            </p>
            <p>Personal Data processed: Usage Data.</p>
            <p>Place of processing: United States – Privacy Policy.</p>
            <h5>Google Play Store (Google Ireland Limited)</h5>
            <p>
              This Application is available on the Google Play Store, a platform
              for distributing mobile applications provided by Google Ireland
              Limited. As part of its distribution through this app store,
              Google collects usage and diagnostics data and shares aggregated
              information with the Operator. Much of this information is
              processed based on user consent. Users can opt-out of these
              analytics features directly through their device settings. Further
              details on managing analytics settings can be found on this page.
            </p>
            <p>Personal Data processed: Usage Data.</p>
            <p>Place of processing: Ireland – Privacy Policy.</p>
            <h4>Registration and authentication</h4>
            <p>
              By registering or authenticating, Users enable this Application to
              identify them and grant access to specific services. Depending on
              the specifics outlined below, third-party services may facilitate
              registration and authentication. In such cases, this Application
              may access certain Data stored by these third-party services for
              registration or identification purposes. Some of the services
              listed below may also gather Personal Data for targeting and
              profiling purposes. For further details, please consult the
              description of each respective service.
            </p>
            <h4>Firebase Authentication (Google LLC)</h4>
            <p>
              Firebase Authentication is a registration and authentication
              service provided by a third-party service provider. To streamline
              the registration and authentication process, this service may
              utilize third-party identity providers and store relevant
              information securely. Firebase Authentication collects and
              processes email address, first name, last name, password, phone
              number, social media accounts.
            </p>
            <p>Place of processing: United States – Privacy Policy.</p>
            <h4>Facebook Authentication (Meta Platforms Ireland Limited)</h4>
            <p>
              Facebook Authentication is an authentication and registration
              service offered by Meta Platforms Ireland Limited, linked to the
              Facebook social network.
            </p>
            <p>
              Facebook Authentication collects and processes Personal Data such
              as Tracker, various types of Data as specified in the privacy
              policy of the service.
            </p>
            <p>Place of processing: Ireland – Privacy Policy.</p>
            <h4>Social features</h4>
            <h5>Firebase Dynamic Links (Google LLC)</h5>
            <p>
              Firebase Dynamic Links is a social feature offered by Google LLC.
              These links are tracked through Firebase or Google Analytics for
              Firebase, providing the Owner with insights into how Users
              navigate to and within this Application.
            </p>
            <p>
              Firebase Dynamic Links collects and processes such as various
              types of Data as specified in the privacy policy of the service.
            </p>
            <p>Place of processing: United States – Privacy Policy.</p>
            <h5>User database management</h5>
            <p>
              This type of service enables the Owner to create user profiles
              using information such as email addresses, personal names, or
              other data provided by the User to this Application. It also
              allows tracking of User activities through analytics features.
              This Personal Data may be combined with publicly available
              information about the User (such as social media profiles) to
              develop private profiles used by the Owner to enhance this
              Application. Additionally, some of these services facilitate
              sending timed messages to the User, such as emails triggered by
              specific actions on this Application.
            </p>
            <h5>Intercom (Intercom R&amp;D Unlimited Company)</h5>
            <p>
              Intercom is a User database management service offered by Intercom
              R&amp;D Unlimited Company. It can also serve as a communication
              medium via email or messages within this Application. Intercom
              Messenger may utilize Trackers to identify and monitor User
              behavior.
            </p>
            <p>
              Intercom collects and processes Personal Data such as Data
              communicated while using the service; email address; Tracker;
              Universally unique identifier (UUID); Usage Data; various types of
              Data as specified in the privacy policy of the service.
            </p>
            <p>Place of processing: Ireland – Privacy Policy.</p>
            <h5>Information on opting out of interest-based advertising</h5>
            <p>
              In addition to any opt-out options provided by the services listed
              in this document, Users can follow the guidance provided by
              YourOnlineChoices (EU), the Network Advertising Initiative (US),
              the Digital Advertising Alliance (US), DAAC (Canada), DDAI
              (Japan), or similar initiatives. These initiatives enable Users to
              manage their preferences for most advertising tools. The Owner
              encourages Users to utilize these resources alongside the
              information provided in this document. The Digital Advertising
              Alliance offers an application called AppChoices, which assists
              Users in controlling interest-based advertising on mobile apps.
              Users may also opt out of specific advertising features through
              relevant device settings, such as mobile phone device advertising
              settings or general ad settings.
            </p>
            <h4>Further information about the processing of Personal Data</h4>
            <h5>Push notifications</h5>
            <p>
              This Application may send notifications to Users to fulfill the
              purposes described in this privacy policy. Users can generally
              choose to opt out of receiving notifications by adjusting their
              device settings, such as notification settings for mobile phones,
              and modifying these settings for this Application, specific apps,
              or all apps on their device. Users should understand that
              disabling notifications may impact the functionality of this
              Application.
            </p>
            <h5>Push notifications based on the User's geographic location</h5>
            <p>
              This Application may utilize the User's location information to
              send notifications for the purposes outlined in this privacy
              policy. Users can generally opt out of receiving notifications by
              adjusting their device settings, such as notification preferences
              on mobile phones, and modifying these settings for specific apps
              or all apps on their device. Users should be aware that turning
              off notifications may impact the functionality and usefulness of
              this Application.
            </p>
            <h5>Selling goods and services online</h5>
            <p>
              The Personal Data collected is used to provide users with services
              or products, including processing payments and arranging delivery.
              Data required for payment processing may include credit card
              details, bank account information used for transfers, or other
              payment methods supported by this application.
            </p>
            <h4>Users rights</h4>
            <p>
              Users have specific rights regarding their Personal Data processed
              by the Owner:
            </p>
            <ul>
              <li>
                Withdraw Consent: Users can withdraw consent at any time if they
                previously agreed to the processing of their Personal Data.
              </li>
              <li>
                Object to Processing: Users can object to the processing of
                their Data if it is done on a legal basis other than consent.
                Details on how to object are provided in the relevant section
                below.
              </li>
              <li>
                Access Data: Users can inquire whether their Data is being
                processed by the Owner, gain insight into certain aspects of the
                processing, and request a copy of their Data.
              </li>
              <li>
                Verify and Rectify:Users can verify the accuracy of their Data
                and request updates or corrections.
              </li>
              <li>
                Restrict Processing: Under specific circumstances, Users can
                request that the processing of their Data be restricted. In such
                cases, the Owner will only store the Data and not process it
                further.
              </li>
              <li>
                Delete Data: Under certain conditions, Users can request the
                erasure of their Data from the Owner.
              </li>
              <li>
                Data Portability: Users can request their Data in a structured,
                commonly used, and machine-readable format and have it
                transferred to another controller, if technically feasible. This
                applies if the Data is processed by automated means and based on
                the User's consent, a contract the User is part of, or
                pre-contractual obligations.
              </li>
              <li>
                Lodge a Complaint: Users have the right to file a complaint with
                their local data protection authority.
              </li>
            </ul>
            <h4>Right to object to processing</h4>
            <p>
              Users have the right to object to the processing of their Personal
              Data if it is being processed for a task carried out in the public
              interest, in the exercise of official authority vested in the
              Owner, or for the Owner’s legitimate interests. Users should
              provide a reason based on their particular situation to support
              their objection. However, if Personal Data is being processed for
              direct marketing purposes, Users can object at any time without
              needing to provide any reason. To determine if Personal Data is
              used for direct marketing, Users can refer to the relevant
              sections of this document.
            </p>
            <h4>Exercising these rights</h4>
            <p>
              Users can direct any requests to exercise their rights to the
              Owner via the contact information provided in this document. These
              requests are free of charge and will be addressed by the Owner
              promptly, typically within one month.
            </p>
            <h4>Additional information about Data collection and processing</h4>
            <h5>Legal action</h5>
            <p>
              The Owner may use the User's Personal Data for legal purposes,
              including in court or in preparation for possible legal actions
              resulting from misuse of this Application or its related services.
              The User acknowledges that the Owner may be obligated to disclose
              personal data upon request by public authorities.
            </p>
            <h5>
              Additional information regarding User&amp;apos;s Personal Data
            </h5>
            <p>
              Beyond the details provided in this privacy policy, our services
              may offer Users further contextual information regarding specific
              services or the collection and processing of Personal Data, upon
              request.
            </p>
            <h5>System logs and maintenance</h5>
            <p>
              For operational and maintenance purposes, our company and
              third-party services may collect files that record interactions
              with our services (System logs). These logs may include Personal
              Data such as IP addresses, solely for the purpose of ensuring the
              functionality and security of our services.
            </p>
            <h5>Additional Information</h5>
            <p>
              For additional details regarding the collection or processing of
              Personal Data, please feel free to contact us using the
              information provided at the beginning of this document.
            </p>
            <h5>Handling of "Do Not Track" Requests</h5>
            <p>
              Our company does not currently support "Do Not Track" requests.
              Please refer to the privacy policies of any third-party services
              we use to determine if they honor "Do Not Track" requests.
            </p>
            <h5>Changes to this privacy policy</h5>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page
              and updating the "effective date" at the top of this Privacy
              Policy. You are advised to review this Privacy Policy periodically
              for any changes. Changes to this Privacy Policy are effective when
              they are posted on this page.
            </p>
            <h4>Definitions and legal references</h4>
            <h5>Personal Data (or Data)</h5>
            <p>
              Personal Data (or Data) refers to any information that, either
              alone or in combination with other data, enables the
              identification of a natural person.
            </p>
            <h5>Usage Data</h5>
            <p>
              Usage Data consists of information collected automatically through
              our Application (or third-party services integrated into our
              Application), which may include: IP addresses or domain names of
              the devices used by Users who interact with our Application, URI
              addresses (Uniform Resource Identifier), time of the request,
              method used to submit the request to the server, size of the file
              obtained in response, numerical code indicating the status of the
              server's response (successful outcome, error, etc.), country of
              origin, browser and operating system features utilized by the
              User, time spent on each page within the Application, sequence of
              pages visited, and other parameters related to the User's device
              operating system and IT environment.
            </p>
            <h5>User</h5>
            <p>
              The individual using our Application, who, unless otherwise
              specified, is also the Data Subject.
            </p>
            <h5>Data Subject</h5>
            <p>The individual to whom the Personal Data pertains.</p>
            <h5>Data Processor (or Data Supervisor)</h5>
            <p>
              The Data Processor is the individual or entity, whether natural or
              legal person, public authority, agency, or other body, that
              processes Personal Data on behalf of the Data Controller, as
              outlined in this privacy policy.
            </p>
            <h5>Data Controller (or Owner)</h5>
            <p>
              The Data Controller is the individual or entity, whether natural
              or legal person, public authority, agency, or other body, who
              independently or jointly with others, determines the purposes and
              methods of processing Personal Data, including the security
              measures related to the operation and use of our Application.
              Unless explicitly stated otherwise, the Data Controller is the
              owner of this Application.
            </p>
            <h5>This Application</h5>
            <p>
              The methods by which we collect and process the Personal Data of
              our users.
            </p>
            <h5>Service</h5>
            <p>
              The service provided by our Application is on this
              site/application and detailed in the relevant terms (if
              available).
            </p>
            <h5>European Union (or EU)</h5>
            <p>
              Unless specified otherwise, all references in this document to the
              geographic scope include all relevant member states of the
              European Union and the European Economic Area (EEA).
            </p>
            <h5>Cookie</h5>
            <p>
              Cookies are small sets of data stored in the User's browser that
              track.
            </p>
            <h5>Tracker</h5>
            <p>
              Tracker refers to any technology, such as cookies, unique
              identifiers, web beacons, embedded scripts, e-tags, and
              fingerprinting. These technologies enable the tracking of Users,
              including accessing or storing information on the User’s device.
            </p>
            <h5>Legal Information</h5>
            <p>
              This privacy statement has been crafted based on various legal
              provisions, including Article 13/14 of Regulation (EU) 2016/679
              (General Data Protection Regulation). This privacy policy pertains
              specifically to our Application, unless stated otherwise within
              this document.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
