import React from 'react';
import {PiArrowCounterClockwise, PiTreeStructure} from 'react-icons/pi';
import {noneRotateLeft, reviveStationImg} from '../../assets/images/index';
import {SlArrowLeft} from 'react-icons/sl';
interface StationDetails {
  state: any;
  setShowStationDetails:any
}

const StationDetails: React.FC<StationDetails> = ({state, setShowStationDetails}) => {
  return (
    <div className="min-h-full">
      <div className="xl:pt-[100px] sm:pt-[64px] pt-6 xl:pb-6 sm:pb-[130px] pb-[165px]">
        <div className="container">
          <div className="grid grid-cols-12 xl:gap-x-10 gap-y-[30px] xl:border-0 border border-[#D6D6D6] rounded-[20px] mb-8 xl:mb-0">
            <div className="xl:col-span-6 col-span-12 relative">
              <div className="flex align-middle gap-4 absolute top-4 xl:top-5 left-4 xl:left-5 right-4 xl:right-5">
                <div
                onClick={() => setShowStationDetails()}
                  className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                  <SlArrowLeft />
                </div>
                <span className="ml-auto font-semibold leading-7 py-[10px] px-4 bg-amber-600 text-gray-950 rounded-[12px]">
                  <span className="text-2xl">
                    {state?.currencyName}
                    {+state?.pJifei}
                  </span>{' '}
                  / {state?.pJifeiDanwei} minutes
                </span>
              </div>
              <img
                className="w-full rounded-[20px] xl:object-left-bottom object-center object-cover xl:h-[550px] 2xl:h-[682px] h-[230px]"
                src={
                  state?.shoplogo !== '' && state?.shoplogo
                    ? state?.shoplogo
                    : reviveStationImg
                }
                alt="ReviveStation Img"
              />
            </div>

            <div className="xl:col-span-6 col-span-12 flex flex-col xl:px-0 px-4 xl:pt-[30px]">
              {state.shopName && <h1 className="text-2xl">{state.shopName}</h1>}
              {state?.shopAddress && (
                <p className="mb-6">{state?.shopAddress}</p>
              )}
              {state?.shopContent && (
                <p className="mb-5">{state?.shopContent}</p>
              )}
              <p className="mb-5 flex gap-[10px] items-center">
                Free of charge for the first {state?.freeDuration} mins. A
                maximum daily charge of {state?.currencyName}
                {+state?.pFengding}. Deposit will be automatically deducted when
                rent reaches {state?.currencyName}
                {+state?.pYajin}
              </p>
              <p className="mb-5 flex gap-[10px] items-center">
                <PiTreeStructure size={18} className="flex-none" /> Adapted to
                all types of mobile phone{' '}
              </p>
              <p className="mb-5 flex gap-[10px] items-center">
                {' '}
                <PiArrowCounterClockwise
                  size={18}
                  className="rotate-[40deg] flex-none"
                />
                Can be returned to any other site
              </p>
              <p className="mb-5 flex gap-[10px] items-start">
                <img
                  className="mt-1 flex-none"
                  src={noneRotateLeft}
                  alt="Arrow Rotate Left Img"
                />{' '}
                Non-return fee: in case you do not return the power bank within{' '}
                {state?.pOvertimeDay || 2} days we will direct debit{' '}
                {state?.currencyName}
                {+state?.pyajin},- from your bank account
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StationDetails;
