import {
  cafeNar,
  gerrie,
  jeuDeBar,
  kapSalonBroers,
  leSailFish,
  reviveMarkerIcon,
  theTeamBuilding,
  uvsvClub,
} from '../assets/images';
import {
  PiCaretRight,
  PiClipboardText,
  PiCrosshair,
  PiGear,
  PiHouse,
  PiList,
  PiMagnifyingGlass,
  PiPower,
  PiQuestion,
  PiQuestionMark,
  PiSignOut,
  PiWallet,
  PiX,
} from 'react-icons/pi';

export const powerBankCoordinatesList = [
  {
    lat: 44.6455755192,
    lng: -1.25011620165,
    markersData: [
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 44.640912047742326,
        lng: -1.2555212737429564,
        place: 'Le Sail Fish',
        address: 'Rue des Bernaches, 33970 Lège-Cap-Ferret, France',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: leSailFish,
        loanable: 0,
        returnable: 0,
        distance: 19,
        distance_unit: 'm',
        open: false,
        closed_until: 'Fri 20:00',
        timing: '9:00 - 18:00',
        station_status: false,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 44.65023899057431,
        lng: -1.2447111295666908,
        place: 'Sail Fish Café',
        address: '70 Bd de la Plage, 33970 Lège-Cap-Ferret, France',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: leSailFish,
        loanable: 0,
        returnable: 0,
        distance: 102,
        distance_unit: 'm',
        open: false,
        closed_until: '23:00',
        timing: '9:00 - 18:00',
        station_status: false,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
    ],
  },
  {
    lat: 52.090904518278,
    lng: 5.1076525142674,
    markersData: [
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 52.08302326790824,
        lng: 5.098133962184226,
        place:
          'The Team Building - Fusion Drift, Escape rooms en Real Axe Throwing',
        address: 'Admiraal Helfrichlaan 6, 3527 KV Utrecht, Netherlands',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: theTeamBuilding,
        loanable: 4,
        returnable: 4,
        distance: 2,
        distance_unit: 'km',
        open: false,
        closed_until: '10:00',
        timing: 'ma-vr: 15-22:30u, za: 10-23u zo: 10-20u',
        station_status: true,
        price: 3,
        currency: '€',
        time_duration_in_mins: 60,
      },
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 52.09098943702563,
        lng: 5.097689176950571,
        place: 'Kapsalon Broers',
        address: 'Kanaalstraat 196, 3531 CP Utrecht, Netherlands',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: kapSalonBroers,
        loanable: 5,
        returnable: 3,
        distance: 2,
        distance_unit: 'km',
        open: false,
        closed_until: '23:00',
        timing: 'di, wo, vr: 10-18u, do: 10:20u, za: 10-17u',
        station_status: true,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 52.09521639758501,
        lng: 5.111201613077561,
        place: 'JEU de boules bar',
        address: 'Paardenveld 1, 3511 RD Utrecht, Netherlands',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: jeuDeBar,
        loanable: 7,
        returnable: 1,
        distance: 2,
        distance_unit: 'km',
        open: false,
        closed_until: '23:00',
        timing: 'W/T: 16-23h, F/Sat: 15-1h, Sun: 13-22h',
        station_status: true,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 52.09356378554106,
        lng: 5.118899982389016,
        place: 'StayOkay Neude',
        address: 'Neude 5, Utrecht, Netherlands',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: uvsvClub,
        loanable: 0,
        returnable: 0,
        distance: 4,
        distance_unit: 'km',
        open: false,
        closed_until: '23:00',
        timing: 'ma-vr: 9-23u, za-zo: 9-1u',
        station_status: false,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 52.08834771018603,
        lng: 5.10751785540515,
        place: 'Jaarbeurs - Majesty Padel',
        address: 'Hal 12, Jaarbeursplein 1, Utrecht',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: uvsvClub,
        loanable: 4,
        returnable: 4,
        distance: 3,
        distance_unit: 'km',
        open: false,
        closed_until: '23:00',
        timing: '10:00 - 23:00 (elke dag)',
        station_status: true,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 52.094221026215195,
        lng: 5.123584482389078,
        place: 'UVSV Club - Introduction Week 2024',
        address: 'Drift 21k207, 3512 BR Utrecht, Netherlands',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: uvsvClub,
        loanable: 24,
        returnable: 0,
        distance: 2,
        distance_unit: 'km',
        open: false,
        closed_until: '23:00',
        timing: '21:00-04:00',
        station_status: true,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 52.101372140930806,
        lng: 5.083481757258223,
        place: 'Cafe Nar',
        address: 'Nijverheidsweg 6, 3534 AM Utrecht, Netherlands',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: cafeNar,
        loanable: 0,
        returnable: 0,
        distance: 12,
        distance_unit: 'km',
        open: false,
        closed_until: '23:00',
        timing: 'wo: 16u-01u, do-za: 12-laat, zo: 12-22u',
        station_status: false,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
    ],
  },
  {
    lat: 22.705972192569,
    lng: 113.78948513566,
    markersData: [
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 22.705625,
        lng: 113.790084,
        place: 'Baoan',
        address: 'Shenzhen, Guangdong Province, China',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: leSailFish,
        loanable: 3,
        returnable: 3,
        distance: 505,
        distance_unit: 'm',
        open: false,
        closed_until: '10:00',
        timing: '9:00 - 18:00',
        station_status: true,
        price: 3,
        currency: '€',
        time_duration_in_mins: 60,
      },
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 22.706319385137235,
        lng: 113.78888627131823,
        place: 'Shenzhen Tianpengsheng',
        address:
          'PQ4Q+GG5, Shafu Rd, Baoan, Shenzhen, Guangdong Province, China',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: leSailFish,
        loanable: 0,
        returnable: 0,
        distance: 642,
        distance_unit: 'm',
        open: false,
        closed_until: '23:00',
        timing: '9:00 - 22:00',
        station_status: false,
        price: 2,
        currency: '€',
        time_duration_in_mins: 60,
      },
    ],
  },
  {
    lat: 51.68660553498131,
    lng: 5.300263228696419,
    markersData: [
      {
        type: 'restaurant',
        type_icon: 'restaurant',
        lat: 51.68660553498131,
        lng: 5.300263228696419,
        place: "Gerrie's Party!",
        address: 'Kruisbroedershof 11, Den Bosch',
        online_image: reviveMarkerIcon,
        offline_image: reviveMarkerIcon,
        image: gerrie,
        loanable: 0,
        returnable: 0,
        distance: 72,
        distance_unit: 'km',
        open: false,
        closed_until: '10:00',
        timing: '24/7',
        station_status: false,
        price: 3,
        currency: '€',
        time_duration_in_mins: 60,
      },
    ],
  },
];

export const sidebarLinks = (Login_Token: any) => {
  return [
    {
      id: '1',
      navigateTo: Login_Token ? '#' : '/login',
      icon: PiHouse,
      label: 'Home',
    },
    // {
    //   id: '2',
    //   navigateTo: Login_Token ? '/my-wallet' : '/login',
    //   icon: PiWallet,
    //   label: 'My Wallet',
    // },
    {
      id: '3',
      navigateTo: Login_Token ? '/order-list' : '/login',
      icon: PiClipboardText,
      label: 'Order',
    },
    {
      id: '4',
      navigateTo: Login_Token ? '/settings' : '/login',
      icon: PiGear,
      label: 'Settings',
    },
    {
      id: '5',
      navigateTo: Login_Token ? '/help' : '/login',
      icon: PiQuestion,
      label: 'Help',
    },
  ];
};
