export const QRCODESCAN = '/cdb/cabinet/checkisok';
export const GETCAPTCHAS = '/captcha.webp';
export const VRIFYOTP = '/saas/sendVerifyCodeSms';
export const MOBILELOGIN = '/saas/mobileLogin';
export const EMAILOTPSEND = '/saas/sendVerifyCodeMail';
export const EMAILLOGIN = '/saas/emailLogin';
export const GOOGLELOGIN = '/saas/loginGoogleByCode';
export const STRIPEMULTICURANCY =
  '/cdb/payment/stripe/multicurrency/preauthOfIntent/payment';
export const PAYMENTCONFIRM = '/cdb/payment/stripe/multicurrency/confirm';
export const USEBALANCETORENT = '/cdb/cabinet/useBalanceToRent';
export const GOOGLEMAPLISTNEARAPI = '/cdb/shop/listnear';
export const PAYMENTMODE = '/cdb/cabinet/check/payment';
export const RECHARGEWALLET =
  '/cdb/payment/stripe/multicurrency/rechargeWallet';
export const ORDERCHECK = '/cdb/cabinet/ordercheck';
export const USERINFO = '/userInfo';
export const ORDERLIST = '/cdb/mine/order/list';
export const LOGIN_FACEBOOK = '/saas/loginFacebookByToken';
