/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {acceptTermsCondition} from '../../assets/images';

const AcceptTermCondition: React.FC = () => {
  return (
    <div className="min-h-full flex flex-col justify-center items-center lg:py-10 pt-6 pb-[114px]">
      <div className="container">
        <div className="text-center">
          <img
            className="mb-7 max-w-[80%] sm:max-w-[521px] w-full mx-auto"
            alt="payment-success"
            src={acceptTermsCondition}
            width={100}
            height={100}
          />
          <p className="mb-10 max-w-[330px] mx-auto">
            To continue, please review and accept our{' '}
            <Link to="/privacy-policy" className="font-semibold underline">
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link to="/terms-and-condition" className="font-semibold underline">
              Terms and Conditions
            </Link>
          </p>
        </div>
      </div>
      <div className="py-7 lg:py-0 lg:relative fixed left-0 bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
        <div className="container">
          <div className="lg:max-w-[362px] mx-auto w-full flex flex-col gap-4 items-center">
            <button className="btn-primary flex items-center justify-center gap-2 px-4">
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptTermCondition;
