import React from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft, SlArrowRight} from 'react-icons/sl';
import {userProfile} from '../../assets/images';
import {PiSignOut} from 'react-icons/pi';

const Settings: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-6">
              <Link
                to="/"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <h4 className="text-2xl">Settings</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-3 pb-6 border-b border-gray-medium mb-6">
              <div className="profile-ring w-[76px] h-[76px] flex-none">
                <img
                  className="profile-img w-[68px] h-[68px] rounded-full bg-amber-600"
                  src={userProfile}
                  width={70}
                  height={70}
                  alt="user-profile"
                />
              </div>
              <div className="flex-1">
                <h5 className="text-xl">Sarah Saman</h5>
                <p>1 565 432 9182</p>
                <p>sarahsa@gmail.com</p>
              </div>
              <div className="flex-none">
                <Link to="/settings/user-account">
                  <SlArrowRight />
                </Link>
              </div>
            </div>
            <Link to="#" className="flex items-center gap-5 text-red-600">
              <PiSignOut size={22} className="rotate-180 flex-none" />
              <span>Log Out</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
