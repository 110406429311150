import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/high-res.css';

const UpdatePhoneNo: React.FC = () => {
  const [phone, setPhone] = useState('');
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-6">
              <Link
                to="/settings/user-account"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <h4 className="text-2xl">User Account</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="mb-[30px]">
              <h5 className="text-xl mb-3">Phone Number</h5>
              <p>
                You’ll use the number to get notifications, Sign in, and recover
                your account.
              </p>
            </div>
            <div className="react-phone-input-custom mb-1">
              <PhoneInput
                containerStyle={{
                  fontFamily: `"Montserrat", sans-serif`,
                }}
                enableSearch={true}
                country={'nl'}
                value={phone}
                onChange={setPhone}
              />
            </div>
            <p className="mb-7 text-sm">
              A verification code will be sent to this number
            </p>
          </div>
        </div>
        <div className="py-7 lg:py-0 lg:relative fixed left-0 bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
          <div className="container">
            <div className="max-w-[900px] mx-auto w-full">
              <div className="lg:max-w-[362px]">
                <button className="btn-primary flex items-center justify-center gap-3 px-4">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePhoneNo;
