import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import OTPInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import 'react-phone-input-2/lib/style.css';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import {
  apple,
  email,
  facebook,
  google,
  loginBanner,
  mobile,
  refreshIcon,
} from '../../assets/images/index';

import {
  AUTH_VERIFICATION,
  SOCIAL_LOGIN,
  SOCIAL_LOGIN_APPLE,
  SOCIAL_LOGIN_FACEBOOK,
  USERINFO,
  WITHOUT_SOCIAL_LOGIN,
  WITHOUT_SOCIAL_LOGIN_EMAIL,
} from '../../services/apiStaticData';
import { fetchData } from '../..//services/Api';

const generateCaptchaText = () => {
  const chars = '1234567890';
  let text = '';
  for (let i = 0; i < 4; i++) {
    text += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return text;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('31');
  const [isEmail, setIsEmail] = useState(false);
  const [captchaText, setCaptchaText] = useState(generateCaptchaText());
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [otpStep, setOtpStep] = useState(false);
  const [otp, setOtp] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {control, handleSubmit, register, reset, watch, setValue} = useForm({
    mode: 'all',
  });

  const handlePhoneChange = (value: string, country: any, e: any) => {
    const countryCode = country?.dialCode || '31';
    let phoneWithoutCountryCode = value.replace(`+${countryCode}`, '').trim();
    const strippedPhoneNumber = value.startsWith(country?.dialCode)
      ? value.slice(country.dialCode.length)
      : value;
    setPhone(phoneWithoutCountryCode);
    setCountryCode(countryCode);
    setValue('phone', strippedPhoneNumber);
  };

  ///// new captcha /////
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = '30px Arial';
    ctx.fillStyle = 'black';
    ctx.fillText(captchaText, 10, 30);
  }, [captchaText, isEmail]);

  const refreshCaptcha = () => {
    setCaptchaText(generateCaptchaText());
    setUserInput('');
  };

  const onSubmit = async (data: {
    email?: string;
    phone?: string;
    code?: string;
  }) => {
    if (isEmail && data.email) {
      try {
        const url = `${
          process.env.REACT_APP_API_HOST_SOCIAL_LOGIN
        }${WITHOUT_SOCIAL_LOGIN_EMAIL}?id=${watch('email')}`;
        const response = await axios.get(url);
        if (response) {
          setOtpStep(true);
        } else {
          Swal.fire({
            icon: 'error',
            text: 'error',
          });
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          text: error,
        });
      }
    } else if (userInput) {
      setPhone(data.phone || '');
      if (userInput !== captchaText) {
        Swal.fire({
          icon: 'error',
          text: 'Incorrect captcha, please try again.',
        }).then(() => {
          setOtpStep(false);
          setPhone('+31');
          setUserInput('');
          setCountryCode('31');
        });
        return;
      }

      try {
        const params = new URLSearchParams();
        params.append('id', phone);
        const url = `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${WITHOUT_SOCIAL_LOGIN}?id=${phone}`;
        const response = await axios.get(url);
        if (response) {
          setOtpStep(true);
        } else {
          Swal.fire({
            icon: 'error',
            text: 'error',
          });
        }
      } catch (error) {
        console.error('Error calling the API:', error);
        reset();
        setCaptchaText(generateCaptchaText());
        setUserInput('');
        setCountryCode('31');
        setPhone('');
      }
    }
  };

  const handleChange = (otpValue: string) => {
    setOtp(otpValue);
  };

  const handleOtpSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const verifyCode = Number(otp);
    try {
      setIsSubmitting(true);
      if (isEmail) {
        // New API calling for EMAIl ////
        const payload = {
          sourceId: String(watch('email')),
          otp: String(verifyCode),
        };
        const emailLoginUrl = `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${AUTH_VERIFICATION}`;
        const response = await axios.post(emailLoginUrl, payload);
        if (response) {
          localStorage.setItem('reviveToken', response?.data?.data?.token);
          localStorage.setItem(
            'userInfo',
            JSON.stringify(response?.data?.data?.user),
          );
          if (!location?.state?.state) {
            navigate('/');
          } else {
            navigate('/checkout', {state: location?.state?.data});
          }
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Error in email OTP submission',
          });
        }
      } else {
        ///// NEW API CALLING ////
        const payload = {
          sourceId: String(Number(countryCode + watch().phone)),
          otp: String(verifyCode),
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${AUTH_VERIFICATION}`,
          payload,
        );
        if (response) {
          localStorage.setItem('reviveToken', response?.data?.data?.token);
          localStorage.setItem(
            'userInfo',
            JSON.stringify(response?.data?.data?.user),
          );
          if (!location?.state?.state) {
            navigate('/');
          } else {
            navigate('/checkout', {state: location?.state?.data});
          }
        } else {
          Swal.fire({
            icon: 'error',
            text: 'Not Found',
          });
        }
      }
    } catch (error: any) {
      if (error.response) {
        const errorMessage =
          error.response.data.error.message || 'An error occurred.';
        Swal.fire({
          icon: 'error',
          text: errorMessage,
        }).then(() => {
          setOtpStep(false);
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Network error or server is down.',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  /////  google login new start /////
  const handleGoogleLoginNew = async () => {
    try {
      const redirectUrl = `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${SOCIAL_LOGIN}`;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Error during login:', error);
      Swal.fire({
        icon: 'error',
        text: 'Failed to start Google login. Please try again.',
      });
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const failure = urlParams.get('error');
    if (token) {
      ////// Decode token for get userInfo ////
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(window.atob(base64));
      localStorage.setItem('reviveToken', token);
      userInfo();
      const stationId = localStorage.getItem('cabinetID');
      if (!stationId) {
        navigate('/');
      } else {
        navigate('/checkout', {state: location?.state?.data});
      }
    } else {
      if (failure) {
        Swal.fire({
          icon: 'error',
          text: decodeURIComponent(failure),
        }).then(() => {
          navigate('/login');
        });
      }
    }
  }, [navigate]);

  const userInfo = async () => {
    try {
      const response = await fetchData(
        `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${USERINFO}`,
      );

      if (response?.statusCode === 200) {
        localStorage.setItem('userInfo', JSON.stringify(response?.data));
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.msg,
        }).then(() => navigate('/'));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while getting user info.',
      }).then(() => navigate('/'));
    }
  };

  ////// End New Google Login /////

  ////// On Apple Login New Start ////
  const onAppleLogin = () => {
    try {
      const redirectUrl = `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${SOCIAL_LOGIN_APPLE}`;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Error during login:', error);
      Swal.fire({
        icon: 'error',
        text: 'Failed to start Apple login. Please try again.',
      });
    }
  };
  //// Facebook login new start /////
  const onFacebookLogin = () => {
    try {
      const redirectUrl = `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${SOCIAL_LOGIN_FACEBOOK}`;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Error during login:', error);
      Swal.fire({
        icon: 'error',
        text: 'Failed to start Facebook login. Please try again.',
      });
    }
  };

  return (
    <>
      <div className="py-6 min-h-full flex flex-col items-center md:justify-center">
        {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} /> */}
        <div className="container">
          <div className="max-w-[484px] mx-auto">
            <div>
              <img
                className="mb-6 w-full"
                alt="login-banner"
                src={loginBanner}
              />
              {!otpStep && (
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  {isEmail ? (
                    <>
                      <label className="block mb-4 text-xl">
                        Enter Your Email
                      </label>
                      <div className="react-phone-input-custom mb-6">
                        <input
                          className="form-input"
                          {...register('email')}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="test@mail.com"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <label className="block mb-4 text-xl">
                        Enter your mobile number
                      </label>
                      <div className="react-phone-input-custom mb-6">
                        <Controller
                          name="phone"
                          control={control}
                          render={({field}) => (
                            <PhoneInput
                              {...field}
                              value={phone}
                              // onChange={(e)=>handlePhoneChange(e)}
                              onChange={(
                                value,
                                countryObj,
                                e,
                                formattedValue,
                              ) => handlePhoneChange(value, countryObj, e)}
                              containerStyle={{
                                fontFamily: `"Montserrat", sans-serif`,
                              }}
                              enableSearch={true}
                              country={'nl'}
                            />
                          )}
                        />
                      </div>
                      <label className="block mb-4 text-xl">Code</label>
                      <div className="relative">
                        <canvas
                          ref={canvasRef}
                          width="100"
                          height="30"
                          className="captcha-image w-[100px] h-auto border object-contain border-none absolute -end-4 -top-1 h-full rounded-e-xl"
                        />
                        <button
                          onClick={refreshCaptcha}
                          className="refresh-button w-[25px] h-[25px] border border-none absolute end-20 top-4 rounded-e-xl">
                          <img src={refreshIcon} alt="" />
                        </button>
                        <div className="react-phone-input-custom mb-6">
                          <input
                            className="form-input pe-[108px]"
                            type="text"
                            value={userInput}
                            onChange={e => {
                              const value = e.target.value.replace(/\D/g, '');
                              if (value.length <= 4) {
                                setUserInput(value);
                              }
                            }}
                            placeholder="Enter captcha"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <button className="btn-primary px-8 mb-6" type="submit">
                    Continue
                  </button>

                  <div className="text-gray-300 mb-6 relative block w-full text-center before:block before:relative before:w-full before:top-[14px] before:z-0 before:bg-gray-300 before:h-[1px] px-12">
                    <span className="px-5 relative bg-white">or</span>
                  </div>
                  <div className="google-login-wrapper relative">
                    <button
                      className="google-login-fake-btn btn-secondary px-4 mb-6 flex gap-4 justify-center"
                      onClick={() => {
                        handleGoogleLoginNew();
                      }}>
                      <img alt="google-icon" src={google} />
                      Continue with Google
                    </button>
                  </div>
                  {/* <button
                    className="btn-secondary px-4 mb-6 flex gap-4 justify-center"
                    onClick={onFacebookLogin}>
                    <img alt="facebook-icon" src={facebook} />
                    Continue with Facebook
                  </button> */}
                  <button
                    className="btn-secondary px-4 mb-6 flex gap-4 justify-center"
                    onClick={onAppleLogin}>
                    <img alt="Apple Icon" src={apple} />
                    Continue with Apple
                  </button>
                  <button
                    className="btn-secondary px-4 mb-6 flex gap-4 justify-center"
                    onClick={() => {
                      setIsEmail(!isEmail);
                    }}>
                    <img alt="Envelope Icon" src={isEmail ? mobile : email} />
                    {isEmail ? 'Continue with Mobile' : 'Continue with Email'}
                  </button>
                </form>
              )}

              {/* OTP input fields are shown when otpStep is true */}
              {otpStep && (
                <div className="otp-screen">
                  <h3 className="text-xl mb-4">
                    {`Enter the OTP sent to your ${
                      isEmail ? 'email' : 'mobile'
                    }.`}
                  </h3>
                  <form onSubmit={handleOtpSubmit}>
                    <div className="otp-input flex items-center gap-6 mb-6">
                      <OTPInput
                        value={otp}
                        onChange={(e: any) => handleChange(e)}
                        numInputs={6}
                        shouldAutoFocus
                        inputStyle={{
                          width: '53px',
                          height: '53px',
                          textAlign: 'center',
                          borderRadius: '4px',
                          border: '1px solid #ccc',
                        }}
                        containerStyle={{display: 'flex', gap: '10px'}}
                        renderInput={props => <input {...props} />}
                      />
                    </div>

                    <button
                      className="btn-primary px-8 mb-6"
                      type="submit"
                      disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit OTP'}
                    </button>
                  </form>
                </div>
              )}

              <p className="mb-6 text-sm">
                By proceeding, you give Revive and its partners permission to
                call the provided number or send WhatsApp messages or SMS
                messages (for example, via an automated service).
              </p>
              <p className="text-sm">
                By clicking Login, you agree to{' '}
                <Link to="/user-agreement" className="font-semibold underline">
                  User Agreement
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
