/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import React, {CSSProperties, useEffect, useState} from 'react';
import {PiArrowCounterClockwise, PiTreeStructure} from 'react-icons/pi';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Swal from 'sweetalert2';
import {noneRotateLeft, reviveStationImg} from '../../assets/images/index';
import {
  QRCODESCAN,
  STRIPEMULTICURANCY,
  USERINFO,
} from '../../services/apiStaticData';
import {check} from '../../assets/images';
import {SlArrowLeft} from 'react-icons/sl';

const StationDetail: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [dynamicData, setDynamicData] = useState<any>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const QR_ID = searchParams.get('qr') || localStorage.getItem('cabinetID');
  if (QR_ID) {
    localStorage.setItem('cabinetID', QR_ID);
  }
  const Login_Token = localStorage.getItem('reviveToken') || null;
  const userInformation = localStorage.getItem('userInfo') || [];

  const navigateToCheckout = async () => {
    if (Login_Token) {
      const checkoutState = {state: dynamicData};
      navigate('/checkout', checkoutState);
    } else {
      const loginState = {state: {state: 'notLogin', data: dynamicData}};
      navigate('/login', loginState);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}${QRCODESCAN}?id=${QR_ID}`,
      );
      if (response.status === 200) {
        if (response?.data?.code === 0) {
          navigate(`/station-detail?qr=${response.data?.cabinetID}`);
          localStorage.setItem('cabinetID', response.data?.cabinetID);
        } else {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            text: response?.data?.msg,
          }).then(() => {
            navigate('/');
          });
        }
      }
      setDynamicData(response?.data);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch data',
        icon: 'error',
      }).then(() => navigate('/'));
    }
  };

  useEffect(() => {
    if (QR_ID) {
      fetchData();
    }
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (Login_Token && userInformation.length === 0) {
      userInfo();
    }
  }, [Login_Token, userInformation]);

  const userInfo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}${USERINFO}`,
        {
          headers: {
            Token: `${Login_Token}`,
          },
        },
      );

      if (response.status === 200 && response?.data?.code === 0) {
        localStorage.setItem('userInfo', JSON.stringify(response?.data?.user));
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.data?.msg,
        }).then(() => navigate('/'));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while getting user info.',
      }).then(() => navigate('/'));
    }
  };

  return (
    <div className="min-h-full">
      <div className="xl:pt-[100px] sm:pt-[64px] pt-6 xl:pb-6 sm:pb-[130px] pb-[165px]">
        <div className="container">
          <div className="grid grid-cols-12 xl:gap-x-10 gap-y-[30px] xl:border-0 border border-[#D6D6D6] rounded-[20px] mb-8 xl:mb-0">
            <div className="xl:col-span-6 col-span-12 relative">
              <div className="flex align-middle gap-4 absolute top-4 xl:top-5 left-4 xl:left-5 right-4 xl:right-5">
                <Link
                  to="/"
                  className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                  <SlArrowLeft />
                </Link>
                <span className="ml-auto font-semibold leading-7 py-[10px] px-4 bg-amber-600 text-gray-950 rounded-[12px]">
                  <span className="text-2xl">
                    {dynamicData?.currencyName}
                    {dynamicData?.priceStrategy?.pPrice}
                  </span>{' '}
                  / {dynamicData?.priceStrategy?.pPriceMinute} minutes
                </span>
              </div>
              <img
                className="w-full rounded-[20px] xl:object-left-bottom object-center object-cover xl:h-[550px] 2xl:h-[682px] h-[230px]"
                src={
                  dynamicData?.shoplogo !== '' && dynamicData?.shoplogo
                    ? dynamicData?.shoplogo
                    : reviveStationImg
                }
                alt="ReviveStation Img"
              />
            </div>

            <div className="xl:col-span-6 col-span-12 flex flex-col xl:px-0 px-4 xl:pt-[30px]">
              {dynamicData?.shopname && (
                <h1 className="text-2xl">{dynamicData?.shopname}</h1>
              )}
              {dynamicData?.shopAddress && (
                <p className="mb-6">{dynamicData?.shopAddress}</p>
              )}
              {dynamicData?.shopContent && (
                <p className="mb-5">{dynamicData?.shopContent}</p>
              )}
              <p className="mb-5 flex gap-[10px] items-center">
                Free of charge for the first {dynamicData?.mianfei} mins. A
                maximum daily charge of {dynamicData?.currencyName}
                {dynamicData?.fengding}. Deposit will be automatically deducted
                when rent reaches {dynamicData?.currencyName}
                {dynamicData?.yajin}
              </p>
              <p className="mb-5 flex gap-[10px] items-center">
                <PiTreeStructure size={18} className="flex-none" /> Adapted to
                all types of mobile phone{' '}
              </p>
              <p className="mb-5 flex gap-[10px] items-center">
                {' '}
                <PiArrowCounterClockwise
                  size={18}
                  className="rotate-[40deg] flex-none"
                />
                Can be returned to any other site
              </p>
              <p className="mb-5 flex gap-[10px] items-start">
                <img
                  className="mt-1 flex-none"
                  src={noneRotateLeft}
                  alt="Arrow Rotate Left Img"
                />{' '}
                Non-return fee: in case you do not return the power bank within{' '}
                {dynamicData?.priceStrategy?.pOvertimeDay} days we will direct
                debit {dynamicData?.currencyName}
                {dynamicData?.overtimeAmount},- from your bank account
              </p>
              <div className="items-center gap-2 mb-3 mt-auto hidden xl:flex">
                <input
                  className="input-check"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  id="flexCheckDefault"
                />
                <label className="text-gray-950" htmlFor="flexCheckDefault">
                  Accept{' '}
                  <Link
                    to="/privacy-policy"
                    className="font-semibold underline">
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link
                    to="/terms-and-condition"
                    className="font-semibold underline">
                    Terms and Conditions
                  </Link>
                </label>
              </div>
              <button
                onClick={() => navigateToCheckout()}
                className="btn btn-primary px-8 hidden xl:block"
                disabled={!isChecked}>
                {!Login_Token ? (
                  <>
                    <span className="me-3">Pre-authorization</span>{' '}
                    {dynamicData?.priceStrategy?.pDepositAmount}{' '}
                    {dynamicData?.currency}{' '}
                  </>
                ) : (
                  <span className="me-3">Rent the power bank</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 w-full xl:hidden inline-block">
        <div className="container py-2 bg-white">
          <div className="flex items-center gap-2">
            <input
              className="input-check"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              id="flexCheckDefault"
            />
            <label className="text-gray-950" htmlFor="flexCheckDefault">
              Accept{' '}
              <Link to="/privacy-policy" className="font-semibold underline">
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                to="/terms-and-condition"
                className="font-semibold underline">
                Terms and Conditions
              </Link>
            </label>
          </div>
        </div>

        <div className="bg-white shadow-3xl py-7">
          <div className="container">
            <button
              onClick={() => navigateToCheckout()}
              className="btn btn-primary px-4"
              disabled={!isChecked}>
              {!Login_Token ? (
                <>
                  <span className="me-3">Pre-authorization</span>{' '}
                  {dynamicData?.priceStrategy?.pDepositAmount}{' '}
                  {dynamicData?.currency}{' '}
                </>
              ) : (
                <span className="me-3">Rent the power bank</span>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* {isCheckout ? (
        <div style={iframeDiv} onClick={hideCheckout}>
          <iframe src="/checkout" title="Checkout" style={iframe} />
        </div>
      ) : null} */}
    </div>
  );
};

const iframeDiv: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  height: '100%',
  width: '100%',
  zIndex: 9999,
  backgroundColor: 'rgba(0,0,0,0.8)',
};

const iframe: CSSProperties = {
  border: 'none',
  borderRadius: 15,
  height: '100%',
  maxHeight: '100vh',
  width: '100%',
  maxWidth: '324px',
};

export default StationDetail;
