import React from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {agreementVector} from '../../assets/images';

const UserAgreement: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="flex items-center gap-6">
            <Link
              to="/login"
              className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
              <SlArrowLeft />
            </Link>
            <h4 className="text-2xl">User Agreement</h4>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          <div className="hero-image mb-[30px]">
            <img
              className="mb-6 max-w-[80%] sm:max-w-[484px] w-full mx-auto"
              alt="User Agreement"
              src={agreementVector}
            />
          </div>
          <div className="user-agreement-content">
            <p>
              PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
              SERVICES.
            </p>
            <h4>Contractual Relationship</h4>
            <p>
              These Terms of Use (“Terms”) govern the access or use by you, an
              individual, from within any country in the world of applications,
              websites, content, products, and services (the “Services”) made
              available by Revive (“The Company”)
            </p>
            <p>
              Your access and use of the Services constitutes your agreement to
              be bound by these Terms, which establishes a contractual
              relationship between you and The Company. If you do not agree to
              these Terms, you may not access or use the Services. These Terms
              expressly supersede prior agreements or arrangements with you. The
              Company may immediately terminate these Terms or any Services with
              respect to you, or generally cease offering or deny access to the
              Services or any portion thereof, at any time for any reason.
            </p>
            <p>
              Supplemental terms may apply to certain Services, such as policies
              for a particular event, activity or promotion, and such
              supplemental terms will be disclosed to you in connection with the
              applicable Services. Supplemental terms are in addition to, and
              shall be deemed a part of, the Terms for the purposes of the
              applicable Services. Supplemental terms shall prevail over these
              Terms in the event of a conflict with respect to the applicable
              Services.
            </p>
            <p>
              The Company may amend the Terms related to the Services from time
              to time. Amendments will be effective upon The Company’s posting
              of such updated Terms at this location or the amended policies or
              supplemental terms on the applicable Service. Your continued
              access or use of the Services after such posting constitutes your
              consent to be bound by the Terms, as amended.
            </p>
            <p>
              Our collection and use of personal information in connection with
              the Services is as provided in The Company’s Privacy Policy. The
              Company may provide to a claims processor or an insurer any
              necessary information (including your contact information) if
              there is a complaint, dispute or conflict, which may include an
              accident, involving you and a third party and such information or
              data is necessary to resolve the complaint, dispute or conflict.
            </p>
            <h4>The Services</h4>
            <p>
              The Services constitute a technology platform that enables users
              of The Company’s mobile applications or websites provided as part
              of the Services (each, an “Application”) to rent public portable
              charger, including web services of portable charger-rental
              software. Unless otherwise agreed by The Company in a separate
              written agreement with you, the Services are made available solely
              for your personal, non-commercial use.
            </p>
            <h5>(1) License.</h5>
            <p>
              Subject to your compliance with these Terms, The Company grants
              you a limited, non-exclusive, non-sub- licensable, revocable,
              non-transferrable license to: (i) access and use the Applications
              on your personal device solely about your use of the Services; and
              (ii) access and use any content, information and related materials
              that may be made available through the Services, in each case
              solely for your personal, non-commercial use. Any rights not
              expressly granted herein are reserved by The Company and The
              Company’s licensors.
            </p>
            <h5>(2) Restrictions.</h5>
            <p>
              You may not: (i) remove any copyright, trademark or other
              proprietary notices from any portion of the Services; (ii)
              reproduce, modify, prepare derivative works based upon,
              distribute, license, lease, sell, resell, transfer, publicly
              display, publicly perform, transmit, stream, broadcast or
              otherwise exploit the Services except as expressly permitted by
              the Company; (iii) decompile, reverse engineer or disassemble the
              Services except as may be permitted by applicable law; (iv) link
              to, mirror or frame any portion of the Services; (v) cause or
              launch any programs or scripts for the purpose of scraping,
              indexing, surveying, or otherwise data mining any portion of the
              Services or unduly burdening or hindering the operation and/or
              functionality of any aspect of the Services; or (vi) attempt to
              gain unauthorised access to or impair any aspect of the Services
              or its related systems or networks.
            </p>
            <h5>(3) Provision of the Services.</h5>
            <p>
              You acknowledge that portions of the Services may be made
              available under The Company’s various brands or request options
              associated with public portable charger, including the
              transportation request brands currently referred to as “The
              Company”.
            </p>
            <h5>(4) Third Party Services.</h5>
            <p>
              The Services may be made available or accessed in connection with
              third party services and content (including advertising) that The
              Company does not control. You acknowledge that different terms of
              use and privacy policies may apply to your use of such third party
              services and content. The Company does not endorse such third
              party services and content and in no event shall The Company be
              responsible or liable for any products or services of such third
              party providers. Additionally, Apple Inc., Google, Inc., and/or
              their applicable international subsidiaries and affiliates will be
              third-party beneficiaries to this contract if you access the
              Services using Applications developed for Apple iOS, Android
              mobile devices, respectively. These third party beneficiaries are
              not parties to this contract and are not responsible for the
              provision or support of the Services in any manner. Your access to
              the Services using these devices is subject to terms set forth in
              the applicable third party beneficiary’s terms of service.
            </p>
            <h5>(5) Ownership.</h5>
            <p>
              The Services and all rights therein are and shall remain The
              Company’s property or the property of The Company’s licensors.
              Neither these Terms nor your use of the Services convey or grant
              to you any rights: (i) in or related to the Services except for
              the limited license granted above; or (ii) to use or reference in
              any manner The Company’s company names, logos, product and service
              names, trademarks or services marks or those of The Company’s
              licensors.
            </p>
            <h5>(6) Maintenance</h5>
            <p>
              The Company is responsible for the daily running, maintenance and
              repair of the portable charger. But it does not mean that the
              Company has the obligation to ensure that all available portable
              chargers are in a trouble-free condition in real time. The user
              should confirm the integrity of the components, efficiency, and
              familiarise with the performance of portable charger and safety
              devices before using a portable charger.
            </p>
            <p>
              If usedr finds that the portable charger is broken, he/ she shall
              cancel reservation or stop using, and notify The Company about the
              problem. If the user disregards the broken portable charger and
              continues use it, the user should take responsibility for the
              behaviour.
            </p>
            <h4>Your Use of the Services</h4>
            <h5>(1) User Accounts.</h5>
            <p>
              In order to use most aspects of the Services, you must register
              for and maintain an active personal user Services account
              (“Account”). A profile page shall be provided for you to update
              your personal information, such as your name, email and mobile
              phone number. You agree to maintain accurate, complete, and
              up-to-date information in your Account. Your failure to maintain
              accurate, complete, and up-to-date Account information may result
              in your inability to access and use the Services or The Company’s
              termination of these Terms with you. You are responsible for all
              activity that occurs under your Account, and you agree to maintain
              the security and secrecy of your Account username and password at
              all times. Unless otherwise permitted by The Company in writing,
              you may only possess one Account.
            </p>
            <h5>(2) User Requirements and Conduct.</h5>
            <p>
              You may not authorise third parties to use your Account. You may
              not assign or otherwise transfer your Account to any other person
              or entity. You agree to comply with all applicable laws when using
              the Services, and you may only use the Services for lawful
              purposes. You will not, in your use of the Services, cause
              nuisance, annoyance, inconvenience, or property damage, whether to
              any other party. In certain instances, you may be asked to provide
              proof of identity to access or use the Services, and you agree
              that you may be denied access to or use of the Services if you
              refuse to provide proof of identity.
            </p>
            <p>
              You shall not: (i) submit, upload or publish any defamatory,
              libellous, hateful, violent, obscene, pornographic, unlawful, or
              otherwise offensive content, (ii) without the prior consent of The
              Company, publish any commercial or non-commercial advertisements,
              (iii) infringe any other party’s lawful rights, including without
              limitation intellectual property rights
            </p>
            <h5>(3) Text Messaging.</h5>
            <p>
              By creating an Account, you agree that the Services may send you
              informational text (SMS) messages as part of the normal business
              operation of your use of the Services. Should you have questions
              about receiving text (SMS) messages from The Company, you may
              contact The Company through koen@revivesharing.com. You
              acknowledge that opting out of receiving text (SMS) messages may
              impact your use of the Services.
            </p>
            <h5>(4) Promotional Codes.</h5>
            <p>
              The Company may, in The Company’s sole discretion, create
              promotional codes that may be redeemed for Account credit, or
              other features or benefits related to the Services, subject to any
              additional terms that The Company establishes on a per promotional
              code basis (“Promo Codes”). You agree that Promo Codes: (i) must
              be used for the intended audience and purpose, and in a lawful
              manner; (ii) may not be duplicated, sold or transferred in any
              manner, or made available to the general public (whether posted to
              a public form or otherwise), unless expressly permitted by The
              Company; (iii) may be disabled by The Company at any time for any
              reason without liability to The Company; (iv) may only be used
              pursuant to the specific terms that The Company establishes for
              such Promo Code; (v) are not valid for cash; and (vi) may expire
              prior to your use. The Company reserves the right to withhold or
              deduct credits or other features or benefits obtained through the
              use of Promo Codes by you or any other user in the event that The
              Company determines or believes that the use or redemption of the
              Promo Code was in error, fraudulent, illegal, or in violation of
              the applicable Promo Code terms or these Terms.
            </p>
            <h5>(5) Network Access and Devices.</h5>
            <p>
              You are responsible for obtaining the data network access
              necessary to use the Services. Your mobile network’s data and
              messaging rates and fees may apply if you access or use the
              Services from a wireless-enabled device and you shall be
              responsible for such rates and fees. You are responsible for
              acquiring and updating compatible hardware or devices necessary to
              access and use the Services and Applications and any updates
              thereto. The Company does not guarantee that the Services, or any
              portion thereof, will function on any particular hardware or
              devices. In addition, the Services may be subject to malfunctions
              and delays inherent in the use of the Internet and electronic
              communications.
            </p>
            <h4>How the system works</h4>
            <p>
              User’s period of portable charger rental begins either when the
              portable charger is unlocked with the mobile app. The Rental
              Period ends when the portable charger is returned. Additional fees
              will apply if the portable charger is not returned.
            </p>
            <h4>Payment</h4>
            <p>
              You understand that use of the Services may result in charges to
              you for the services you receive (“Charges”). After you have
              received services obtained through your use of the Service, The
              Company will facilitate your payment of the applicable Charges.
              Charges will be inclusive of applicable taxes where required by
              law.
            </p>
            <p>
              The Security Deposit is debited from your payment card as part of
              the registration process and paid to The Company on the start date
              of the account.
            </p>
            <p>
              The Security Deposit is refundable; it takes 1 to 14 days to
              process the refund.
            </p>
            <p>
              You need to recharge, also referred as top-up, before you start
              using the Services. All Charges are due immediately and payment
              will be deducted from your wallet from the balance of your
              Account. You are not able to transfer your credit to the other
              users. The expiry date for your credit shall be determined by The
              Company’s policy. The Company may from time to time provide
              certain users with promotional offers and discounts that may
              result in different amounts charged for the same or similar
              services or goods obtained through the use of the Services, and
              you agree that such promotional offers and discounts, unless also
              made available to you, shall have no bearing on your use of the
              Services or the Charges applied to you.
            </p>
            <h4>
              Restrictions and other Terms and Conditions of Portable Charger
              Use
            </h4>
            <h5>Representations and Warranties</h5>
            <p>
              As a condition precedent to the Company’s agreement to allow User
              to participate in the Program and to rent a portable charger, User
              represents and warrants to the Company that:
            </p>
            <p>
              User meets the requirements: (1) according to the related local
              jurisdiction. (2)User is experienced and familiar with the safe
              and competent operation of a portable charger.
            </p>
            <p>
              User is familiar with all applicable local, state, and county
              rules, regulations, codes and laws that relate to the safe and
              legal operation of a portable charger.
            </p>
            <h5>Acknowledgements and Agreements</h5>
            <p>
              As a condition precedent to the Company’s agreement to allow User
              to participate in the Program and to rent a portable charger, User
              acknowledges and agrees as follows:
            </p>
            <p>
              User is fully aware that using a portable charger poses a risk of
              accident due to contact with water, get near the fire, and
              dismantles the portable charger, and user must keep a proper
              lookout to avoid such accidents.
            </p>
            <p>
              User is solely responsible for operating and using a portable
              charger in a careful and reasonably competent manner.
            </p>
            <p>
              All portable chargers are and shall remain the exclusive property
              of the Company at all times.
            </p>
            <p>
              The Company is not obligated to provide insurance of any kind
              related to User or User’s use of the portable charger, and in the
              event that the Company, at its option, carries insurance, User
              shall remain liable for any liability, property damage, personal
              injury, injury to others, damages, penalties, fines, losses, and/
              or expenses of any kind whatsoever.
            </p>
            <p>
              If User causes damage to property or injury to another party while
              operating or in possession of the portable charger, User is solely
              liable for such damage or injury.
            </p>
            <p>
              User shall return the portable charger to the Company in the same
              condition as when received.
            </p>
            <p>
              User is liable for any and all damages resulting from improper use
              or abuse of the portable charger and the cost of such damages.
            </p>
            <p>
              The Company provides portable charger as a convenience, and such
              rental availability is intended to be used only by those persons
              who have agreed to all terms and conditions of this Agreement.
            </p>
            <h4>Requirements</h4>
            <p>
              As a condition precedent to the Company’s agreement to allow User
              to participate in the Program and to rent a portable charger, User
              shall do the following during any use of a portable charger (“Use
              Requirements”):
            </p>
            <p>
              Carefully inspect the portable charger that User wishes to rent
              prior to use to ensure the portable charger is good operating
              condition,
            </p>
            <p>
              Test the portable charger’s operating components before proceeding
              with the intended use, including, but not limited to the portable
              charger and charging cable.
            </p>
            <p>
              Promptly notify the Company customer service of any defect,
              malfunction or needed repair to a portable charger.
            </p>
            <p>
              Contact the Company and local Police immediately in the event of
              theft of the portable charger or an accident that occurred during
              User’s use of the portable charger resulting in any bodily injury.
            </p>
            <h5>Dispute Resolution</h5>
            <p>
              User agrees that The Company, as its sole discretion, may submit
              any disputes whatsoever arising out of, resulting from, and / or
              relating to this Agreement, User’s use of The Company’s Equipment,
              including, without limitation, portable chargers, and/ or The
              Company website, to courts in the local jurisdiction. And this
              Agreement shall be governed by the laws of the local jurisdiction
              without regard to conflicts of law provisions.
            </p>
            <h5>Indemnification</h5>
            <p>
              User shall indemnify, defend and hold harmless The Company for,
              from and against any and all Claims related to or arising out of
              This Agreement, including, but no limited to User’s breach of any
              representation, warranties or covenants set forth in This
              Agreement, and the rental, maintenance, design, use or operation
              of the portable charger, and/or The Company website, even where
              caused in whole or in part by The Company’s negligence, and / or
              the negligence of others, whether presently known or unknown. At
              The Company’s option, User will assume control of the defence and
              settlement of any Claim subject to indemnification by User
              (provided that, in such event, The Company may at any time elect
              to take over control of the defence and settlement of any such
              Claim). In no event may User settle any Claim without The
              Company’s prior written consent.
            </p>
            <h5>Assignment</h5>
            <p>
              The Company may assign its rights and duties under This Agreement
              to any party(ies) at any time without notice to User.
            </p>
            <h5>No Waiver</h5>
            <p>
              The Company’s failure to insist upon or enforce strict performance
              of any provision of This Agreement shall not be construed as a
              waiver of any provision or right. Neither the course of conduct
              between the parties nor trade practice shall act to modify any
              part of This Agreement. No waiver by The Company shall be
              construed as a waiver of any proceeding or succeeding breach of
              any provision in This Agreement
            </p>
            <h5>Termination of Agreement</h5>
            <p>
              The Company may terminate This Agreement at any time, without
              cause, legal process, or notice to the User and User’s use of the
              System is ‘at the will’ of The Company. User waives all claims,
              causes actions, expenses, and/ or damages connected and/ or
              related to any such termination. User shall not be entitled to a
              refund of any amount paid for unused rental periods if This
              Agreement is terminated for cause. User may terminate User’s
              rental plan at any time; provided, however, that no refund will be
              provided by The Company for time already used by User.
            </p>
            <h5>Survival</h5>
            <p>
              All provisions of This Agreement relating to limitation and
              exclusion of liability, waivers, assumption of risk, warranties
              and indemnification obligations shall survive the termination of
              This Agreement, and all amounts unpaid at the time of termination
              or expiration of This Agreement shall remain due and payable.
            </p>
            <h5>Personal Data Protection</h5>
            <p>
              You agree and consent to The Company using and processing your
              Personal Data for the Purposes and in the manner as identified
              hereunder.
            </p>
            <p>
              For the purposes of This Agreement, ‘Personal Data’ means
              information about you, from which you are identifiable, including
              but not limited to your name, identification card number, birth
              certificate number, passport number, nationality, address,
              telephone number, credit or debit card details, race, gender, date
              of birth, email address, any information about you which you have
              provided to The Company in registration forms, application forms
              or any other similar forms and/or any information about you that
              has been or may be collected, stored, used and processed by The
              Company from time to time and includes sensitive personal data
              such as data relating to health, religious or other similar
              beliefs.
            </p>
            <p>
              The provision of your Personal Data is voluntary. However, if you
              do not provide The Company your Personal Data, your request for
              the Application maybe incomplete and The Company will not be able
              to process your Personal Data for the Purposes outlined below and
              may cause The Company to be unable to allow you to use the
              Service.
            </p>
            <p>
              The Company may use and process your Personal Data for business
              and activities of The Company which shall include, without
              limitation the following (the ‘Purpose’):
            </p>
            <p>
              To perform The Company’s obligations in respect of any contract
              entered with you;
            </p>
            <p>
              To provide you with any services pursuant to the Terms of Use
              herein;
            </p>
            <p>
              To process your participation in any events, promotions,
              activities, focus groups, research studies, contests, promotions,
              polls, surveys or any productions and to communicate with you
              regarding your attendance thereto;
            </p>
            <p>
              Process, manage or verify your application for the Service
              pursuant to the Terms of Use herein;
            </p>
            <p>
              To validate and/ or process payments pursuant to the Terms of Use
              herein;
            </p>
            <p>
              To develop, enhance and provide what is required pursuant to the
              Terms of Use herein to meet your needs;
            </p>
            <p>
              To process any refunds, rebates, and or charges pursuant to the
              Terms of Use herein;
            </p>
            <p>
              To facilitate or enable any checks as may be required pursuant to
              the Terms of Use herein;
            </p>
            <p>To respond to questions, comments, and feedback from you;</p>
            <p>To communicate with you for any of purposes listed herein;</p>
            <p>
              For internal administrative purposes, such as auditing, data
              analysis, database records;
            </p>
            <p>
              For purposes of detection, prevention and prosecution of crime;
            </p>
            <p>For The Company to comply with its obligations under law;</p>
            <p>
              To send you alerts, newsletters updates, mailers, promotional
              materials, special privileges, festive greetings from The Company,
              its partners, advertisers and/ or sponsors;
            </p>
            <p>
              To notify and invite you to events or activities organised by The
              Company, its partners, advertisers, and/ or sponsors; and/or
            </p>
            <p>
              To share your Personal Data amongst the companies within the
              Company’s group of companies comprising the subsidiaries,
              associate companies and/ or jointly controlled entitles of the
              holding company of the group (the ‘Group’) and with The Company’s
              and Group’s agents, third party providers, developers,
              advertisers, partners, event companies or sponsors who may
              communicate with you for any reasons whatsoever.
            </p>
            <p>
              If you do not consent to The Company processing your Personal Data
              for any of the Purposes, please notify The Company using the
              support details as provided in the Application.
            </p>
            <p>
              If any of the Personal Data that you have provided to us changes,
              for example, if you change your e-mail address, telephone number,
              payment details of if you wish to cancel your account, please
              update your details by sending your request to the support contact
              details as provided in the Application.
            </p>
            <p>
              By submitting your information to The Company, you consent to the
              use of that information as set out in the form of submission and
              in the Terms of Use.
            </p>
            <h4>Third Party Interactions</h4>
            <p>
              During use of the Service, you may enter into correspondence with,
              purchase goods and/or services from, or participate in promotions
              of third party providers, advertisers or sponsors showing their
              goods and/or services through the Service, the Software and/or the
              Application. Any such activity, and any terms, conditions,
              warranties or representations associated with such activity, is
              solely between you and the applicable third-party. The Company and
              its licensors shall have no liability, obligation or
              responsibility for any such correspondence, purchase, transaction
              or promotion between you and any such third-party. The Group does
              not endorse any applications or sites on the Internet that are
              linked through the Service, the Application and/or the Software,
              and in no event, shall the Company, its licensors or the Group be
              responsible for any content, products, services or other materials
              on or available from such sites or third party providers. The
              Company provides the Service to you pursuant to the Terms of Use.
              You recognise, however, that certain third party providers of
              transportation, goods and/or services may require your agreement
              to additional or different Terms of Use prior to your use of or
              access to such goods or services, and the Company is not a party
              to and disclaims any and all responsibility and/or liability
              arising from such agreements between you and the third party
              providers.
            </p>
            <p>
              The Company may rely on third party advertising and marketing
              supplied through the Service and other mechanisms to subsidise the
              Service and/or to earn additional revenue. By agreeing to the
              Terms of Use you agree to receive such advertising and marketing.
              If you do not want to receive such advertising, you should notify
              us in writing or in accordance with the procedure determined by
              the Company. The Company reserves the right to charge you a higher
              fee for or deny you use of the Service should you choose not to
              receive these advertising services. This higher fee, if
              applicable, will be posted on the Company’s website located at
              https://revivesharing.com/. You agree and allow the Company to
              compile and release information regarding you and your use of the
              Service on an anonymous basis as part of a customer profile or
              similar report or analysis. You agree that it is your
              responsibility to take all precautions in all actions and
              interactions with any third party transportation provider, other
              third party providers, advertisers and/or sponsors you interact
              with through the Service and/or advertising or marketing material
              supplied through the Service.
            </p>
            <h4>Customer Support</h4>
            <p>
              If you encounter any problem using the powerbank, you can report
              it with the APP by clicking customer services. We encourage you to
              submit your comment. Moreover, you can contact us by email at
              koen@revivesharing.com.
            </p>
            <h4>Disclaimers; Limitation of Liability; Indemnity.</h4>
            <h5>(1) DISCLAIMER.</h5>
            <p>
              THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” THE COMPANY
              DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR
              STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. IN ADDITION, THE COMPANY MAKES NO
              REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY,
              QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY
              SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR
              THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. YOU AGREE
              THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND
              ANY SERVICE OR GOOD REQUESTED IN CONNECTION THEREWITH, REMAINS
              SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
              LAW.
            </p>
            <h5>(2) LIMITATION OF LIABILITY.</h5>
            <p>
              THE COMPANY SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
              EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
              PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO,
              IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE
              SERVICES, EXCEPT THOSE RESULTING SOLELY FROM THE FAULTS OF THE
              COMPANY. THE COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES,
              LIABILITY OR LOSSES ARISING OUT OF YOUR USE OF OR RELIANCE ON THE
              SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES, EVEN IF
              THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              THE COMPANY SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN
              PERFORMANCE RESULTING FROM CAUSES BEYOND THE COMPANY’S REASONABLE
              CONTROL.
            </p>
            <p>
              THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 6 DO NOT PURPORT TO
              LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE
              EXCLUDED UNDER APPLICABLE LAW.
            </p>
            <h5>(3) Indemnity.</h5>
            <p>
              You agree to indemnify and hold The Company and its officers,
              directors, employees and agents harmless from any and all claims,
              demands, losses, liabilities, and expenses (including attorneys’
              fees) arising out of or in connection with: (i) your use of the
              Services or services or goods obtained through your use of the
              Services; (ii) your breach or violation of any of these Terms;
              (iii) your violation of the rights of any third party, including
              injurers in accidents.
            </p>
            <h4>Governing Law; Arbitration.</h4>
            <p>
              Except as otherwise set forth in these Terms, these Terms shall be
              exclusively governed by and construed in accordance with the laws
              of the local jurisdiction.
            </p>
            <h4>Other Provisions</h4>
            <h5>(1) Notice.</h5>
            <p>
              The Company may give notice by means of a general notice on the
              Services, or by text (SMS) messages. You may give notice to The
              Company by writing email to koen@revivesharing.com.
            </p>
            <h5>(2) General.</h5>
            <p>
              You may not assign or transfer these Terms in whole or in part
              without The Company’s prior written approval. You give your
              approval to The Company for it to assign or transfer these Terms
              in whole or in part, including to: (i) a subsidiary or affiliate;
              (ii) an acquirer of The Company’s equity, business or assets; or
              (iii) a successor by merger. No joint venture, partnership,
              employment or agency relationship exists between you and The
              Company as a result of the contract between you and The Company or
              use of the Services.
            </p>
            <p>
              If any provision of these Terms is held to be illegal, invalid or
              unenforceable, in whole or in part, under any law, such provision
              or part thereof shall to that extent be deemed not to form part of
              these Terms but the legality, validity and enforceability of the
              other provisions in these Terms shall not be affected. In that
              event, the parties shall replace the illegal, invalid or
              unenforceable provision or part thereof with a provision or part
              thereof that is legal, valid and enforceable and that has, to the
              greatest extent possible, a similar effect as the illegal, invalid
              or unenforceable provision or part thereof, given the contents and
              purpose of these Terms. These Terms constitute the entire
              agreement and understanding of the parties with respect to its
              subject matter and replaces and supersedes all prior or
              contemporaneous agreements or undertakings regarding such subject
              matter. In these Terms, the words “including” and “include” mean
              “including, but not limited to.”
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAgreement;
