import React from 'react';
import {Navigate} from 'react-router-dom';

interface PublicRouteProps {
  element: React.ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({element}) => {
  const Login_Token = localStorage.getItem('reviveToken');

  return Login_Token ? <Navigate to="/" replace /> : element;
};

export default PublicRoute;
