/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {
  PiArrowCounterClockwise,
  PiArrowUUpLeft,
  PiCalendarDots,
  PiClock,
  PiCopySimple,
  PiLightning,
  PiTreeStructure,
} from 'react-icons/pi';
import classNames from 'classnames'; // Import classNames

const orders = [
  {
    orderNo: '24072107234836',
    venueRented: 'South Korea',
    venueReturned: 'South Korea',
    date: 'Sep 19, 2024',
    time: '01:53 PM',
    buttonText: 'On Rent',
  },
  {
    orderNo: '24072107234836',
    venueRented: 'South Korea',
    venueReturned: 'South Korea',
    date: 'Sep 19, 2024',
    time: '01:53 PM',
    buttonText: 'Returned',
  },
  {
    orderNo: '24072107234836',
    venueRented: 'South Korea',
    venueReturned: 'South Korea',
    date: 'Sep 19, 2024',
    time: '01:53 PM',
    buttonText: 'Returned',
  },
  {
    orderNo: '24072107234836',
    venueRented: 'South Korea',
    venueReturned: 'South Korea',
    date: 'Sep 19, 2024',
    time: '01:53 PM',
    buttonText: 'Returned',
  },
  {
    orderNo: '24072107234836',
    venueRented: 'South Korea',
    venueReturned: 'South Korea',
    date: 'Sep 19, 2024',
    time: '01:53 PM',
    buttonText: 'Returned',
  },
  {
    orderNo: '24072107234836',
    venueRented: 'South Korea',
    venueReturned: 'South Korea',
    date: 'Sep 19, 2024',
    time: '01:53 PM',
    buttonText: 'Returned',
  },
];

const OrderList: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="flex items-center gap-6">
            <Link
              to="/"
              className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
              <SlArrowLeft />
            </Link>
            <h4 className="text-2xl">My Order</h4>
          </div>
        </div>
      </div>
      <div className="pb-10">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[30px]">
            {orders.map((order, index) => (
              <Link to="/order-list/order-details" key={index}>
                <div className="bg-white border border-gray-medium rounded-lg p-6 shadow">
                  <div className="grid grid-cols-2">
                    <div className="mb-[14px]">
                      <h6 className="mb-3">Order No.</h6>
                      <div className="flex items-center gap-1">
                        <PiCopySimple className="text-lg flex-none text-amber-600 cursor-pointer" />
                        <span className="text-sm text-gray-950">
                          {order.orderNo}
                        </span>
                      </div>
                    </div>
                    <div>
                      <h5 className="text-xl text-gray-950 text-end">€00.00</h5>
                    </div>
                  </div>
                  <div className="grid grid-cols-6 grid-rows-2 gap-2 place-items-stretc py-[14px] mb-[14px] border-y border-gray-medium">
                    <div className="col-span-4">
                      <h6 className="flex items-center gap-2">
                        <PiLightning className="text-lg flex-none" />
                        <span>Venue Rented:</span>
                      </h6>
                    </div>
                    <div className="col-span-2">
                      <p className="text-sm text-gray-950 text-end">
                        {order.venueRented}
                      </p>
                    </div>
                    <div className="col-span-4">
                      <h6 className="flex items-center gap-2">
                        <PiArrowUUpLeft className="text-lg flex-none" />
                        <span>Venue Returned:</span>
                      </h6>
                    </div>
                    <div className="col-span-2">
                      <p className="text-sm text-gray-950 text-end">
                        {order.venueReturned}
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>
                      <p className="text-gray-600 text-sm flex items-center gap-2 mb-[9px]">
                        <PiCalendarDots className="text-lg flex-none" />
                        {order.date}
                      </p>
                      <p className="text-gray-600 text-sm flex items-center gap-2">
                        <PiClock className="text-lg flex-none" />
                        {order.time}
                      </p>
                    </div>
                    <div className="text-end">
                      <span
                        className={classNames(
                          'inline-block text-center px-[14px] py-[6px] leading-tight text-sm rounded-[5px] min-w-[88px]',
                          {
                            'bg-amber-600 text-gray-950':
                              order.buttonText === 'On Rent',
                            'bg-gray-950 text-white':
                              order.buttonText !== 'On Rent',
                          },
                        )}>
                        {order.buttonText}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
