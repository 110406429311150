/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {success} from '../../assets/images';

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();
  const navigateToHome = () => navigate('/');

  return (
    <div className="min-h-full flex flex-col justify-center items-center pt-6 lg:py-[100px]">
      <div className="container text-center lg:mb-0 pb-[115px] lg:pb-0">
        <div className="max-w-[362px] mx-auto">
          <img
            className="mb-7 w-auto h-auto mx-auto"
            alt="payment-success"
            src={success}
            width={100}
            height={100}
          />

          <h1 className="text-xl font-semibold">Payment is Successful</h1>
          <p className="mt-1.5 mb-6">
            Click the button below to view your order.
          </p>
        </div>
      </div>

      <div className="py-7 lg:py-1.5 lg:relative fixed bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
        <div className="container text-center">
          <div className="lg:max-w-[362px] mx-auto">
            <button className="btn-primary px-4" onClick={navigateToHome}>
              <span>View My Order</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
