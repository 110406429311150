/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import {Elements, useElements} from '@stripe/react-stripe-js';
import {useStripe, PaymentElement} from '@stripe/react-stripe-js';
import {API_BASE_URL} from '../../services/Api';
import {color, rgbaColor} from '../../assets/colors';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/high-res.css';
import {apple, google, loginBanner} from '../../assets/images/index';
import {PAYMENTCONFIRM} from '../../services/apiStaticData';
import Swal from 'sweetalert2';
import axios from 'axios';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
);

const CheckoutForm = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const {state} = useLocation();
  const [isPaymentElementComplete, setIsPaymentElementComplete] =
    useState(false);
  const [message, setMessage] = useState('');
  const Login_Token = localStorage.getItem('reviveToken');
  const cabinetData = localStorage.getItem('cabinetData');
  const disabled =
    !stripe || isProcessing || !isPaymentElementComplete || isSuccess;

  const bgColor = isSuccess
    ? color.green
    : !isPaymentElementComplete
    ? rgbaColor.cornflower_blue_0_5
    : color.dodger_blue; // Change background color on success;

  const handlePaymentElementChange = event =>
    setIsPaymentElementComplete(event.complete);

  const handleError = error => {
    setIsProcessing(false);
    setMessage(error.message);
  };

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements?.submit();

    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    // const res = await fetch(`${API_BASE_URL}/stripe/secret`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });

    const clientSecret = state?.clientSecret;
    // Confirm the PaymentIntent using the details collected by the Payment Element
    const {error, paymentIntent} = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        // return_url: `${window.location.origin}/payment/success`,
        return_url: `${window.location.origin}/popout-timer`,
      },
      redirect: 'if_required',
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)

      handleError(error);
      navigate('/payment/failure', {state: error});
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Success! Payment received.');
          setIsProcessing(false);
          setIsSuccess(true);
          // navigate('/payment/success');
          // navigate('/popout-timer');
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_HOST}${PAYMENTCONFIRM}?tradeNo=${cabinetData?.paymentId}`,
              {
                headers: {
                  Token: `${Login_Token}`,
                },
              },
            );
            if (response.status === 200) {
              if (response?.data?.code === 0) {
                navigate('/popout-timer');
              } else {
                Swal.fire({
                  icon: 'error',
                  text: response?.data?.msg,
                });
              }
            }
          } catch (error) {
            Swal.fire({
              icon: 'error',
              text: error,
            });
          }
          break;

        case 'processing':
          setMessage(
            "Payment processing. We'll update you when payment is received.",
          );
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage('Payment failed. Please try another payment method.');
          navigate('/payment/failure', {state: ''});
          break;

        default:
          setMessage('Something went wrong.');
          break;
      }
    }
  };

  return (
    <>
      <div className="py-6 min-h-full flex flex-col items-center">
        <div className="container text-start">
          <div className="max-w-[484px] mx-auto">
            <div>
              <img
                className="mb-6 w-full"
                alt="login-banner"
                src={loginBanner}
              />
              {/* <button className="btn-secondary px-4 mb-6 flex gap-4 justify-center">
              <img alt="google-icon" src={google} />
              GooglePay
            </button>
            <button className="btn-secondary px-4 mb-6 flex gap-4 justify-center">
              <img alt="google-icon" src={apple} />
              ApplePay
            </button>
            <div className="text-gray-300 mb-6 relative block w-full text-center before:block before:relative before:w-full before:top-[14px] before:z-0 before:bg-gray-300 before:h-[1px]">
              <span className="px-5 relative bg-white">or</span>
            </div> */}
              {/* <div className="mb-4">
              <label className='mb-2 inline-block text-start' htmlFor="email">Enter your email</label>
              <input className="form-input" value="" id="email" name="email" type="text" placeholder="example@gmail.com" />
            </div> */}
              <form
                // style={form}
                onClick={handleSubmit}>
                <PaymentElement
                  id="payment-element"
                  onChange={handlePaymentElementChange}
                />

                <button
                  type="submit"
                  // onClick={handleSubmit}
                  disabled={disabled} // Disable when processing or success
                  style={{
                    ...payButton,
                    backgroundColor: bgColor,
                  }}>
                  {isSuccess ? (
                    <span className="check-icon">&#10003;</span>
                  ) : isProcessing ? (
                    'Processing...'
                  ) : (
                    'Pay'
                  )}
                </button>

                <span style={messageStyle}>{message}</span>
              </form>
              {/* <button className="btn-primary px-8 mt-4">
              Continue
            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Checkout = () => {
  const options = {
    // passing the stripe elements options
    mode: 'payment',
    amount: 1099,
    currency: 'eur',
    // Fully customizable with appearance API.
    appearance: {
      theme: 'flat',
      labels: 'floating',
      variables: {
        colorPrimary: '#0A74DA',
        colorBackground: '#F4F4F5',
        colorText: '#333',
      },
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <div
        className="py-6 min-h-full flex flex-col items-center md:justify-center"
        // style={containerStyle}
      >
        <CheckoutForm />
      </div>
    </Elements>
  );
};

// const containerStyle = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   height: '100vh',
// };

// const form = {
// padding: '15px',
// backgroundColor: color.white,
// borderRadius: '12px',
// // boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.2)',
// width: '40%',
// justifyContent: 'center',
// alignItems: 'center',
// alignSelf: 'center',
// };

const payButton = {
  backgroundColor: color.dodger_blue,
  borderRadius: 10,
  padding: '10px 20px 10px 20px',
  color: color.white,
  fontWeight: 'normal',
  marginTop: 15,
  width: '100%',
  cursor: 'pointer',
  opacity: 0.9, // Full opacity
  transition: 'background-color 0.3s ease',
};

const messageStyle = {
  color: color.black,
  fontWeight: 'normal',
  fontFamily: 'serif',
  fontSize: '16px',
};

export default Checkout;
