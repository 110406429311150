import React from 'react';
import {PiCaretLeft, PiCaretRight} from 'react-icons/pi';
import ReactPaginate from 'react-paginate';

interface CommonPaginationProps {
  onPageChange: (selectedPage: number) => void;
  pageCount: number;
  activePage: number;
  className?: string;
  totalCount?: number;
}

const CommonPagination: React.FC<CommonPaginationProps> = ({
  onPageChange,
  pageCount,
  activePage,
  className = '',
}) => {
  return (
    <ReactPaginate
      previousLabel={<PiCaretLeft size={20} />}
      nextLabel={<PiCaretRight size={20} />}
      breakLabel="..."
      breakClassName="page-item border border-amber-600 text-amber-600 rounded-xl hover:bg-amber-600 hover:!text-black p-2 w-10 h-10 flex items-center justify-center"
      breakLinkClassName="page-link"
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={e => onPageChange(e.selected + 1)}
      containerClassName={`pagination ${className}`}
      pageClassName="page-item border border-amber-600 text-amber-600 rounded-xl hover:bg-amber-600 hover:!text-black p-2 w-10 h-10 flex items-center justify-center text-sm cursor-pointer"
      pageLinkClassName="page-link"
      previousClassName="page-item border border-amber-600 text-amber-600 rounded-xl hover:bg-amber-600 hover:!text-black p-2 w-10 h-10 flex items-center justify-center cursor-pointer"
      previousLinkClassName="page-link"
      nextClassName="page-item border border-amber-600 text-amber-600 rounded-xl hover:bg-amber-600 hover:!text-black p-2 w-10 h-10 flex items-center justify-center cursor-pointer"
      nextLinkClassName="page-link"
      activeClassName="active bg-amber-600 !text-black"
      forcePage={activePage - 1}
    />
  );
};

export default CommonPagination;
