/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {walletBg, masterCardLogo} from '../../assets/images';
import {
  PiArrowUUpLeft,
  PiCalendar,
  PiCalendarDots,
  PiClockLight,
  PiCopySimple,
  PiLightning,
  PiPlus,
  PiTrash,
} from 'react-icons/pi';

const OrderDetails: React.FC = () => {
  const {state} = useLocation();
  const getZujieStatus = (status: number | undefined) => {
    switch (status) {
      case 0:
        return 'Not Completed';
      case 1:
        return 'Renting';
      case 2:
        return 'Canceled';
      case 3:
        return 'Returned';
      case 4:
        return 'Fault occurred';
      case 5:
        return 'Overtime';
      default:
        return null;
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return formattedTime;
  };

  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-6">
              <Link
                to="/order-list"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <h4 className="text-2xl">Order Details</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-6">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="bg-gray-50 rounded-lg px-5 py-5 mb-[30px]">
              <div className="flex flex-wrap items-start justify-between gap-4">
                <div className="flex-none">
                  <h6 className="text-lg mb-3">Order No.</h6>
                  <div className="flex items-center gap-2">
                    <PiCopySimple className="text-lg flex-none text-amber-600 cursor-pointer" />
                    <span>{state?.pOrderid}</span>
                  </div>
                </div>
                <div className="flex-none">
                  <span className="inline-block text-center px-[14px] py-[6px] leading-tight text-sm rounded-[5px] min-w-[88px] bg-gray-950 text-white">
                    {getZujieStatus(state?.pZujie)}{' '}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-6 grid-rows-2 gap-3 pb-5 mb-5 border-b border-gray-medium">
              <div className="col-span-4">
                <p>Duration Used</p>
              </div>
              <div className="col-span-2">
                <h6 className="text-lg text-end">{state.pUseMins} Min</h6>
              </div>
              <div className="col-span-4">
                <p>Total Amount</p>
              </div>
              <div className="col-span-2">
                <h6 className="text-lg text-end">
                  {state?.pCurrencyName} {state?.pjiesuan}
                </h6>
              </div>
            </div>
            <div className="relative flex flex-col gap-8">
              <div className="ps-6 relative">
                <PiLightning className="text-lg absolute text-lg top-1 left-0" />
                <h6 className="text-lg mb-2">Venue Rented</h6>
                <p className="mb-[10px]">{state?.pShopName}</p>
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-2 w-32">
                    <PiCalendarDots className="text-lg" />
                    <span>{formatDate(state?.pGhtime)}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <PiClockLight className="text-lg" />
                    <span>{formatTime(state?.pGhtime)}</span>
                  </div>
                </div>
              </div>
              <div className="ps-6 relative">
                <PiArrowUUpLeft className="text-lg absolute text-lg top-1 left-0" />
                <h6 className="text-lg mb-2">Venue Returned</h6>
                <p className="mb-[10px]">{state?.pShopName}</p>
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-2 w-32">
                    <PiCalendarDots className="text-lg" />
                    <span>{formatDate(state?.pJiesuantime)}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <PiClockLight className="text-lg" />
                    <span>{formatTime(state?.pJiesuantime)}</span>
                  </div>
                </div>
              </div>
              <div className="absolute top-9 start-2 w-0 h-20 border border-dashed border-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
