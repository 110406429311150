import axios from 'axios';

const REACT_APP_API_BASE_URL = {
  dev: 'https://degrees-hits-residents-magnificent.trycloudflare.com',
  stag: 'https://staging.revivesharing.com/api',
  prod: '',
};

export const API_BASE_URL = REACT_APP_API_BASE_URL.stag;

// Create an Axios instance with base configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Base URL for your API
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor to modify or log requests if needed
axiosInstance.interceptors.request.use(
  config => {
    // Add custom logic before request is sent, e.g., adding auth tokens
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// Add response interceptor to handle responses or errors globally
axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Handle response errors here, e.g., redirect on 401 status code
    return Promise.reject(error);
  },
);

// API request methods

// GET request
export const fetchData = async (endpoint: string, params?: any) => {
  try {
    const response = await axiosInstance.get(endpoint, {params});
    return response.data;
  } catch (error) {
    // You can add custom error handling here
    throw error;
  }
};

// POST request
export const postData = async (endpoint: string, data: any) => {
  try {
    const response = await axiosInstance.post(endpoint, data);
    return response.data;
  } catch (error) {
    // You can add custom error handling here
    throw error;
  }
};

// PUT request
export const updateData = async (endpoint: string, data: any) => {
  try {
    const response = await axiosInstance.put(endpoint, data);
    return response.data;
  } catch (error) {
    // You can add custom error handling here
    throw error;
  }
};

// DELETE request
export const deleteData = async (endpoint: string) => {
  try {
    const response = await axiosInstance.delete(endpoint);
    return response.data;
  } catch (error) {
    // You can add custom error handling here
    throw error;
  }
};

// Export the axiosInstance for custom requests if needed
export default axiosInstance;
