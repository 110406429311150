import React, {useEffect, useRef, useState} from 'react';
import OtpInput from 'react-otp-input';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {
  letterT,
  scanCode,
  scanCodeWhite,
  scannerBlack,
} from '../../assets/images/index';
import {IDetectedBarcode} from '@yudiel/react-qr-scanner';
import {QrScanner} from '../../components';
import axios from 'axios';
import {QRCODESCAN} from '../../services/apiStaticData';
import Swal from 'sweetalert2';

const Scan = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>('');
  const [activeTab, setActiveTab] = useState<'qr' | 'otp'>('qr');
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [typeInQR, setTypeInQR] = useState<string | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [videoAllowed, setVideoAllowed] = useState<boolean | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isMount, setIsMount] = useState<boolean>(false);


  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
      setVideoAllowed(null);
    }
  };

  useEffect(() => {
    if (id) {
      fetchQRCodeData(id);
    } else {
      setIsMount(true);
    }
  }, [id]);

  useEffect(() => {
    if (activeTab === 'qr' && isMount) {
      requestCameraAccess();
    } else {
      stopCamera();
    }
    return () => {
      stopCamera();
    };
  }, [activeTab, isMount]);

  const requestCameraAccess = async () => {
    try {
      stopCamera();
      const constraints: MediaStreamConstraints = {
        video: {
          facingMode: {ideal: 'environment'},
          width: {ideal: 1280},
          height: {ideal: 720},
        },
      };
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setVideoAllowed(true);
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error: any) {
      setVideoAllowed(false);

      if (error.name === 'OverconstrainedError') {
        console.error(
          'Device does not support requested camera settings:',
          error,
        );
      } else if (error.name === 'NotAllowedError') {
        console.error('Camera access denied. Please enable permissions.');
      } else {
        console.error('Error accessing camera:', error);
      }
    }
  };

  // Function to safely extract QR code from URL
  const extractQRCode = (urlString: string): string | null => {
    try {
      const fragment = urlString.split('=')[1];
      return fragment ?? null;
    } catch (error) {
      console.error('Error extracting QR code:', error);
      return null;
    }
  };

  const fetchQRCodeData = async (qrCode: string) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${QRCODESCAN}?id=${qrCode}`,
      );
      if (response.status === 200) {
        localStorage.setItem('QRID', qrCode);
        localStorage.setItem('cabinetID', response.data?.data?.stationId);
        if (response.data?.data?.stationId) {
          navigate(`/station-detail?qr=${response.data?.data?.stationId}`);
        } else {
          navigate('/');
        }
      } else {
        Swal.fire({
          title: 'Error',
          text: response?.data?.error?.message,
          icon: 'error',
        });
        setIsMount(true);
        return null;
      }
    } catch (error: any) {
      setIsMount(true);
      Swal.fire({
        title: 'Error',
        text: error?.response?.data?.error?.message || error.msg,
        icon: 'error',
      });
      return null;
    }
  };

  const handleScan = async (detectedCodes: IDetectedBarcode[]) => {
    if (detectedCodes?.[0]?.rawValue) {
      const qrCode = extractQRCode(detectedCodes[0].rawValue);
      if (qrCode) {
        await fetchQRCodeData(qrCode);
      }
    }
  };

  const handleError = (error: any) => {
    console.error('QR Scan Error:', error);
  };

  // Function to check camera availability
  const checkCamera = async () => {
    try {
      const devices = await navigator?.mediaDevices?.enumerateDevices();
      const videoInputDevices = devices?.filter(
        device => device?.kind === 'videoinput',
      );
      if (videoInputDevices.length > 0) {
        setIsCameraActive(true);
        setIsScannerOpen(true);
      } else {
        setIsScannerOpen(false);
        Swal.fire({
          icon: 'error',
          text: 'No camera available on this device.',
        });
      }
    } catch (error) {
      console.error('Error checking camera devices:', error);
      setIsScannerOpen(false);
      Swal.fire({
        icon: 'error',
        text: 'Unable to access camera devices.',
      });
    }
  };

  useEffect(() => {
    if (!isScannerOpen || !isCameraActive) {
      checkCamera();
    }
  }, [isScannerOpen, isCameraActive]);

  const handleOtpSubmit = async () => {
    if (typeInQR) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${QRCODESCAN}?id=${typeInQR}`,
        );
        if (response.status === 200) {
          navigate(`/station-detail?qr=${response.data?.data?.stationId}`);
          localStorage.setItem('cabinetID', response.data?.data?.stationId);
          localStorage.setItem('QRID', typeInQR);
          setOtp('');
          setTypeInQR('');
        } else {
          Swal.fire({
            icon: 'error',
            text: response?.data?.error?.message,
          }).then(() => {
            setOtp('');
            setTypeInQR('');
          });
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          text:
            error?.response?.data?.error?.message ||
            error.message ||
            'Failed to verify the OTP.',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Invalid OTP. Please enter a valid code.',
      });
    }
  };

  return (
    <div className="xl:pt-[100px] sm:pt-[64px] pt-6">
      <div className="container">
        <div className="mb-[30px] relative z-[99999]">
          <Link
            to="/"
            className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
            <SlArrowLeft />
          </Link>
        </div>

        <div className="max-w-[600px] mx-auto">
          <div className="flex justify-center sm:gap-4 gap-3 w-full mb-11 relative z-[99999]">
            <button
              className={`sm:px-[34px] px-[30px] w-max flex gap-2.5 justify-center items-center ${
                activeTab === 'qr' ? 'btn-primary' : 'btn-secondary'
              }`}
              onClick={() => setActiveTab('qr')}>
              <img alt="Scanner icon" src={scannerBlack} />
              QR Scanner
            </button>
            <button
              onClick={() => {
                setActiveTab('otp');
                stopCamera();
              }}
              className={`sm:px-[34px] px-[30px] w-max flex gap-2.5 justify-center items-center ${
                activeTab === 'otp' ? 'btn-primary' : 'btn-secondary'
              }`}>
              <img alt="Letter icon" src={letterT} />
              OTP Screen
            </button>
          </div>

          {activeTab === 'qr' && (
            <div className="flex justify-center items-center">
              {videoAllowed === null ? (
                <p>Loading....</p>
              ) : videoAllowed ? (
                <>
                  <div className="flex gap-4 md:mb-[60px] mb-[30px] relative z-[99999]">
                    <div>
                      <div className="bg-white/15 p-2.5 w-max rounded-lg mb-2.5">
                        <img
                          alt="Scan Code"
                          src={scanCodeWhite}
                          className="sm:w-[50px] w-7"
                        />
                      </div>
                      <p className="sm:text-xl text-sm text-white">123456</p>
                    </div>
                    <p className="sm:mt-2.5 text-white">
                      Scan the code on the front of the Revive station
                    </p>
                  </div>
                  <div>
                    <video
                      ref={videoRef}
                      muted
                      playsInline
                      style={{display: 'none'}}
                    />
                    <QrScanner onScan={handleScan} onError={handleError} />
                  </div>
                </>
              ) : (
                <p className="text-red-500">
                  Camera access needed. Please enable permissions in your
                  settings.
                </p>
              )}
            </div>
          )}

          {activeTab === 'otp' && (
            <>
              <div className="flex gap-4 md:mb-[60px] mb-[30px]">
                <div>
                  <div className="bg-black/15 p-2.5 w-max rounded-lg mb-2.5">
                    <img
                      alt="Scan Code"
                      src={scanCode}
                      className="sm:w-[50px] w-7"
                    />
                  </div>
                  <p className="sm:text-xl text-sm">123456</p>
                </div>
                <p className="sm:mt-2.5">
                  Type in the number displayed under the station code
                </p>
              </div>
              <OtpInput
                value={otp}
                onChange={value => {
                  setOtp(value);
                  setTypeInQR(value);
                }}
                numInputs={8}
                containerStyle={{
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: '6px',
                }}
                renderInput={props => (
                  <input
                    {...props}
                    placeholder="0"
                    className="p-2 border border-gray-medium text-gray-950 sm:text-xl text-md font-medium rounded-lg sm:!w-[53px] !w-[33px] sm:h-14 h-[43px] flex items-center justify-center bg-gray-50 placeholder:text-gray-100 focus:border-[#FFBC07] focus-visible:outline-[#FFBC07] shadow focus:placeholder-transparent"
                  />
                )}
              />
              <button onClick={handleOtpSubmit} className="mt-10 btn-primary">
                Submit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Scan;
