/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {SlArrowLeft, SlControlStart} from 'react-icons/sl';
import {
  PiArrowUUpLeft,
  PiCalendarDots,
  PiClockLight,
  PiCopySimple,
  PiLightning,
} from 'react-icons/pi';
import {formatCurrency} from '../../utils/functionCommon';
import classNames from 'classnames'; // Import classNames
import {fetchData} from '../../services/Api';
import {ORDERLIST} from '../../services/apiStaticData';
import Swal from 'sweetalert2';

const OrderDetails: React.FC = () => {
  const navigate = useNavigate();

  const {state} = useLocation();
  interface IOrderData {
    orderId?: string;
    rentalStatus?: number;
    duration: number;
    discountApplicable?: boolean;
    discountAmount: number;
    amount: number;
    currency: string;
    rentalMerchant?: string;
    rentTime: string;
    returnMerchant?: string;
    returnTime?: string;
    freeDuration: number;
    price: number;
    priceMinute: number;
    maxPrice: number;
    discountTime: number;
  }

  const [orderData, setOrderData] = useState<IOrderData>(state);

  const [formattedDuration, setFormattedDuration] = useState<string>('');
  const [calculatedAmount, setCalculatedAmount] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);

  const formatDuration = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return [hrs, mins, secs].map(v => String(v).padStart(2, '0')).join(':');
  };

  const orderDetails = async (status?: boolean) => {
    try {
      let uir = `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${ORDERLIST}/${state?.orderId}`;

      if (status) {
        uir += `?status=${status}`;
      }

      const response = await fetchData(uir);

      if (response?.statusCode === 200) {
        if (status && orderData.rentalStatus !== response?.data?.rentalStatus) {
          orderDetails();
        } else if (!status) {
          setOrderData(response.data);
          setSubTotal(response.data?.amount + response.data?.discountAmount);
          setDiscountAmount(response.data?.discountAmount);
        }
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.data?.msg,
        }).then(() => navigate('/'));
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while getting user info.',
      }).then(() => navigate('/'));
    }
  };

  useEffect(() => {
    // If rentalStatus is not 1, don't set the interval
    if (orderData?.rentalStatus !== 1) return;

    // Fetch immediately
    orderDetails();

    // Set interval to fetch every 30 seconds
    const interval = setInterval(() => {
      orderDetails(true);
    }, 5000);

    // Cleanup interval on unmount or rentalStatus change
    return () => clearInterval(interval);
  }, [orderData?.rentalStatus]);

  useEffect(() => {
    if (orderData?.rentalStatus !== 1) return;

    let elapsedSeconds = Math.round(orderData.duration); // Convert to seconds
    const freeDuration = (orderData?.freeDuration || 0) * 60; // Convert minutes to seconds
    const incrementInterval = orderData?.priceMinute; // Convert minutes to seconds
    const initialCharge = orderData?.price; // Base price
    const maxCharge = orderData?.maxPrice; // Maximum charge
    const discountTime = orderData?.discountTime || 0; // Discount time

    const updateDuration = () => {
      setFormattedDuration(formatDuration(elapsedSeconds));

      // Calculate billable duration (time exceeding free duration)
      const billableSeconds = Math.max(elapsedSeconds - freeDuration, 0);

      if (billableSeconds === 0) {
        setCalculatedAmount(0); // No charge within free duration
        setSubTotal(0); // No charge within free duration
      } else {
        const totalAmount =
          Math.ceil(elapsedSeconds / 60 / incrementInterval) * initialCharge; // Calculate total charge

        let discountAmount = 0;

        let calculatedAmount = Math.min(totalAmount, maxCharge);

        if (orderData?.discountApplicable) {
          discountAmount = (initialCharge / incrementInterval) * discountTime; // Calculate discount amount
          calculatedAmount = Math.max(totalAmount - discountAmount, 0); // Apply discount
          setDiscountAmount(discountAmount);
        }

        setCalculatedAmount(calculatedAmount); // Cap at `maxPrice`

        setSubTotal(calculatedAmount + discountAmount);
      }

      elapsedSeconds++; // Increment elapsed time
    };

    const interval = setInterval(updateDuration, 1000);
    updateDuration(); // Initial call

    return () => clearInterval(interval);
  }, [
    orderData?.duration,
    orderData?.rentalStatus,
  ]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(state.orderId);
    // .then(
    //   () => {
    //     alert("Order ID copied to clipboard!");
    //   }
    // );
  };

  const getZujieStatus = (status: number | undefined) => {
    switch (status) {
      case 0:
        return 'Not Completed';
      case 1:
        return 'On Rent';
      case 2:
        return 'Canceled';
      case 3:
        return 'Returned';
      case 4:
        return 'Fault occurred';
      case 5:
        return 'Overtime';
      default:
        return null;
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return formattedTime;
  };

  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-6">
              <Link
                to="/order-list"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <h4 className="text-2xl">Order Details</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-6">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="bg-gray-50 rounded-lg px-5 py-5 mb-[30px]">
              <div className="flex flex-wrap items-start justify-between gap-4">
                <div className="flex-none">
                  <h6 className="text-lg mb-3">Order No.</h6>
                  <div className="flex items-center gap-2">
                    <PiCopySimple
                      onClick={copyToClipboard}
                      className="text-lg flex-none text-amber-600 cursor-pointer"
                    />
                    <span>{orderData?.orderId}</span>
                  </div>
                </div>
                <div className="flex-none">
                  <span
                    className={classNames(
                      'inline-block text-center px-[14px] py-[6px] leading-tight text-sm rounded-[5px] min-w-[88px]',
                      {
                        'bg-amber-600 text-gray-950':
                          orderData?.rentalStatus === 1,
                        'bg-gray-950 text-white': orderData?.rentalStatus !== 1,
                      },
                    )}>
                    {getZujieStatus(orderData?.rentalStatus)}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-6 grid-rows-2 gap-3 pb-5 mb-5 border-b border-gray-medium">
              <div className="col-span-4">
                <p>Duration Used</p>
              </div>
              <div className="col-span-2">
                <h6 className="text-lg text-end">
                  {orderData?.rentalStatus === 1
                    ? formattedDuration
                    : formatDuration(orderData?.duration)}
                </h6>
              </div>
              {orderData?.discountApplicable && (
                <>
                  <div className="col-span-4">
                    <p>Sub Total</p>
                  </div>
                  <div className="col-span-2">
                    <h6 className="text-lg text-end">
                      {formatCurrency(subTotal, orderData?.currency)}
                    </h6>
                  </div>

                  <div className="col-span-6 border-b border-gray-medium"></div>
                  <div className="col-span-4">
                    <p>Discount</p>
                  </div>
                  <div className="col-span-2">
                    <h6 className="text-lg text-end text-success">
                      - {formatCurrency(discountAmount, orderData?.currency)}
                    </h6>
                  </div>
                </>
              )}
              <div className="col-span-4">
                <p>Total Amount</p>
              </div>
              <div className="col-span-2">
                {/* <h6 className="text-lg text-end">
                  {formatCurrency(calculatedAmount, orderData?.currency)}
                </h6> */}
                <h6 className="text-lg text-end">
                  {orderData?.rentalStatus === 1
                    ? formatCurrency(calculatedAmount, orderData?.currency)
                    : formatCurrency(orderData?.amount, orderData?.currency)}
                </h6>
              </div>
            </div>
            <div className="relative flex flex-col gap-8">
              <div className="ps-6 relative">
                <PiLightning className="text-lg absolute text-lg top-1 left-0" />
                <h6 className="text-lg mb-2">Venue Rented</h6>
                <p className="mb-[10px]">{orderData?.rentalMerchant}</p>
                <div className="flex items-center gap-3">
                  <div className="flex items-center gap-2 w-32">
                    <PiCalendarDots className="text-lg" />
                    <span>{formatDate(orderData?.rentTime)}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <PiClockLight className="text-lg" />
                    <span>{formatTime(orderData?.rentTime)}</span>
                  </div>
                </div>
              </div>
              <div className="ps-6 relative">
                <PiArrowUUpLeft className="text-lg absolute text-lg top-1 left-0" />
                <h6 className="text-lg mb-2">Venue Returned</h6>
                <p className="mb-[10px]">{orderData?.returnMerchant}</p>
                <div className="flex items-center gap-3">
                  {orderData?.returnTime && orderData?.rentalStatus !== 5 ? (
                    <>
                      <div className="flex items-center gap-2 w-32">
                        <PiCalendarDots className="text-lg" />
                        <span>{formatDate(orderData?.returnTime)}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <PiClockLight className="text-lg" />
                        <span>{formatTime(orderData?.returnTime)}</span>
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="absolute top-9 start-2 w-0 h-20 border border-dashed border-gray-300"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
