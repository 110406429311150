import loginBanner from './login-banner.png';
import otpBanner from './otp-banner.svg';
import reviveStationImg from './revive-station-img.png';
import reviveMarkerIcon from './revive_marker_icon.png';
import locationIcon from './locationIcon.png';
import leSailFish from './lesailfish.jpg';
import theTeamBuilding from './theteambuilding.jpg';
import jeuDeBar from './jeudebar.jpg';
import kapSalonBroers from './kapsalonbroers.jpg';
import uvsvClub from './uvsvclub.jpg';
import cafeNar from './cafeNar.jpg';
import gerrie from './gerrie.png';
import greenClock from './green-clock.png';
import greenMarker from './green-marker.png';
import greyExplore from './grey-explore.png';
import navigateLocation from './navigate-location.png';
import scanner from './icon/scanner.svg';
import apple from './icon/apple-icon.svg';
import email from './icon/email-icon.svg';
import flagDownArrow from './icon/flag-down-arrow.svg';
import google from './icon/google-icon.svg';
import noneRotateLeft from './icon/none-rotate-left.svg';
import reviveRound from './icon/revive-round.png';
import scannerBlack from './icon/scanner-black.svg';
import letterT from './icon/letter-t.svg';
import scanCode from './icon/scan-code.svg';
import scanCodeWhite from './icon/scan-code-white.svg';
import userProfile from './user-profile.png';
import failed from './icon/failed.png';
import success from './icon/success.png';
import profileBg from './profile-bg.svg';
import walletBg from './wallet-bg.svg';
import masterCardLogo from './master-card-logo.svg';
import agreementVector from './agreement-vector.svg';
import termsConditionsVector from './terms-conditions-vector.svg';
import privacyPolicyVector from './privacy-policy-vector.svg';
import mobileWithPowerbank from './mobile-with-powerbank.png';
import questionAnswerVector from './question-answer-vector.svg';
import acceptTermsCondition from './accept-terms-condition.svg';
import playStore from './play-store.svg';
import appStore from './app-store.svg';
import eightSlotMachineLeft from './8-slot-machine-left.png';
import locationPinListnear from '../images/listnearPin.png';

export {
  loginBanner,
  otpBanner,
  reviveStationImg,
  scanner,
  apple,
  email,
  flagDownArrow,
  google,
  noneRotateLeft,
  reviveMarkerIcon,
  locationIcon,
  leSailFish,
  theTeamBuilding,
  jeuDeBar,
  kapSalonBroers,
  uvsvClub,
  greenClock,
  greenMarker,
  greyExplore,
  navigateLocation,
  cafeNar,
  gerrie,
  reviveRound,
  scannerBlack,
  letterT,
  scanCode,
  scanCodeWhite,
  userProfile,
  failed,
  success,
  profileBg,
  walletBg,
  masterCardLogo,
  agreementVector,
  termsConditionsVector,
  privacyPolicyVector,
  mobileWithPowerbank,
  questionAnswerVector,
  acceptTermsCondition,
  playStore,
  appStore,
  eightSlotMachineLeft,
  locationPinListnear,
};
