import React from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {termsConditionsVector} from '../../assets/images';

const TermsCondition: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="flex items-center gap-6">
            <Link
              to="/station-detail"
              className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
              <SlArrowLeft />
            </Link>
            <h4 className="text-2xl">Terms and Conditions</h4>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          <div className="hero-image mb-[30px]">
            <img
              className="mb-6 max-w-[80%] sm:max-w-[484px] w-full mx-auto"
              alt="Terms and condition"
              src={termsConditionsVector}
            />
          </div>
          <div className="terms-and-condition-content">
            <h5>Effective Date: 2022-10-04.</h5>
            <p>
              PLEASE READ THIS AGREEMENT CAREFULLY. IT SETS FORTH THE LEGALLY
              BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE SERVICES.
            </p>
            <p>
              In consideration of Your use of any of the Services (defined
              below) provided by Mobile Power Solutions SRL or any other parent
              company, subsidiary and affiliated entity (« Revive ») doing
              business as « Mobile Power Solutions » or « Revive » or any other
              commercial name that may be used from time to time (« MPS », « We
              », « Us », « Our »), Revive requires that You (« The Consumer », «
              User », « You », or « Your ») (acting for all of User's family,
              heirs, agents, affiliates, representatives, successors, and
              assigns) agree to all terms and conditions in this agreement («
              Agreement »).
            </p>
            <p>
              By accessing and/or using our Services, you agree to be bound by
              this Agreement. If you do not agree to this Agreement and the
              conditions of use stated herein, do not use the Services.
            </p>
            <p>The services (the « Services ») provided by Revive includes:</p>
            <ol>
              <li>Revive mobile application and related website,</li>
              <li>
                Revive portable power batteries (« Battery » or « Batteries »)
                and,
              </li>
              <li>
                All other related equipment, maintenance, personnel, services,
                applications, websites, and information provided or made
                available by Revive.
              </li>
            </ol>
            <p>
              This Agreement, together with all updates, supplements, additional
              terms, and all of Our rules and policies collectively constitute
              the only Agreement covering the Services between You and Us, at
              the exclusion of any other provisions.
            </p>
            <h4> GENERAL RENTAL AND USE OF THE BATTERIES</h4>
            <h5>1.1 The Consumer is sole user</h5>
            <p>
              Revive and the Consumer are the only parties to this Agreement.
              The User is the sole renter and is solely responsible for
              compliance with all terms and conditions contained herein. You
              understand that when You rent a Power Battery from the location,
              the Battery must be used only by You. If You allow others to use a
              Battery that You have activated from the location, such use shall
              be made in compliance with these terms and conditions and You are
              solely liable for enforcing such conditions on the person you
              allowed to use the Battery.
            </p>
            <h5>
              1.2 The Battery shall remain the exclusive property of Revive
            </h5>
            <p>
              Without prejudice to Article 2.3 below, User agrees that the
              Battery and any Revive equipment attached thereto, at all times,
              remain the exclusive property of Revive. You must not dismantle or
              otherwise modify, repair or deface a Battery, any part of a
              Battery, or other Revive equipment in any way. You must not write
              on, peel, or otherwise modify or deface any sticker on a Battery
              in any way. You must not use a Battery, or other Revive equipment,
              for any advertising or other commercial purpose without the
              express written permission of Revive The Services may only be used
              for the intended purpose for which such Services are being made
              available.
            </p>
            <p>
              You must not tamper with, attempt to gain unauthorized access to,
              or otherwise use the Services, the Batteries or the application
              other than as specified in this Agreement.
            </p>
            <p>
              For the execution of this Agreement and, in particular, article
              1.3 of the present general terms and conditions, You acknowledge
              that the interdiction of tampering includes, without being limited
              to, any and all drawings, punctures, scratches, bumps, blows or
              alterations to the Battery rented under this Agreement.
            </p>
            <h5>1.3 Battery operating Hours and Battery availability</h5>
            <p>
              User agrees and acknowledges that the Batteries must be rented
              within the maximum rental time limits set forth below. The number
              of Batteries is limited and Battery availability is never
              guaranteed.
            </p>
            <p>
              The Batteries are electric power batteries that require periodic
              charging in order to operate. User agrees to use the Batteries
              safely and prudently in light of it being an electric power
              battery and all of the limitations and requirements associated
              therewith.
            </p>
            <p>
              The level of charging power in the Battery at the time User
              initiates the rental or operation of the Battery is not guaranteed
              and may vary with each rental use.
            </p>
            <ul>
              <li>
                The level of charging power remaining in the Battery will
                decrease with use of the Battery, and that as the level of
                charge power of the Battery decreases, the operational
                capabilities of the Battery may decrease (or cease in their
                entirety).
              </li>
              <li>
                The level of charging power in the Battery at the time User
                initiates the rental or operation of the Battery is not
                guaranteed and may vary with each rental use.
              </li>
              <li>
                The rate of loss of charging power during the use of the Battery
                is not guaranteed and will vary based on the Battery,
                operational conditions, weather conditions, and other factors.
              </li>
              <li>
                It is User's responsibility to check the level of charge power
                of the Battery and to ensure that it is adequate before
                initiating operation of the Battery.
              </li>
              <li>
                The time that User may operate the Battery before it loses
                charging power is never guaranteed.
              </li>
              <li>
                The Battery may run out of charging power and cease to operate
                at any time during User's rental of the Battery, including
                before reaching User's desired level of reloading.
              </li>
              <li>
                If the Battery runs out of charging power during a rental, User
                shall return the Battery in compliance with all terms of this
                Agreement. If an accident occurs because the Battery ran out of
                power, Revive shall not be held liable.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
