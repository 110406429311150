import React, {useEffect, useRef, useState} from 'react';
import OtpInput from 'react-otp-input';
import {Link, useNavigate} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {
  letterT,
  scanCode,
  scanCodeWhite,
  scannerBlack,
} from '../../assets/images/index';
import {IDetectedBarcode} from '@yudiel/react-qr-scanner';
import {QrScanner} from '../../components';
import axios from 'axios';
import {QRCODESCAN} from '../../services/apiStaticData';
import Swal from 'sweetalert2';

const Scan = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>('');
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const mediaElementRef = useRef(null);
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [typeInQR, setTypeInQR] = useState<string | null>(null);
  const tabs = ['Scan', 'Type in'];

  // Function to safely extract QR code from URL
  const extractQRCode = (urlString: string): string | null => {
    try {
      const fragment = urlString.split('=')[1];
      return fragment ?? null;
    } catch (error) {
      console.error('Error extracting QR code:', error);
      return null;
    }
  };

  // Function to handle QR code scan
  const handleScan = async (detectedCodes: IDetectedBarcode[]) => {
    if (detectedCodes?.[0]?.rawValue) {
      const qrCode = extractQRCode(detectedCodes[0].rawValue);
      if (qrCode) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_HOST}${QRCODESCAN}?id=${qrCode}`,
          );
          if (response.status === 200 && response.data.code === 0) {
            navigate(`/station-detail?qr=${response?.data?.cabinetID}`);
            localStorage.setItem('QRID', qrCode);
            localStorage.setItem('cabinetID', response?.data?.cabinetID);
          } else {
            Swal.fire({
              title: 'Error',
              text: response.data.msg,
              icon: 'error',
            }).then(() => {
              navigate('/');
            });
          }
        } catch (error: any) {
          Swal.fire({
            title: 'Error',
            text: error.msg,
            icon: 'error',
          }).then(() => {
            navigate('/');
          });
        }
      }
    }
  };

  const handleError = (error: any) => {
    console.error('QR Scan Error:', error);
  };

  // Function to check camera availability
  const checkCamera = async () => {
    try {
      const devices = await navigator?.mediaDevices?.enumerateDevices();
      const videoInputDevices = devices?.filter(
        device => device?.kind === 'videoinput',
      );
      if (videoInputDevices.length > 0) {
        setIsCameraActive(true);
        setIsScannerOpen(true);
      } else {
        setIsScannerOpen(false);
        Swal.fire({
          icon: 'error',
          text: 'No camera available on this device.',
        });
      }
    } catch (error) {
      console.error('Error checking camera devices:', error);
      setIsScannerOpen(false);
      Swal.fire({
        icon: 'error',
        text: 'Unable to access camera devices.',
      });
    }
  };

  useEffect(() => {
    if (!isScannerOpen || !isCameraActive) {
      checkCamera();
    }
  }, [isScannerOpen, isCameraActive]);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
    setIsCameraActive(index === 0);
  };

  const handleOtpSubmit = async () => {
    if (typeInQR) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}${QRCODESCAN}?id=${typeInQR}`,
        );
        if (response.status === 200) {
          if (response?.data?.code === 0) {
            navigate(`/station-detail?qr=${response.data?.cabinetID}`);
            localStorage.setItem('cabinetID', response.data?.cabinetID);
            localStorage.setItem('QRID', typeInQR);
            setOtp('');
            setTypeInQR('');
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.msg,
            }).then(() => {
              setOtp('');
              setTypeInQR('');
            });
          }
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          text: error.message || 'Failed to verify the OTP.',
        });
      }
    } else {
      Swal.fire({
        icon: 'error',
        text: 'Invalid OTP. Please enter a valid code.',
      });
    }
  };

  return (
    <div className="xl:pt-[100px] sm:pt-[64px] pt-6">
      <div className="container">
        <div className="mb-[30px] relative z-[99999]">
          <Link
            to="/"
            className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
            <SlArrowLeft />
          </Link>
        </div>
        <div className="max-w-[600px] mx-auto">
          <div className="flex justify-center sm:gap-4 gap-3 w-full mb-11 relative z-[99999]">
            {tabs.map((tab, index) => (
              <button
                key={index}
                className={`sm:px-[34px] px-[30px] w-max flex gap-2.5 justify-center items-center ${
                  activeTab === index ? 'btn-primary' : 'btn-secondary'
                }`}
                onClick={() => handleTabChange(index)}>
                <img
                  alt="Scanner icon"
                  src={index === 0 ? scannerBlack : letterT}
                />
                {tab}
              </button>
            ))}
          </div>
          {activeTab === 0 && (
            <>
              <div className="flex gap-4 md:mb-[60px] mb-[30px] relative z-[99999]">
                <div>
                  <div className="bg-white/15 p-2.5 w-max rounded-lg mb-2.5">
                    <img
                      alt="Scan Code"
                      src={scanCodeWhite}
                      className="sm:w-[50px] w-7"
                    />
                  </div>
                  <p className="sm:text-xl text-sm text-white">123456</p>
                </div>
                <p className="sm:mt-2.5 text-white">
                  Scan the code on the front of the Revive station
                </p>
              </div>
              <div>
                <video
                  ref={mediaElementRef}
                  muted
                  playsInline
                  style={{display: 'none'}}
                />
                <QrScanner onScan={handleScan} onError={handleError} />
              </div>
            </>
          )}
          {activeTab === 1 && (
            <>
              <div className="flex gap-4 md:mb-[60px] mb-[30px]">
                <div>
                  <div className="bg-black/15 p-2.5 w-max rounded-lg mb-2.5">
                    <img
                      alt="Scan Code"
                      src={scanCode}
                      className="sm:w-[50px] w-7"
                    />
                  </div>
                  <p className="sm:text-xl text-sm">123456</p>
                </div>
                <p className="sm:mt-2.5">
                  Type in the number displayed under the station code
                </p>
              </div>
              <OtpInput
                value={otp}
                onChange={value => {
                  setOtp(value);
                  setTypeInQR(value);
                }}
                numInputs={8}
                containerStyle={{
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: '6px',
                }}
                renderInput={props => (
                  <input
                    {...props}
                    placeholder="0"
                    className="p-2 border border-gray-medium text-gray-950 sm:text-xl text-md font-medium rounded-lg sm:!w-[53px] !w-[33px] sm:h-14 h-[43px] flex items-center justify-center bg-gray-50 placeholder:text-gray-100 focus:border-[#FFBC07] focus-visible:outline-[#FFBC07] shadow focus:placeholder-transparent"
                  />
                )}
              />
              <button onClick={handleOtpSubmit} className="mt-10 btn-primary">
                Submit OTP
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Scan;
