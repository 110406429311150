import React from 'react';
import {Link} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';

const UpdateName: React.FC = () => {
  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-6">
              <Link
                to="/settings/user-account"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <h4 className="text-2xl">User Account</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="mb-[30px]">
              <h5 className="text-xl mb-3">Name</h5>
              <p>
                This is the name you would like other people to use when
                refering to you.
              </p>
            </div>
            <form action="" className="flex flex-col gap-5 mb-[30px]">
              <div>
                <label className="mb-2 inline-block" htmlFor="first-name">
                  First Name
                </label>
                <input
                  className="form-input"
                  value="Sarah"
                  id="first-name"
                  name="first-name"
                  type="text"
                  placeholder="First Name"
                />
              </div>
              <div>
                <label className="mb-2 inline-block" htmlFor="last-name">
                  Last Name
                </label>
                <input
                  className="form-input"
                  value="Saman"
                  id="last-name"
                  name="last-name"
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="py-7 lg:py-0 lg:relative fixed left-0 bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
          <div className="container">
            <div className="max-w-[900px] mx-auto w-full">
              <div className="lg:max-w-[362px]">
                <button className="btn-primary flex items-center justify-center gap-3 px-4">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateName;
