/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {SlArrowLeft} from 'react-icons/sl';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';

const UpdateName: React.FC = () => {
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().notRequired(),
  });
  const navigate = useNavigate();
  const userLoginToken = localStorage.getItem('reviveToken');
  const location = useLocation();
  const {firstName, lastName} = location.state || {};
  const {
    handleSubmit,
    formState: {errors},
    register,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
    },
  });

  const onUserDataSubmit = async (data: any) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
    };
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}/user`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userLoginToken}`,
          },
        },
      );
      const existingUserInfo = localStorage.getItem('userInfo');

      if (existingUserInfo) {
        const userInfo = JSON.parse(existingUserInfo);
        const updatedUserInfo = {
          ...userInfo,
          firstName: data.firstName,
          lastName: data.lastName,
        };
        localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));
      }
      navigate('/settings/user-account');
    } catch (error: string | any) {
      Swal.fire({
        icon: 'error',
        text: error?.response?.data?.error?.message || error.message,
      });
    }
  };

  return (
    <div className="min-h-full">
      <div className="bg-white 2xl:pt-[100px] sm:pt-8 pt-6 pb-4 mb-4 sticky top-0 z-10">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="flex items-center gap-6">
              <Link
                to="/settings/user-account"
                className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                <SlArrowLeft />
              </Link>
              <h4 className="text-2xl">User Account</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pb-10 pb-[114px]">
        <div className="container">
          <div className="max-w-[900px] mx-auto w-full">
            <div className="mb-[30px]">
              <h5 className="text-xl mb-3">Name</h5>
              <p>
                This is the name you would like other people to use when
                referring to you.
              </p>
            </div>
            <form
              className="flex flex-col gap-5 mb-[30px]"
              onSubmit={handleSubmit(onUserDataSubmit)}>
              <div>
                <label className="mb-2 inline-block" htmlFor="firstName">
                  First Name
                </label>
                <input
                  className="form-input"
                  id="firstName"
                  type="text"
                  {...register('firstName')}
                  name="firstName"
                  placeholder="First Name"
                />
                {errors?.firstName && (
                  <p
                    className="text-red-500 text-sm mt-1"
                    style={{color: 'red'}}>
                    {errors?.firstName?.message}
                  </p>
                )}
              </div>
              <div>
                <label className="mb-2 inline-block" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  className="form-input"
                  id="lastName"
                  {...register('lastName')}
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                />
                {errors?.lastName && (
                  <p
                    className="text-red-500 text-sm mt-1"
                    style={{color: 'red'}}>
                    {errors?.lastName?.message}
                  </p>
                )}
              </div>
              <div className="py-7 lg:py-0 lg:relative fixed left-0 bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
                <div className="max-w-[900px] mx-auto w-full px-6 lg:px-0">
                  <div className="lg:max-w-[362px]">
                    <button
                      type="submit"
                      className="btn-primary flex items-center justify-center gap-3 px-4">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* <div className="py-7 lg:py-0 lg:relative fixed left-0 bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
          <div className="container">
            <div className="max-w-[900px] mx-auto w-full">
              <div className="lg:max-w-[362px]">
                <button
                  type="submit"
                  className="btn-primary flex items-center justify-center gap-3 px-4">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default UpdateName;
