/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import React, {CSSProperties, useEffect, useState} from 'react';
import {PiArrowCounterClockwise, PiTreeStructure} from 'react-icons/pi';
import {Link, useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {noneRotateLeft, reviveStationImg} from '../../assets/images/index';
import {QRCODESCAN, STRIPEMULTICURANCY} from '../../services/apiStaticData';

const StationDetail: React.FC = () => {
  const navigate = useNavigate();

  const [isCheckout, setIsCheckout] = useState<boolean>(false);
  const [dynamicData, setDynamicData] = useState<any>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const QR_ID = localStorage.getItem('QRID');
  const Login_Token = localStorage.getItem('reviveToken');

  const navigateToChekout = async () => {
    if (Login_Token) {
      // navigate('/checkout');
      const formData = new FormData();
      formData.append('amount', dynamicData?.priceStrategy?.pDepositAmount);
      formData.append('paymentId', String(4));
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}${STRIPEMULTICURANCY}/${dynamicData.cabinetID}`,
          formData,
          {
            headers: {
              Token: `${Login_Token}`,
            },
          },
        );
        if (response.status === 200) {
          if (response?.data?.code === 0) {
            localStorage.setItem(
              'cabinetData',
              JSON.stringify(response.data?.data),
            );
            navigate('/checkout', {state: response?.data?.data});
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.msg,
            });
          }
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          text: error,
        });
      }
    } else {
      navigate('/login');
    }
  };

  const showCheckout = () => setIsCheckout(true);

  const hideCheckout = () => setIsCheckout(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}${QRCODESCAN}?id=${QR_ID}`,
        );
        localStorage.setItem('cabinetID', response?.data.cabinetID);
        setDynamicData(response?.data);
      } catch (error) {
        console.error('API Error:', error);
      }
    };
    fetchData();
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="min-h-full">
      <div className="xl:pt-[100px] sm:pt-[64px] pt-6 xl:pb-6 pb-[114px]">
        <div className="container">
          <div className="grid grid-cols-12 xl:gap-x-10 gap-y-[30px] xl:border-0 border border-[#D6D6D6] rounded-[20px] mb-8 xl:mb-0">
            <div className="xl:col-span-6 col-span-12 relative">
              <div className="flex align-middle gap-4 absolute top-4 xl:top-5 left-4 xl:left-5 right-4 xl:right-5">
                {/* <Link
                  to="/"
                  className="bg-white hover:bg-gray-950 hover:border-gray-50/70 hover:text-white transition ease-in-out duration-300 shadow-sm w-[50px] h-[50px] rounded-full flex items-center justify-center flex-none">
                  <SlArrowLeft />
                </Link> */}
                <span className="ml-auto font-semibold leading-7 py-[10px] px-4 bg-amber-600 text-gray-950 rounded-[12px]">
                  <span className="text-2xl">
                    {dynamicData?.currencyName}
                    {dynamicData?.priceStrategy?.pPrice}
                  </span>{' '}
                  / {dynamicData?.priceStrategy?.pPriceMinute} minutes
                </span>
              </div>
              <img
                className="w-full rounded-[20px] xl:object-left-bottom object-center object-cover xl:h-[550px] 2xl:h-[682px] h-[230px]"
                src={dynamicData?.shoplogo !== "" ? dynamicData?.shoplogo : reviveStationImg}
                alt="ReviveStation Img"
              />
            </div>

            <div className="xl:col-span-6 col-span-12 flex flex-col xl:px-0 px-4 xl:pt-[30px]">
              <h1 className="text-2xl">{dynamicData?.shopname}</h1>
              <p className="mb-6">{dynamicData?.shopAddress}</p>
              <p className="mb-5">{dynamicData?.shopContent}</p>
              <p className="mb-5 flex gap-[10px] items-center">
                <PiTreeStructure size={18} className="flex-none" /> Adapted to
                all types of mobile phone{' '}
              </p>
              <p className="mb-5 flex gap-[10px] items-center">
                {' '}
                <PiArrowCounterClockwise
                  size={18}
                  className="rotate-[40deg] flex-none"
                />
                Can be returned to any other site
              </p>
              <p className="mb-5 flex gap-[10px] items-start">
                <img
                  className="mt-1 flex-none"
                  src={noneRotateLeft}
                  alt="Arrow Rotate Left Img"
                />{' '}
                Non-return fee: in case you do not return the power bank within{' '}
                {dynamicData?.priceStrategy?.pOvertimeDay} days we will direct
                debit {dynamicData?.currencyName}
                {dynamicData?.overtimeAmount},- from your bank account
              </p>
              <div className="items-center gap-2 mb-3 mt-auto hidden xl:flex">
                <input
                  className="w-[22px] h-[22px] rounded-md checked:rounded-md accent-amber-600"
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  id="flexCheckDefault"
                />
                <label className="text-[#616161]" htmlFor="flexCheckDefault">
                  Accept{' '}
                  <Link
                    to="/privacy-policy"
                    className="font-semibold underline">
                    Privacy Policy
                  </Link>{' '}
                  and{' '}
                  <Link
                    to="/terms-and-condition"
                    className="font-semibold underline">
                    Terms and Conditions
                  </Link>
                </label>
              </div>
              <button
                onClick={() => navigateToChekout()}
                className="btn btn-primary px-8 mt-auto mt-[87px] hidden xl:block"
                disabled={!isChecked}>
                {!Login_Token ? (
                  <>
                    <span className="me-3">Pre-authorization</span>{' '}
                    {dynamicData?.priceStrategy?.pDepositAmount}{' '}
                    {dynamicData?.currency}{' '}
                  </>
                ) : (
                  <span className="me-3">Rent the power bank</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="py-7 fixed bottom-0 w-full bg-white xl:hidden inline-block shadow-3xl">
        <div className="container">
          <div className="flex items-center gap-2 mb-4">
            {/* <input className="w-[22px] h-[22px] rounded-md accent-amber-600" type="checkbox" value="" id="flexCheckDefault" /> */}
            <input
              className="w-[22px] h-[22px] rounded-md checked:rounded-md accent-amber-600"
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              id="flexCheckDefault"
            />
            <label className="text-[#616161]" htmlFor="flexCheckDefault">
              Accept{' '}
              <Link to="/privacy-policy" className="font-semibold underline">
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                to="/terms-and-condition"
                className="font-semibold underline">
                Terms and Conditions
              </Link>
            </label>
          </div>

          <button
            onClick={() => navigateToChekout()}
            className="btn btn-primary px-4"
            disabled={!isChecked}>
            {!Login_Token ? (
              <>
                <span className="me-3">Pre-authorization</span>{' '}
                {dynamicData?.priceStrategy?.pDepositAmount}{' '}
                {dynamicData?.currency}{' '}
              </>
            ) : (
              <span className="me-3">Rent the power bank</span>
            )}
          </button>
        </div>
      </div>

      {/* {isCheckout ? (
        <div style={iframeDiv} onClick={hideCheckout}>
          <iframe src="/checkout" title="Checkout" style={iframe} />
        </div>
      ) : null} */}
    </div>
  );
};

const iframeDiv: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  height: '100%',
  width: '100%',
  zIndex: 9999,
  backgroundColor: 'rgba(0,0,0,0.8)',
};

const iframe: CSSProperties = {
  border: 'none',
  borderRadius: 15,
  height: '100%',
  maxHeight: '100vh',
  width: '100%',
  maxWidth: '324px',
};

export default StationDetail;
