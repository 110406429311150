export {default as Home} from './Home';
export {default as Login} from './Login';
export {default as Otp} from './Otp';
export {default as PaymentFailure} from './PaymentFailure';
export {default as PaymentSuccess} from './PaymentSuccess';
export {default as Profile} from './Profile';
export {default as StationDetail} from './StationDetail';
export {default as Checkout} from './Checkout';
export {default as Scan} from './Scan';
export {default as OrderList} from './OrderList';
export {default as MyWallet} from './MyWallet';
export {default as UserAgreement} from './UserAgreement';
export {default as PrivacyPolicy} from './PrivacyPolicy';
export {default as TermsCondition} from './TermsCondition';
export {default as Help} from './Help';
export {default as Settings} from './Settings';
export {default as UserAccount} from './Settings/UserAccount';
export {default as UpdateName} from './Settings/UpdateName';
export {default as UpdateEmail} from './Settings/UpdateEmail';
export {default as UpdatePhoneNo} from './Settings/UpdatePhoneNo';
export {default as OrderDetails} from './OrderDetails';
export {default as RentalConfirmed} from './RentalConfirmed';
export {default as AcceptTermCondition} from './AcceptTermCondition';
export {default as PopoutTimer} from './PopoutTimer';
