import axios from 'axios';
import CryptoJS from 'crypto-js';
import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import OTPInput from 'react-otp-input';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import 'react-phone-input-2/lib/style.css';
import {Link, useNavigate} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import {apple, email, loginBanner} from '../../assets/images/index';
import {
  EMAILLOGIN,
  EMAILOTPSEND,
  GETCAPTCHAS,
  GOOGLELOGIN,
  MOBILELOGIN,
  VRIFYOTP,
} from '../../services/apiStaticData';
import {generateUUID} from '../../utils/functionCommon';

interface GoogleResponse {
  token: string;
  profile: {name: string; email: string; imageUrl: string};
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('31');
  const [isEmail, setIsEmail] = useState(false);
  const [uuid, setUuid] = useState('');
  const [getCaptcha, setGetCaptcha] = useState<string | null>(null);
  const [otpStep, setOtpStep] = useState(false);
  const [otp, setOtp] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    formState: {errors},
    register,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'all',
    // resolver: yupResolver(validationSchema),
  });

  ////////////////////// convert Captcha to Base64 ///////////////////////
  const arrayBufferToBase64 = (buffer: any) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handlePhoneChange = (value: string, country: any, e: any) => {
    const countryCode = country?.dialCode || '31';
    let phoneWithoutCountryCode = value.replace(`+${countryCode}`, '').trim();
    const strippedPhoneNumber = value.startsWith(country?.dialCode)
      ? value.slice(country.dialCode.length)
      : value;
    setPhone(phoneWithoutCountryCode);
    setCountryCode(countryCode);
    setValue('phone', strippedPhoneNumber);
  };

  ////// generate UUID ////

  //////// pass UUID function /////
  const fetchCaptcha = async () => {
    // const newUuid = uuidv4();
    const newUuid = generateUUID();
    setUuid(newUuid);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_HOST}${GETCAPTCHAS}?uuid=${newUuid}`,
        {responseType: 'arraybuffer'},
      );
      const base64Image = arrayBufferToBase64(response.data);
      setGetCaptcha(`data:image/jpeg;base64,${base64Image}`);
    } catch (error) {
      console.error('Error fetching the captcha:', error);
    }
  };

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const onSubmit = async (data: {
    email?: string;
    phone?: string;
    code?: string;
  }) => {
    if (isEmail && data.email) {
      // Step 1: Generate a timestamp
      var timeGet = new Date().valueOf();
      // Step 2: Replace all '0' characters in the timestamp with '8'
      var a = timeGet.toString().replace(/0/g, '8');
      // Step 3: Concatenate with a secret key and hash with MD5
      var secretKey = 'GZzbjkj';
      var i = CryptoJS.MD5(a + secretKey).toString();

      const formData = new FormData();
      formData.append('email', watch('email'));
      // Get current timestamp
      const timestamp: any = Date.now();
      // formData.append('timestamp', "1730986747767");
      formData.append('timestamp', timeGet.toString());
      // Generate the sign value using email and timestamp
      const sign = CryptoJS.MD5(`${data.email}${timestamp}`);
      // formData.append('sign', "f0a5a81d06a7c0cde7e5593e25d1ad21");
      formData.append('sign', i);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}${EMAILOTPSEND}`,
          formData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        );
        if (response.status === 200) {
          if (response.data.code === 0) {
            setOtpStep(true);
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.msg,
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            text: response?.data?.msg,
          });
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          text: error,
        });
      }
    } else if (data?.code) {
      // Handle phone verification as usual
      setPhone(data.phone || '');
      try {
        const url = `${process.env.REACT_APP_API_HOST}${VRIFYOTP}?mobile=${phone}&uuid=${uuid}&code=${data?.code}&useDebug=true`;
        const response = await axios.get(url);
        if (response.status === 200) {
          if (response?.data?.code === 0) {
            setOtpStep(true);
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.msg,
            })
            reset();
            setPhone('');
            setCountryCode('31')
          }
        } else {
          Swal.fire({
            icon: 'error',
            text: response?.data?.msg || 'An error occurred',
          });
          reset();
          setPhone('');
        }
      } catch (error) {
        console.error('Error calling the API:', error);
        reset();
        fetchCaptcha();
      }
    }
  };

  const handleChange = (otpValue: string) => {
    setOtp(otpValue);
  };

  const handleOtpSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const verifyCode = Number(otp);
    try {
      setIsSubmitting(true);

      if (isEmail) {
        const email = watch('email');
        const emailLoginUrl = `${process.env.REACT_APP_API_HOST}${EMAILLOGIN}?email=${email}&verifyCode=${verifyCode}`;
        const response = await axios.post(emailLoginUrl);
        if (response.status === 200) {
          if (response.data.code === 0) {
            localStorage.setItem('reviveToken', response?.data?.token);
            navigate('/station-detail');
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.msg,
            });
          }
        } else {
          console.error('Error in email OTP submission:', response);
        }
      } else {
        // Existing mobile login logic
        const areaCode = Number(countryCode);
        const registAndLoginType = 1;
        const queryParams = new URLSearchParams({
          mobile: String(Number(countryCode + watch().phone)),
          verifyCode: String(verifyCode),
          areaCode: String(areaCode),
          registAndLoginType: String(registAndLoginType),
        }).toString();

        const response = await axios.post(
          `${process.env.REACT_APP_API_HOST}${MOBILELOGIN}?${queryParams}`,
        );

        if (response.status === 200) {
          if (response.data.code === 0) {
            localStorage.setItem('reviveToken', response?.data?.token);
            navigate('/station-detail');
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.msg,
            });
          }
        } else {
          console.error('Error in OTP submission:', response);
        }
      }
    } catch (error) {
      console.error('Error submitting OTP:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  //// google //
  const CredentialResponse = (response: any) => {
    const credential = response.credential;
    handleGoogleResponse(credential);
  };
  const handleGoogleResponse = async (idToken: string) => {
    const formData = new FormData();
    formData.append('androidToken', idToken);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}${GOOGLELOGIN}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      if (response.status === 200) {
        if (response.data.code === 0) {
          localStorage.setItem('reviveToken', response.data.token);
          navigate('/station-detail');
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.msg,
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Error during Google login.',
        });
      }
    } catch (error) {
      console.error('Error during Google login:', error);
      Swal.fire({
        icon: 'error',
        text: 'Error during Google login.',
      });
    }
  };
  const initGoogle = () => {
    const clientId = process.env.REACT_APP_GOOGLE_KEY;

    if (!clientId) {
      throw new Error(
        'Google Client ID is not set in the environment variables.',
      );
    }

    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: CredentialResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById('buttonDiv'),
      {
        type: 'standard',
        theme: '',
        size: 'large',
        shape: 'rectangular',
      },
    );
  };

  const loadGoogle = () => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      console.log('Google API script loaded');
      initGoogle();
    };
    document.head.appendChild(script);
  };

  useEffect(() => {
    loadGoogle();
  }, []);

  return (
    <>
      <div className="py-6 min-h-full flex flex-col items-center md:justify-center">
        {/* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} /> */}
        <div className="container">
          <div className="max-w-[484px] mx-auto">
            <div>
              <img
                className="mb-6 w-full"
                alt="login-banner"
                src={loginBanner}
              />
              {!otpStep && (
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  {isEmail ? (
                    <>
                      <label className="block mb-4 text-xl">
                        Enter Your Email
                      </label>
                      <div className="react-phone-input-custom mb-6">
                        <input
                          className="form-input"
                          {...register('email')}
                          id="email"
                          name="email"
                          type="email"
                          placeholder="test@mail.com"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <label className="block mb-4 text-xl">
                        Enter your mobile number
                      </label>
                      <div className="react-phone-input-custom mb-6">
                        <Controller
                          name="phone"
                          control={control}
                          render={({field}) => (
                            <PhoneInput
                              {...field}
                              value={phone}
                              // onChange={(e)=>handlePhoneChange(e)}
                              onChange={(
                                value,
                                countryObj,
                                e,
                                formattedValue,
                              ) => handlePhoneChange(value, countryObj, e)}
                              containerStyle={{
                                fontFamily: `"Montserrat", sans-serif`,
                              }}
                              enableSearch={true}
                              country={'nl'}
                            />
                          )}
                        />
                      </div>
                      <label className="block mb-4 text-xl">Code</label>
                      <div className="relative">
                        {getCaptcha && (
                          <img
                            src={getCaptcha}
                            alt="Captcha"
                            className="captcha-image w-[100px] h-[50px] border border-gray-300 absolute end-0 top-0 h-full rounded-e-xl"
                          />
                        )}
                        <div className="react-phone-input-custom mb-6">
                          <input
                            className="form-input pe-[108px]"
                            {...register('code')}
                            id="code"
                            name="code"
                            type="text"
                            placeholder="****"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <button className="btn-primary px-8 mb-6" type="submit">
                    Continue
                  </button>

                  <div className="text-gray-300 mb-6 relative block w-full text-center before:block before:relative before:w-full before:top-[14px] before:z-0 before:bg-gray-300 before:h-[1px] px-12">
                    <span className="px-5 relative bg-white">or</span>
                  </div>
                  {/* <button className="btn-secondary px-4 mb-6 flex gap-4 justify-center" onClick={()=>{onGoogle()}}>
                    <img alt="google-icon" src={google} />
                    Continue with Google
                  </button> */}
                  <div
                    id="buttonDiv"
                    className="google-login-button btn-secondary py-0 mb-6 flex gap-4 justify-center"
                  />
                  <button className="btn-secondary px-4 mb-6 flex gap-4 justify-center">
                    <img alt="google-icon" src={apple} />
                    Continue with Apple
                  </button>
                  <button
                    className="btn-secondary px-4 mb-6 flex gap-4 justify-center"
                    onClick={() => {
                      setIsEmail(!isEmail);
                    }}>
                    <img alt="google-icon" src={email} />
                    {isEmail ? 'Continue with Mobile' : 'Continue with Email'}
                  </button>
                </form>
              )}

              {/* OTP input fields are shown when otpStep is true */}
              {otpStep && (
                <div className="otp-screen">
                  <h3 className="text-xl mb-4">
                    Enter the OTP sent to your mobile
                  </h3>
                  <form onSubmit={handleOtpSubmit}>
                    <div className="otp-input flex items-center gap-6 mb-6">
                      <OTPInput
                        value={otp}
                        onChange={(e: any) => handleChange(e)}
                        numInputs={4}
                        shouldAutoFocus
                        inputStyle={{
                          width: '53px',
                          height: '53px',
                          textAlign: 'center',
                          borderRadius: '4px',
                          border: '1px solid #ccc',
                        }}
                        containerStyle={{display: 'flex', gap: '10px'}}
                        renderInput={props => <input {...props} />}
                      />
                    </div>

                    <button
                      className="btn-primary px-8 mb-6"
                      type="submit"
                      disabled={isSubmitting}>
                      {isSubmitting ? 'Submitting...' : 'Submit OTP'}
                    </button>
                  </form>
                </div>
              )}

              <p className="mb-6 text-sm">
                By proceeding, you give Revive and its partners permission to
                call the provided number or send WhatsApp messages or SMS
                messages (for example, via an automated service).
              </p>
              <p className="text-sm">
                By clicking Login, you agree to{' '}
                <Link to="/user-agreement" className="font-semibold underline">
                  User Agreement
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
