/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import Cookies from 'js-cookie';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {eightSlotMachineLeft} from '../../assets/images';
import {ORDERCHECK} from '../../services/apiStaticData';

const PopoutTimer: React.FC = () => {
  const navigate = useNavigate();
  const cabinetID = localStorage.getItem('cabinetID');
  const [timeLeft, setTimeLeft] = useState(7);
  const [progress, setProgress] = useState(0);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [getSuccessTimer, setGetSuccessTimer] = useState(false);
  const duration = 7000;
  let timer;

  const fetchData = async () => {
    Cookies.set('fromCheckout', 'true', {expires: 1});
    const query = new URLSearchParams(window.location.search);
    const tradeNo = query.get('tradeNo');
    const payMode = query.get('payMode');

    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Set up progress tracking
    const progressInterval = setInterval(() => {
      setProgress(prev => {
        if (prev < 100) {
          return prev + 100 / (duration / 100);
        } else {
          clearInterval(progressInterval);
          return 100;
        }
      });
    }, 100);

    const token = localStorage.getItem('reviveToken');
    const url = `${process.env.REACT_APP_API_HOST}${ORDERCHECK}/${tradeNo}/${payMode}`;
    if (!tradeNo && !payMode) {
      return Swal.fire({
        title: 'Error',
        text: 'Invalid tradeNo or payMode',
        icon: 'error',
      }).then(() => {
        navigate('/');
      });
    }
    try {
      setIsTimerActive(true);
      if (isTimerActive) {
        clearInterval(timer);
        clearInterval(progressInterval);
      }
      const response = await axios.post(url, {
        headers: {
          Token: `${localStorage.getItem('reviveToken')}`,
        },
      });
      if (response.status === 200) {
        setGetSuccessTimer(true);
        // navigate('/rental-confirmed');
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.data?.msg,
        }).then(() => {
          navigate('/');
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Please try again.',
      }).then(() => {
        navigate('/');
      });
    }
  };

  useEffect(() => {
    const fifteenMinutesInMilliseconds = 15 * 60 * 1000;
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + fifteenMinutesInMilliseconds,
    );
    Cookies.set('fromCheckout', 'true', {expires: expirationDate});
    if (!hasFetchedData) {
      fetchData();
      setHasFetchedData(true);
    }
  }, [hasFetchedData]);

  useEffect(() => {
    console.log(timeLeft, 'Fetched data', getSuccessTimer);
    if (getSuccessTimer && timeLeft === 0) {
      navigate('/rental-confirmed');
    }
  }, [getSuccessTimer, timeLeft, navigate]);

  return (
    <div className="min-h-full flex flex-col justify-center items-center lg:py-10 py-6">
      <div className="container">
        <div className="max-w-[350px] text-center mx-auto w-full">
          <h1 className="text-2xl mb-14">Power Bank Ejection</h1>
          <div>
            <img
              className="mb-10 h-60 w-60 sm:h-[300px] sm:w-[300px] sm:ml-auto sm:mr-0 mx-auto relative -right-[5px]"
              alt="Powerbank Station"
              src={eightSlotMachineLeft}
              width={300}
              height={300}
            />
          </div>
          {/* <span className="inline-block py-2 px-6 bg-amber-600 text-gray-950 rounded-full mb-[30px]">00:03</span> */}
          <span className="inline-block py-2 px-6 bg-amber-600 text-gray-950 rounded-full mb-[30px]">{`00:${
            timeLeft < 10 ? '0' : ''
          }${timeLeft}`}</span>
          <div className="overflow-hidden rounded-full bg-gray-50 mb-5">
            <div
              className="h-2 rounded-full bg-gradient-to-r from-emerald-500 from-0% to-amber-600 to-[350px]"
              style={{width: `${progress}%`}}></div>
          </div>
          <p className="text-lg">Power Bank is Popping out</p>
        </div>
      </div>
    </div>
  );
};

export default PopoutTimer;
