/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {FaApple, FaGooglePlay} from 'react-icons/fa';
import {
  success,
  mobileWithPowerbank,
  playStore,
  appStore,
} from '../../assets/images';

const RentalConfirmed: React.FC = () => {
  const navigate = useNavigate();
  localStorage.removeItem('ApiSuccess');
  return (
    <div className="min-h-full flex flex-col justify-center items-center lg:py-10 pt-6 pb-[150px]">
      <div className="container">
        <div className="max-w-[327px] text-center mx-auto w-full">
          <img
            className="mb-7 w-auto h-20 w-20 mx-auto"
            alt="payment-success"
            src={success}
            width={100}
            height={100}
          />
          <h1 className="text-xl mb-2">Rental Confirmed</h1>
          <p className="mb-10">
            Your Power Bank is ready, grab it and enjoy your day. stress free!
          </p>
          <img
            className="mb-[30px] w-auto h-[196px] w-[217px] mx-auto"
            alt="Mobile Powerbank"
            src={mobileWithPowerbank}
            width={217}
            height={196}
          />
        </div>
      </div>
      <div className="py-7 lg:py-0 lg:relative fixed left-0 bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
        <div className="container">
          <div className="max-w-[362px] mx-auto w-full">
            <div className="grid grid-cols-2 grid-rows-1 gap-5 mb-5">
              <div>
                <Link to="https://play.google.com/store/apps/details?id=com.revive.charge&pli=1">
                  <img
                    className="w-full"
                    alt="Playstore"
                    src={playStore}
                    width={180}
                    height={52}
                  />
                </Link>
              </div>
              <div>
                <Link to="https://apps.apple.com/in/app/revive-power-bank/id6504699071">
                  <img
                    className="w-full"
                    alt="Appstore"
                    src={appStore}
                    width={180}
                    height={52}
                  />
                </Link>
              </div>
            </div>
            <div className="text-center">
              <Link
                to="/"
                className="w-max inline-block cursor-pointer text-gray-950 font-bold transition ease-in-out duration-300">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="py-7 lg:py-1.5 lg:relative fixed bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
        <div className="container text-center">
          <div className="lg:max-w-[362px] mx-auto">
            <button
              className="btn-primary px-4"
              onClick={() => navigate('/order-list')}>
              <span>Go to my Orders</span>
            </button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default RentalConfirmed;
