export const QRCODESCAN = '/cdb/cabinet/checkisok';
export const GETCAPTCHAS = '/captcha.webp';
export const VRIFYOTP = '/saas/sendVerifyCodeSms';
export const MOBILELOGIN = '/saas/mobileLogin';
export const EMAILOTPSEND = '/saas/sendVerifyCodeMail';
export const EMAILLOGIN = '/saas/emailLogin';
export const GOOGLELOGIN = '/saas/loginGoogleByCode';
export const STRIPEMULTICURANCY =
  '/cdb/payment/stripe/multicurrency/preauthOfIntent/payment';
export const PAYMENTCONFIRM = '/cdb/payment/stripe/multicurrency/confirm';
export const USEBALANCETORENT =
  '/cdb-app-api/v1/app/cdb/cabinet/useBalanceToRent';
export const GOOGLEMAPLISTNEARAPI = '/cdb/shop/listnear';

