/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {failed} from '../../assets/images';

const PaymentFailure: React.FC = () => {
  const {state} = useLocation();

  let message = state && state.message;

  message = message ? message : '';

  const navigate = useNavigate();
  const navigateToCheckout = () => navigate('/station-detail');

  return (
    <div className="min-h-full flex flex-col justify-center items-center pt-6 lg:py-[100px]">
      <div className="container text-center lg:mb-0 pb-[115px] lg:pb-0">
        <div className="max-w-[439px] mx-auto">
          <img
            className="mb-7 w-auto h-auto mx-auto"
            alt="payment-success"
            src={failed}
            width={100}
            height={100}
          />

          <h1 className="text-xl">Payment Failed</h1>
          <p className="mt-4 mb-6">
            {`${message} Try again with the same payment method or go back to
            change your payment method.`}
          </p>
        </div>
      </div>

      <div className="py-7 lg:py-1.5 lg:relative fixed bottom-0 w-full bg-white inline-block lg:shadow-none shadow-3xl">
        <div className="container text-center">
          <div className="lg:max-w-[362px] mx-auto">
            <button className="btn-primary px-4" onClick={navigateToCheckout}>
              <span>Try Again</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure;
