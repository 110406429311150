/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import {eightSlotMachineLeft} from '../../assets/images';
import {PAYMENT_CONFIRM} from '../../services/apiStaticData';

const PopoutTimer: React.FC = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(7);
  const [progress, setProgress] = useState(0);
  const duration = 7000;
  // let timer;

  const [hasTriggeredPaymentAPI, setHasTriggeredPaymentAPI] = useState(false);

  const timer = setInterval(() => {
    setTimeLeft(prevTime => {
      if (prevTime <= 1) {
        clearInterval(timer);
        return 0;
      }
      return prevTime - 1;
    });
  }, 1000);

  const progressInterval = setInterval(() => {
    setProgress(prev => {
      if (prev < 100) {
        return prev + 100 / (duration / 100);
      } else {
        clearInterval(progressInterval);
        return 100;
      }
    });
  }, 100);

  useEffect(() => {
    if (timeLeft === 5 && !hasTriggeredPaymentAPI) {
      setHasTriggeredPaymentAPI(true);
      const confirmPayment = async () => {
        try {
          const query = new URLSearchParams(window.location.search);
          const paymentIntentId = query.get('tradeNo');

          const response = await axios.get(
            `${process.env.REACT_APP_API_HOST_SOCIAL_LOGIN}${PAYMENT_CONFIRM}?intentId=${paymentIntentId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('reviveToken')}`,
              },
            },
          );

          if (response.status === 200) {
            clearInterval(timer);
            clearInterval(progressInterval);
            navigate('/rental-confirmed');
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.error?.message || 'Payment failed.',
            }).then(() => navigate('/payment/failure'));
          }
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            text:
              error?.response?.data?.error?.message ||
              'Error confirming the payment.',
          }).then(() => navigate('/payment/failure'));
        }
      };
      confirmPayment();
    }
  }, [timeLeft, hasTriggeredPaymentAPI, navigate]);

  return (
    <div className="min-h-full flex flex-col justify-center items-center lg:py-10 py-6">
      <div className="container">
        <div className="max-w-[350px] text-center mx-auto w-full">
          <h1 className="text-2xl mb-14">Power Bank Ejection</h1>
          <div>
            <img
              className="mb-10 h-60 w-60 sm:h-[300px] sm:w-[300px] sm:ml-auto sm:mr-0 mx-auto relative -right-[5px]"
              alt="Powerbank Station"
              src={eightSlotMachineLeft}
              width={300}
              height={300}
            />
          </div>
          {/* <span className="inline-block py-2 px-6 bg-amber-600 text-gray-950 rounded-full mb-[30px]">00:03</span> */}
          <span className="inline-block py-2 px-6 bg-amber-600 text-gray-950 rounded-full mb-[30px]">{`00:${
            timeLeft < 10 ? '0' : ''
          }${timeLeft}`}</span>
          <div className="overflow-hidden rounded-full bg-gray-50 mb-5">
            <div
              className="h-2 rounded-full bg-gradient-to-r from-emerald-500 from-0% to-amber-600 to-[350px]"
              style={{width: `${progress}%`}}></div>
          </div>
          <p className="text-lg">Power Bank is Popping out</p>
        </div>
      </div>
    </div>
  );
};

export default PopoutTimer;
