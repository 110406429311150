/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {eightSlotMachineLeft} from '../../assets/images';
import Swal from 'sweetalert2';
import {USEBALANCETORENT} from '../../services/apiStaticData';

const PopoutTimer: React.FC = () => {
  const cabinetID = localStorage.getItem('cabinetID');
  const [timeLeft, setTimeLeft] = useState(7);
  const [progress, setProgress] = useState(0);
  const duration = 7000;
  const fetchData = async () => {
    const token = localStorage.getItem('reviveToken');
    const url = `${process.env.REACT_APP_API_HOST}${USEBALANCETORENT}?qrcode=${cabinetID}`;
    const ApiSuccess = localStorage.getItem('ApiSuccess');
    if (ApiSuccess) {
      window.location.href = '/rental-confirmed';
    }
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.code === 0) {
        localStorage.setItem('ApiSuccess', '1');
        window.location.href = '/rental-confirmed';
      } else {
        Swal.fire({
          icon: 'error',
          text: data.msg,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Please try again.',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      // Redirect to /order when time reaches 0
      window.location.href = '/rental-confirmed';
    } else {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000); // Decrease time every second

      return () => clearInterval(timer); // Cleanup on unmount
    }
  }, [timeLeft]);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev < 100) {
          return prev + 100 / (duration / 100);
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 100);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [duration]);

  return (
    <div className="min-h-full flex flex-col justify-center items-center lg:py-10 py-6">
      <div className="container">
        <div className="max-w-[350px] text-center mx-auto w-full">
          <h1 className="text-2xl mb-14">Power Bank Ejection</h1>
          <div>
            <img
              className="mb-10 h-60 w-60 sm:h-[300px] sm:w-[300px] sm:ml-auto sm:mr-0 mx-auto relative -right-[5px]"
              alt="Powerbank Station"
              src={eightSlotMachineLeft}
              width={300}
              height={300}
            />
          </div>
          {/* <span className="inline-block py-2 px-6 bg-amber-600 text-gray-950 rounded-full mb-[30px]">00:03</span> */}
          <span className="inline-block py-2 px-6 bg-amber-600 text-gray-950 rounded-full mb-[30px]">{`00:${
            timeLeft < 10 ? '0' : ''
          }${timeLeft}`}</span>
          <div className="overflow-hidden rounded-full bg-gray-50 mb-5">
            <div
              className="h-2 rounded-full bg-gradient-to-r from-emerald-500 from-0% to-amber-600 to-[350px]"
              style={{width: `${progress}%`}}></div>
          </div>
          <p className="text-lg">Power Bank is Popping out</p>
        </div>
      </div>
    </div>
  );
};

export default PopoutTimer;
